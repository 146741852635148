import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignInUpPageComponent } from './pages/sign-in-up-page/sign-in-up-page.component';
import { AccountStatusHandlerPageComponent } from './pages/account-status-handler-page/account-status-handler-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { InterceptorService } from './services/interceptor/interceptor.service';
import { SharedModule } from './modules/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularToastModule } from 'angular-toasts';
import { CanActivateViaAuthGuard } from './services/guard/auth.guard';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { InternalizacionModule } from './modules/internalization/internalization.module';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {
  RollbarErrorHandler,
  RollbarService,
  rollbarFactory,
} from './handlers/rollback.handler';
import { RecordService } from './services/video-recording/record.service';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ChangeEmailVerifyComponent } from './pages/change-email-verify/change-email-verify.component';
import { MediaPlayerComponent } from './modules/dashboard-outlet/components/media-player/media-player.component';
import localeEsCo from '@angular/common/locales/es-CO';
import localeEsPe from '@angular/common/locales/es-PE'; // Local de Perú
import localeEsCl from '@angular/common/locales/es-CL'; // Local de Chile
import localeEsAr from '@angular/common/locales/es-AR'; // Local de Argentina
import localePtBr from '@angular/common/locales/pt';
import localePtBrExtra from '@angular/common/locales/extra/pt';
registerLocaleData(localePtBr, 'pt-BR', localePtBrExtra);

registerLocaleData(localeEsPe); // Registrar local de Perú
registerLocaleData(localeEsCl); // Registrar local de Chile
registerLocaleData(localeEsAr); // Registrar local de Argentina
registerLocaleData(localeDe, 'de-DE', localeDeExtra); // Se agrego nuevo local para moneda EUROS
registerLocaleData(localeEsCo, 'es-CO');
registerLocaleData(localePtBr); // Registrar local de Brasil
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { PermissionsComponent } from './modules/dashboard-outlet/pages/postulation-handler-page/pages/permissions/permissions.component';
import * as moment from 'moment';
import 'moment/locale/es'; // Importa el idioma español
moment.locale('es');

// loader module
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    SignInUpPageComponent,
    AccountStatusHandlerPageComponent,
    ForgotPasswordPageComponent,
    MaintenanceComponent,
    ChangeEmailVerifyComponent,
    PermissionsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    AngularToastModule,
    InternalizacionModule,
    FormsModule,
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    CanActivateViaAuthGuard,
    TranslateService,
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}

  ngOnInit() {}
}
