<div class="d-flex flex-row fondo">
	<div class="d-flex flex-column w-50 justify-content-center">
		<img class="logo align-self-center mb-5" src="assets/images/logo-involve.png" alt="" srcset="">
		<span class="title align-self-center mt-3">
			Estamos trabajando para 
		</span>
		<span class="align-self-center subtitle">
			brindarte un mejor servicio
		</span>
		<span class="text1 align-self-center mt-2">
			En breve podrás ver las mejoras en nuestra plataforma.
		</span>
		<span class="text2 align-self-center mt-2">
			¡Gracias por tu paciencia!
		</span>
	</div>
	<div class="w-50 img-container">
		<img src="../../../../assets/images/mantenimiento.png" class="img-fluid" alt="" srcset="">
	</div>
</div>
<div class="w-100 footer">
	<img class="w-100 desktop-img" src="../../../../assets/svg/curvas.svg" alt="Curvas">
	<img class="w-100 mobile-img" src="../../../../assets/svg/curvas_mobile.svg" alt="Curvas">
</div>
