import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { ICandidate, IWorkExperience } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';
import { hasFormControlError, inputTextFormat, maxDateAllowed, minDateAllowed, minLengthRequired, number, required } from 'src/app/services/custom-validators/custom-validators.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {
  experienceForm: FormGroup;
  positionTypes: any[] = [];
  currentExperience : any;
  action = "CV_CREATE";
  currentCandidate : ICandidate | undefined;
  experiences : any; 
  hasDateError = false;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public translateService: TranslateService
    ) {
      const date = new Date();
      const maxDate = moment(date).format("YYYY-MM-DD");
      const minDate =  moment(moment()).subtract(80, 'year').toString();
      this.experienceForm = this.fb.group({
      position: this.fb.control('', [required]),
      positionType: this.fb.control('', [required]),
      company: this.fb.control('', [required]),
      startDate: this.fb.control('', [required, maxDateAllowed(maxDate), minDateAllowed(minDate)]),
      stillWorking: this.fb.control(false, [Validators.required]),
      finishDate: this.fb.control(null, [required,  maxDateAllowed(maxDate), minDateAllowed(minDate)]),
      peopleInYourCare: this.fb.control(false, [Validators.required]),
      howManyPeople: this.fb.control(null, []),
      functions: this.fb.control('', [required]),
    },{
      validators: [this.validatePeopleInYourCare,this.validateCurrentJobDate]
    });    
  }

  ngOnInit(): void {
    this.getPosition();
    this.getCandidateData();
    this.howManyPeopleControl.valueChanges.subscribe(value => {
      this.howManyPeopleControl.setValue(inputTextFormat(value,'numbersOnly'), { emitEvent: false})
    });
  }

  getCandidateData(refresh = false){
    this.apiService.getCandidate(refresh).subscribe(
      (getCandidateResponse: ICandidate) => {
        this.currentCandidate = getCandidateResponse;
        this.route.params.subscribe(params => {
          this.experiences = this.currentCandidate?.workExperiencie || [];
          const id = params['experienceId'];
          if(this.experiences.length > 0 && id){
            this.currentExperience = this.experiences.filter(function(experience: { workExperienceId: any; }){
              return experience.workExperienceId === id
            })[0]
            if(this.currentExperience){
              this.action = 'CV_EDIT';
              this.positionControl.setValue(this.currentExperience.position.position);
              this.companyControl.setValue(this.currentExperience.company);
              this.startDateControl.setValue(`${this.currentExperience.dateEntry}`);
              this.stillWorkingControl.setValue(this.currentExperience.working) 
              this.functionsControl.setValue(this.currentExperience.functions)
              this.positionTypeControl.setValue(this.currentExperience.positionType.positionTypeId) 
              this.peopleInYourCareControl.setValue(this.currentExperience.dependents)
              if (!this.currentExperience.working){
                this.finishDateControl.setValue(`${this.currentExperience.dateDeparture}`);
              }
              if (this.currentExperience.dependents){
                this.howManyPeopleControl.setValue(this.currentExperience.numberDependents);
              }
              this.setValidation('peopleInYourCare',Boolean(this.currentExperience.dependents)?'yes':'no')
              this.setValidation('stillWorking',Boolean(this.currentExperience.working)?'yes':'no')
            }
          }else{
            if (this.action != "CV_CREATE") {
              this.router.navigateByUrl('dashboard/my-cv');
            }  
          }       
        });
      }
    );
  }

  getPosition() {
    this.apiService.getPositionTypes()?.subscribe((positionTypes: any) => {
      this.positionTypes = positionTypes;
    });
  }

  validateDate(){
    if (!this.stillWorkingControl.value) {
      this.hasDateError = this.startDateControl.value > this.finishDateControl.value;
    }
  }

  setValidation(input: string, value: string){
    switch (input) {
      case "stillWorking":
        if (value == "yes") {
          this.finishDateControl.clearValidators()
          this.finishDateControl.updateValueAndValidity()
        } else {
          const date = new Date();
          const maxDate = moment(date).format("YYYY-MM-DD");
          const minDate =  moment(moment()).subtract(80, 'year').toString();
          this.finishDateControl.setValidators([required,maxDateAllowed(maxDate), minDateAllowed(minDate)])
          this.finishDateControl.updateValueAndValidity();
        }
        break;
      case "peopleInYourCare":
        if (value == "yes") {
          this.howManyPeopleControl.setValidators([required])
          this.howManyPeopleControl.updateValueAndValidity();
        } else {
          this.howManyPeopleControl.clearValidators()
          this.howManyPeopleControl.updateValueAndValidity();
        }
        break;
      default:
        break;
    }
  }

  saveExperience() {
    this.experienceForm.markAllAsTouched()
    this.validateDate()
    if ( this.experienceForm.valid && !this.hasDateError) {
      const endpoint = this.action == 'CV_CREATE'?'addNewWorkExperience':'editWorkExperience';
      const experience =  this.action == 'CV_CREATE'?[this.newExperience()]: this.newExperience();
      this.apiService[endpoint](experience).subscribe({
        next:(response) => {
          this.getCandidateData(true);
          switch (this.action) {
            case 'CV_CREATE':
              this.router.navigateByUrl('dashboard/my-cv');
              break;
            case 'CV_EDIT':
              this.router.navigateByUrl('dashboard/my-cv');
              break;
            case 'EXTRACTION_CREATE':
              this.router.navigateByUrl('onboarding/validate-extraction');
              break;
            default:
              break;
          }
          
        }, 
        error:(error) => {
          this.router.navigateByUrl('onboarding/validation-ocr');
        }
      });
    }
  }

  newExperience () {
    const workExperience = 
      {
          company: this.companyControl.value,
          dateDeparture: this.stillWorkingControl.value ? null : this.finishDateControl.value,
          dateEntry: this.startDateControl.value,
          dependents: this.peopleInYourCareControl.value,
          functions: this.functionsControl.value,
          numberDependents: this.howManyPeopleControl.value?this.howManyPeopleControl.value:0,
          position: {
            position: this.positionControl.value
          },
          positionType: {
            positionTypeId: this.positionTypeControl.value
          },
          working: this.stillWorkingControl.value,
          ...this.currentExperience?.workExperienceId && {workExperienceId:this.currentExperience.workExperienceId},
          ...this.currentCandidate?.candidateId && {candidateId:this.currentCandidate?.candidateId},
          ...this.currentExperience?.workExperienceId && {timeWork:null}
        };

    return workExperience;
  }

  deleteExperience(id: string){
    this.apiService.deleteWorkExperience(id).subscribe({
      next:(response) => {
        this.getCandidateData(true);
        if (id === this.currentExperience?.workExperienceId) {
          this.router.navigateByUrl('dashboard/my-cv');
        }
      }
    })
  }

  validatePeopleInYourCare (): ValidatorFn{
    return (formGroup: AbstractControl): ValidationErrors | null => {
      if (this.peopleInYourCareControl.value == "SI") {
        if (!(Number(this.howManyPeopleControl) > 0)) {
          return null;
        }else{
          return { howManyPeopleError: true }
        }
      }else{
        return null;
      }
    };
  }

  validateCurrentJobDate (): ValidatorFn{
    return (formGroup: AbstractControl): ValidationErrors | null => {
      if (this.stillWorkingControl.value == "SI") {
        if (this.finishDateControl.value.length > 0) {
          return null;
        }else{
          return { finishDateError: true }
        }
      }else{
        return null;
      }
    };
  }

  get positionTypeControl():FormControl{
    return this.experienceForm.get('positionType') as FormControl;
  }

  get functionsControl(): FormControl{
    return this.experienceForm.get('functions') as FormControl;
  }

  get positionControl(): FormControl{
    return this.experienceForm.get('position') as FormControl;
  }

  get companyControl(): FormControl{
    return this.experienceForm.get('company') as FormControl;
  }

  get stillWorkingControl(): FormControl{
    return this.experienceForm.get('stillWorking') as FormControl;
  }

  get startDateControl(): FormControl{
    return this.experienceForm.get('startDate') as FormControl;
  }

  get finishDateControl(): FormControl{
    return this.experienceForm.get('finishDate') as FormControl;
  }

  get peopleInYourCareControl(): FormControl{
    return this.experienceForm.get('peopleInYourCare') as FormControl;
  }

  get howManyPeopleControl(): FormControl{
    return this.experienceForm.get('howManyPeople') as FormControl;
  }

  hasFormControlError(formControlName: string, errorName?: string): boolean | ValidationErrors{
    return hasFormControlError(this.experienceForm, formControlName, errorName, true);
  }
}
