import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private _loaderStatus: Subject<any> = new Subject<any>();    // consider putting the actual type of the data you will receive
  public loaderStatus = this._loaderStatus.asObservable();

  constructor() { }

  showLoader(){

    this._loaderStatus.next(true)
  }

  hideLoader(){
  
    this._loaderStatus.next(false)
  }
}
