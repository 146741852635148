import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecordService {
  status = new Subject<string>();
  error = new Subject<string>();
  mp4File = new Subject<File>();
  url = '';
  saveTemp = false;
  private _recordingTime = new Subject<string>();
  private startTime: any;
  private interval: any;
  constructor() { 
    this._recordingTime.next('00:00');
    this.status.next('INIT');
  }

  setStatus( status: string ){ 
    this.status.next(status);
  }
  
  getStatus(): Observable<string> {
    return this.status.asObservable();
  }

  setError( error: string ){ 
    this.error.next(error);
  }
  
  getError(): Observable<string> {
    return this.error.asObservable();
  }

  setRecordedVideo(mp4File: File)  {
    this.mp4File.next(mp4File);
  }

  getRecordedVideo(): Observable<File> {
    return this.mp4File.asObservable();
  }

  setTempVideo(status: boolean)  {
    this.saveTemp = status;
  }

  setRecordedUrl(url: string)  {
    if(this.saveTemp){
      this.url = url;
    }else{
      this.url = '';
    }
  }

  getRecordedUrl(): string {
    return this.url;
  }

  private toString(value: any): any {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }
  
  startRecordedTime(){
    this.setRecordedTime()
  }
  
  setRecordedTime() {
    this.startTime = moment();
    this.interval = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
      },
      1000
    );
  }

  stopRecordedTime(){
    clearInterval(this.interval)
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

}
