<div class="d-flex flex-row p-5 m-5 main-container">
    <div class="d-flex  flex-column w-25 p-5 image-container">
        <img src="/assets/svg/vineta_buscadores_talento.svg" alt="Your Image" class="img-fluid">
    </div>
    <form class="d-flex flex-column w-75 p-5 form-container" [formGroup]="permissionForm" >
        <div class="mb-5">
            <p>
                <strong>Los buscadores de talento valoran las postulaciones con videopresentación, videoentrevista y pruebas psicométricas </strong>
                ya que les permite conocer mejor a los candidatos.
            </p>
        </div>
        <div class="d-flex flex-row mb-4" *ngIf="isMissingPermission(permissionEnum.VIDEOPRESENTATION)">
            <div class="w-75">
                <p>
                    <strong>Videopresentación</strong> 
                    <br>
                    Consiento el tratamiento de mi imagen y voz para su cesión a empresas interesadas en mi 
                    perfil, con arreglo a Información básica de privacidad.
                </p>
            </div>

            <div class="w-25 d-flex justify-content-end">
                <div class="custom-control custom-switch mt-3">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="VIDEOPRESENTATION_SHARE">
                    <label class="custom-control-label" for="customSwitch1"></label>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row mb-4" *ngIf="isMissingPermission(permissionEnum.GLOBAL_VIDEOPRESENTATION)">
            <div class="w-75">
                <p>
                    <strong>Videopresentación</strong> 
                    <br>
                    Consiento el tratamiento de mi imagen y voz para su cesión a empresas interesadas en mi 
                    perfil, con arreglo a Información básica de privacidad.
                </p>
            </div>

            <div class="w-25 d-flex justify-content-end">
                <div class="custom-control custom-switch mt-3">
                    <input type="checkbox" class="custom-control-input" id="customSwitch1" formControlName="VIDEOPRESENTATION">
                    <label class="custom-control-label" for="customSwitch1"></label>
                </div>
            </div>
        </div>

        <div class="d-flex flex-row mb-4" *ngIf="isMissingPermission(permissionEnum.VIDEOINTERVIEW)">
            <div class="w-75">
                <p>
                    <strong>Videoentrevista</strong> 
                    <br>
                    Consiento el tratamiento de mi imagen y voz para su cesión a empresas interesadas en mi 
                    perfil, con arreglo a Información básica de privacidad.
                </p>
            </div>
            <div class="w-25 d-flex justify-content-end">
                <div class="custom-control custom-switch mt-3">
                    <input type="checkbox" class="custom-control-input" id="customSwitch2" formControlName="VIDEOINTERVIEW">
                    <label class="custom-control-label" for="customSwitch2"></label>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row mb-4" *ngIf="isMissingPermission(permissionEnum.TESTING)">
            <div class="w-75">
                <p>
                    <strong>Pruebas psicométricas</strong> 
                    <br>
                    Doy mi consentimiento a que los datos personales y conclusiones sobre mi perfil que derivan de las siguientes pruebas, sean transferidos a partners y proveedores fuera de la UE, con la finalidad de mejorar mis posibilidades de empleabilidad para la vacante a la que aplico, y de acuerdo a la política de privacidad de Involve RH. Puedes ejercer tus derechos a través de nuestra plataforma, o contactar con nuestro DPD en infosec@dposa.eu
                </p>
            </div>
            <div class="w-25 d-flex justify-content-end">
                <div class="custom-control custom-switch mt-3">
                    <input type="checkbox" class="custom-control-input" id="customSwitch3" formControlName="TESTING_SHARE">
                    <label class="custom-control-label" for="customSwitch3"></label>
                </div>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-center">
            <button class="btn btn-primary outline" type="button" (click)="registerSelectioProcess()">Continuar</button>
        </div>
    </form>
</div>

<ng-template #exceededAttemptsModal let-modal>
    <div class="modal-content">
      <div class="modal-body" style="text-align: center;">
        <div class="d-flex flex-row">
            <img src="/assets/svg/alert_icon.svg" alt="Alerta" class="img-fluid mr-1">
            <h4>¡Deja que conozcan tu potencial!</h4>
            <img src="/assets/svg/alert_icon.svg" alt="Alerta" class="img-fluid ml-1">
        </div>
        
        <p>
            <strong>Los buscadores de talento valoran más las postulaciones con videopresentación,</strong>  
            ya que ver los perfiles completos y postulaciones detalladas les permite conocer 
            mejor a los candidatos.
            ¿Estás completamente seguro de que no deseas compartir la siguiente información?
            </p>
            <ul>
                <li *ngIf="!getFieldValue(permissionEnum.VIDEOPRESENTATION)">Videopresentación</li>
                <li *ngIf="!getFieldValue(permissionEnum.VIDEOINTERVIEW)">Videoentrevista</li>
                <li *ngIf="!getFieldValue(permissionEnum.TESTING)">Pruebas psicométricas</li>
            </ul>  
      </div>
      <div class="modal-footer d-flex flex-row justify-content-center">
        <button type="submit" class="btn btn-min-width btn-primary " (click)="closeModal()">{{ 'interview.continueBtn' | translate }}</button>
      </div>
    </div>
  </ng-template>