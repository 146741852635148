<ng-container *ngIf="currentQuestionaryStep == 'welcome'">
  <div class="h-100vh d-md-flex flex-row-reverse">
    <div class="h-25vh h-md-100vh w-100 w-md-50">
        <img src="/assets/images/video-questions.jpg" alt="Involve video presentación" class="w-100 h-100 login-image">
    </div>
    <div class="min-h-50 h-md-100 w-100 w-md-50 d-flex flex-column align-items-center justify-content-center px-3 px-md-5 py-5 py-md-0 mx-auto">
        <div class="form-group-container">
            <img src="/assets/images/logo-involve.png" alt="Logo Involve RH" class="logo-involve mb-5 d-none d-md-block">
            <h2 class="mb-1 w-100">{{ 'cv.presentation.title' | translate }}</h2>
            <h5 class="mb-5 w-100">{{ 'cv.presentation.subTitle' | translate }}</h5>
            <div class="d-flex flex-column">
                <div class="mb-3 d-flex flex-column">
                    <h4>{{ 'cv.presentation.step' | translate }} 1</h4>
                    <p>{{ 'cv.presentation.description1' | translate }}</p>
                </div>
                <div class="mb-3 d-flex flex-column">
                    <h4>{{ 'cv.presentation.step' | translate }} 2</h4>
                    <p>{{ 'cv.presentation.description2' | translate }}</p>
                </div>
                <div class="mb-3 d-flex flex-column">
                    <h4>{{ 'cv.presentation.step' | translate }} 3</h4>
                    <p>{{ 'cv.presentation.description3' | translate }}</p>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div class="w-50 pr-1">
                    <a class="btn btn-outline-primary btn-block" routerLink="/dashboard/vacancies/search">{{ 'cv.generic.laterBtn' | translate }}</a>
                </div>
                <div class="w-50 pl-1">
                    <button class="btn btn-primary btn-block" (click)="onSetStep(1)">{{ 'cv.generic.continueBtn' | translate }}</button>
                </div>
            </div>

        </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="currentQuestionaryStep == 'selection'">
  <div class="d-flex flex-column">
    <div class="d-md-flex flex-row-reverse questions-container">
      <div class=" w-100 w-md-50 align-self-center d-md-flex p-5 d-none">
          <img src="/assets/svg/chico-involver.svg" alt="Involve video presentación" class="w-75 h-75">
      </div>
      <div class="min-h-50  w-100 w-md-50 d-flex flex-column align-items-center px-3 px-md-5 py-5 py-md-0 mx-auto question-check-container">
          <div class="form-group-container">
              <h5 class="mb-5 w-100">{{ 'cv.presentation.questions' | translate }}</h5>
              <div class="d-flex flex-column">
                  <div class="form-check  d-flex flex-column question-container" *ngFor="let question of questionsPresentation; let i = index;">
                      <label class="form-check-label ">
                          <p class="mb-0">
                            <input type="checkbox" class="form-check-presentation" [checked]="question.isSelected" (change)="onToggleSelection(question, $event)">
                            <span class="form-check-presentation"></span>
                            <strong>{{ 'cv.presentation.question' | translate }} {{ i + 1 }}</strong>
                          </p>
                          <p class="mb-0">
                            <span class="ml-5">{{ question.question }}</span>
                          </p>
                      </label>
                  </div>
              </div>
              <div class="d-flex justify-content-between buttons-container mt-5 mb-5">
                  <div class="w-50 pr-1">
                      <a class="btn btn-outline-primary btn-block" routerLink="/dashboard/vacancies/search">{{ 'cv.generic.laterBtn' | translate }}</a>
                  </div>
                  <div class="w-50 pl-1">
                      <button class="btn btn-primary btn-block next-button" (click)="onSetStep(2)" [ngClass]="{'disabled': selectedQuestions.length != 2}">{{ 'cv.generic.continueBtn' | translate }}</button>
                  </div>
              </div>
  
          </div>
      </div>
    </div>
    <div class="w-100 footer  mt-5">
      <img class="w-100 desktop-img" src="../../../../assets/svg/curvas.svg" alt="Curvas">
      <img class="w-100 mobile-img" src="../../../../assets/svg/curvas_mobile.svg" alt="Curvas">
    </div>
  </div>

</ng-container>
<ng-container *ngIf="currentQuestionaryStep == 'record'">
  <div class="w-100 presentation-screen d-flex background-container flex-column mt-5"  [ngClass]="{ 'justify-content-center': feedbackMessage == undefined || feedbackMessage != '' }">
    <div class="d-flex align-items-center justify-content-center flex-column px-3 px-md-5" *ngIf="feedbackMessage == undefined || feedbackMessage != ''">
      <h1 class="font-weight-bold">{{ 'cv.presentation.permission' | translate }}</h1>
      <h4 class="color-E600" *ngIf="feedbackMessage === 'Permission denied'">{{ 'cv.presentation.deny' | translate }}</h4>
      <h4 class="color-E600" *ngIf="feedbackMessage === 'Could not start video source'">{{ 'cv.presentation.inUse' | translate }}</h4>
      <p>{{ 'cv.presentation.start' | translate }}</p>
      <p>
        {{ 'cv.presentation.clic' | translate }} 
        <a target="_blank" href="https://support.google.com/chrome/answer/2693767">{{ 'cv.presentation.here' | translate }}</a> 
        {{ 'cv.presentation.activate' | translate }}
      </p>
    </div>
    <div class="d-none flex-column h-100" [ngClass]="{ 'd-flex': feedbackMessage != undefined && feedbackMessage == '' }">
      <div class="video-container d-flex align-items-center flex-column">
        <div class="display-question-container d-flexpx-2 flex-column white-background"  *ngIf="isRecording">
          <div class="d-flex justify-content-end ">
            <p class="mb-0 question-number">Pregunta {{ currentDisplayQuestionNumber }} de 2</p>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <span class="question-text">{{ currentDisplayQuestion }}</span>
            <span class="align-self-end timer-count"><span class="recording-icon"></span> {{ recordedtime || '' }}</span>
          </div>
        </div>
        <div class="w-100 py-2 position-relative" >
          <div class="video presentation-container">
            <app-record [recordService]="recordService" [fileName]="fileName"></app-record>
          </div>
        </div>

        
        <div class="d-flex justify-content-center white-background record-buttons mt-2" [ngClass]="{ 'justify-content-between': (isRecording && currentDisplayQuestionNumber == 1) || recordedVideoUrl }">

          <button class="btn btn-primary px-md-4 mx-2" 
            *ngIf="!isRecordedFinished && !isRecording && recordStatus != 'COUNTER'" (click)="startRecording()" [disabled]="remainingSecondToRecord > 0 && currentDisplayQuestionNumber == 1" >
            {{ 'cv.presentation.startRecording' | translate }}
          </button>
          <button class="btn btn-outline-primary px-md-4 mx-2" 
          *ngIf="isRecording" (click)="stopRecording()" [ngClass]="{ 'btn-outline': currentDisplayQuestionNumber == 1 }">
            {{ 'cv.presentation.stopRecording' | translate }}
          </button>
          <button class="btn btn-primary px-md-4 mx-2" 
            *ngIf="isRecording && currentDisplayQuestionNumber == 1" (click)="onDisplayNextQuestion()">
            {{ 'cv.presentation.nextQuestion' | translate }}
          </button>
          <button class="btn btn-outline-primary px-md-4 ve btn-outline mx-2" 
            *ngIf="isRecordedFinished && !isRecording" (click)="onRestartRecording()">
            {{ 'cv.presentation.restartRecording' | translate }}
          </button>
          <button class="btn btn-primary px-md-4 mx-2" 
            *ngIf="isRecordedFinished && !isRecording" (click)="onSubmitVideo()">
            {{ 'cv.generic.saveBtn' | translate }}
          </button>
        </div>
      </div>


    </div>
    
  </div>
</ng-container>

<ng-template #recordErrorModal  let-modal>
  <div class="modal-content px-4 py-1">
    <div class="modal-body" style="text-align: center;">
       <p class="error-text">{{ recordErrorText}}</p>
   </div>
   <div class="modal-footer d-flex flex-row justify-content-center">
    <button type="button"  (click)="closeRecordModal()" [routerLink]="['/dashboard/vacancies/postulations/active']" class="btn btn-min-width btn-primary">Entendido</button>
   </div>
 </div>
</ng-template>
