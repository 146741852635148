import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { IPostulation } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

export enum Permission {
  GLOBAL_VIDEOPRESENTATION ='VIDEOPRESENTATION',
  VIDEOPRESENTATION = 'VIDEOPRESENTATION_SHARE',
  VIDEOINTERVIEW = 'VIDEOINTERVIEW',
  TESTING = 'TESTING_SHARE'
}
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent {

  currentPostulation: any;
  selectionProcessId!: string;
  type!: string;
  missingPermissions: Permission[] = []
  psychometricComplete: any;
  permissionEnum = Permission;
  permissionForm!: FormGroup;
  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private fb: FormBuilder,
    private storageService: StorageService
  ) {
    this.route.params.subscribe(params => {
      this.type = params['type'];
      this.selectionProcessId = params['selectionProcessId'];
      if(this.selectionProcessId){
        this.getCurentPostulation()
      }else{
        if(this.checkGlobalPermission(Permission.GLOBAL_VIDEOPRESENTATION)){
          this.router.navigateByUrl('/dashboard/acceptance/videopresentation');
        }else{
          this.initializeMissingPermissions();
        }
      }
    });
  }


  initializeMissingPermissions() {
    switch (this.type) {
      case 'videopresentation':
        if(this.selectionProcessId === undefined){
          this.missingPermissions = [
            Permission.GLOBAL_VIDEOPRESENTATION
          ];
        }else{
          this.missingPermissions = [
            Permission.VIDEOPRESENTATION,
            Permission.VIDEOINTERVIEW,
            ...(this.currentPostulation.vacant.containPsychometricTest ? [Permission.TESTING] : [])
          ];
        }

        break;

      case 'videointerview':
        this.missingPermissions = [
          Permission.VIDEOINTERVIEW,
          ...(this.currentPostulation.vacant.containPsychometricTest ? [Permission.TESTING] : [])
        ];
        break;

      case 'psycometrics':
        this.missingPermissions = [
          ...(this.currentPostulation.vacant.containPsychometricTest ? [Permission.TESTING] : [])
        ];
        break;

      default:
        break;
    }
    this.initializeForm();
  }

  initializeForm() {
    this.permissionForm = this.fb.group({});
    const filteredPermissions = this.getFilteredPermissions(this.currentPostulation);
    Object.keys(filteredPermissions).forEach((permissionType: string) => {
      if (!filteredPermissions[permissionType].status) {
        this.permissionForm.addControl(permissionType, this.fb.control(false));
      }
    });

    this.missingPermissions.forEach((permission: Permission) => {
      if (!this.permissionForm.contains(permission)) {
        this.permissionForm.addControl(permission, this.fb.control(false));
      }
    });

    console.log(this.permissionForm);
    
  }

  getFilteredPermissions(postulation: IPostulation): any {
    let filteredPermissions: any = {};
    if(this.selectionProcessId === undefined) {
      filteredPermissions[Permission.GLOBAL_VIDEOPRESENTATION] = {};
    }else{
      postulation.permissions.reduce((acc: any, permission: any) => {
        if (acc[permission.permissionType] && new Date(acc[permission.permissionType].createDate) < new Date(permission.createDate)) {
          acc[permission.permissionType] = permission;
        } else if (!acc[permission.permissionType]) {
          acc[permission.permissionType] = permission;
        }
        return acc;
      }, {});
  
      if (!postulation.vacant.containPsychometricTest) {
        delete filteredPermissions[Permission.TESTING];
      }
    }
    return filteredPermissions;
  }

  assignSProccessPsychometrics() {
    if (this.isMissingPermission(Permission.TESTING)) {
      this.apiService.assignSProccessPsychometrics(this.selectionProcessId).subscribe({
        next: (getPsychometricsBySelectionProcessIdResponse: HttpResponse<any>) => {
          this.apiService.getPsychometricsBySProccess(this.selectionProcessId).subscribe({
            next: (getPsychometricsBySelectionProcessIdResponse: HttpResponse<any>) => {
              this.psychometricComplete = getPsychometricsBySelectionProcessIdResponse.body.filter((test: any) => test.dateEndFinal != null);
            }
          });
        }
      });
    }
  }

  isMissingPermission(param: Permission): boolean {
    return this.missingPermissions.includes(param);
  }

  getCurentPostulation(redirect: boolean = false){
      if (this.selectionProcessId === undefined) {
        if (this.getFieldValue(Permission.GLOBAL_VIDEOPRESENTATION) || false) {
          this.router.navigateByUrl('/dashboard/my-cv/presentation');
        }else{
          this.router.navigateByUrl('/dashboard/my-cv');
        }
    }else{
      this.apiService.getPostulation(this.selectionProcessId).subscribe({
        next: async (getPostulationResponse: HttpResponse<IPostulation>) => {
          this.currentPostulation =  getPostulationResponse.body;
          if (redirect) {
            const targetPath = await this.onboardingService.pathNewRedirectionPostulation(this.currentPostulation, true);
            this.router.navigateByUrl(targetPath, { replaceUrl: true, state: { postulation: this.currentPostulation } }); 
          }else{
            this.initializeMissingPermissions();
          }
        },
        error: (getPostulationError: HttpErrorResponse) => {
          this.toastService.error('', this.translateService.instant('errors.generic.default'));
        }
      });
    }

  }

  checkGlobalPermission = (permissionType: Permission) => {
    const currentCandidate = JSON.parse(this.storageService.get('candidate') || 'null');
    const permission = currentCandidate.user.permissions.filter(
      (permission: any) => permission.permissionType === permissionType && permission?.status
    );
    return permission.length > 0;
  };

  checkAllFieldsAreTrue(): boolean {
    const formValues = this.permissionForm.value;
    const fieldValues = Object.values(formValues);
    return fieldValues.every(value => value === true);
  }

  registerGlobalPermission() {
    if (this.selectionProcessId === undefined && this.permissionForm.get(Permission.GLOBAL_VIDEOPRESENTATION)?.value === false){
      this.router.navigateByUrl('/dashboard/my-cv');
    }else{
      let params = this.missingPermissions
      .map(permission => {
      return {
        "permissionType": permission,
        "status": this.getFieldValue(permission) || false
      };
      });
    this.apiService.acceptPermission(params).subscribe({
    next:async (getCatalogResponse: any) => {
      this.getCurentPostulation(true)  
    }, 
    error:() => {
      this.toastService.error('', this.translateService.instant('errors.generic.default'));
    }});
  }


  }

  registerSelectionProcessPermission() {
    let params = this.missingPermissions.map(permission => {
      return {
        "permissionType": permission,
        "status": this.getFieldValue(permission) || false
      };
    });
    this.apiService.registerPermissionSelectionProcess(params, this.selectionProcessId).subscribe({
    next:async (getCatalogResponse: any) => {
      if((!this.checkGlobalPermission(Permission.GLOBAL_VIDEOPRESENTATION))){
        this.registerGlobalPermission()
      }
      this.assignSProccessPsychometrics()
      this.getCurentPostulation(true)         
    }, 
    error:() => {
      this.toastService.error('', this.translateService.instant('errors.generic.default'));
    }});
  }

  registerSelectioProcess(){
    if(this.selectionProcessId === undefined){
      this.registerGlobalPermission()
    }else{
      this.registerSelectionProcessPermission()
    }
  }

  closeModal(){
    
  }

  getFieldValue(fieldName: string): any {
    return this.permissionForm.get(fieldName)?.value;
  }
}
