<div class="d-flex flex-column">
        <div class="h-100 w-100 mt-5 w-md-50 overflow-auto d-flex flex-column align-items-center justify-content-center px-3 py-5 py-md-0 mx-auto">
        <div class="form-group-container">
            <div class="mb-5 d-flex justify-content-center">
                <img src="/assets/images/logo-involve.png" alt="Logo Involve RH" class="logo-involve">
            </div>
            <div class="mb-4 d-flex justify-content-center title-container">
                <h3 *ngIf="currentPath == 'restore-password'" class="mb-1">{{ 'resetPassword.title' | translate }}</h3>
                <!-- TODO: Validar flujo para restablecer contraseña -->
                <h3 *ngIf="currentPath == 'set-password'" class="mb-1">{{ 'resetPassword.subTitle' | translate }}</h3>
            </div>
            <!-- Usar si se abrió el componente por contraseña olvidada  -->
            <form class="form-container w-100 px-3  column involve-ui" [formGroup]="passwordForm">
                <ng-container *ngIf="!passwordControl.valid" class="col-12">
                    <p>{{ 'resetPassword.passwordRequirements' | translate }}: </p>
                    <ul class="mb-5 ml-3">
                        <li [ngClass]="{ 'higlihted': !hasFormControlError('password', 'lowercase', false) }" class="higlihted">{{ 'passwordValidation.lowercase' | translate }}</li> 
                        <li [ngClass]="{ 'higlihted': !hasFormControlError('password', 'uppercase', false) }" class="higlihted">{{ 'passwordValidation.uppercase' | translate }}</li> 
                        <li [ngClass]="{ 'higlihted': !hasFormControlError('password', 'number', false) }" class="higlihted">{{ 'passwordValidation.number' | translate }}</li> 
                        <li [ngClass]="{ 'higlihted': !hasFormControlError('password', 'minLength', false) }">{{ 'passwordValidation.minimumCharacters' | translate }}</li> 
                    </ul>
                </ng-container>
                <div class="form-group">
                    <div class="input-container">
                        <label class="label">{{ 'resetPassword.password' | translate }}</label>
                        <input class="input" 
                        type="password"
                        formControlName="password"
                        appTogglePasswordType maxlength="25">
                    </div>
                </div>
                <div  *ngIf="passwordControl.valid" >
                    <p class="color-P100 mb-5">
                        <i class="eva eva-checkmark-circle-outline align-middle"></i>
                        <span>{{ 'resetPassword.securePassword' | translate }}</span>
                    </p>
                    <div class="form-group mb-5">
                        <div class="input-container">
                            <label class="label">{{ 'resetPassword.confirmPassword' | translate }}</label>
                            <input class="input" 
                            type="password"
                            formControlName="confirmPassword"
                            appTogglePasswordType maxlength="25">
                            <small class="form-text color-E600" *ngIf="passwordForm.errors?.['differentPassword'] && confirmPasswordControl.touched && !hasFormControlError('confirmPassword', 'required')">{{ 'settings.changePassword.inputError.differentPassword' | translate }}</small>
                        </div>
                    </div>
                </div>
                <div class="mt-3 d-flex justify-content-center">
                    <button class="btn btn-primary btn-block mb-4" [ngClass]="{ 'disabled': passwordForm.invalid }" *ngIf="passwordControl.valid && currentPath == 'set-password'"
                    (click)="onCreatePassword()">{{ 'resetPassword.nextBtn' | translate }}</button>
                    <button class="btn btn-primary btn-block mb-4" *ngIf="passwordControl.valid && currentPath == 'restore-password'"
                    (click)="onResetPassword()">{{ 'resetPassword.nextBtn' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
    <div class="w-100 footer mt-5">
        <img class="w-100 desktop-img" src="../../../../assets/svg/curvas.svg" alt="Curvas">
        <img class="w-100 mobile-img" src="../../../../assets/svg/curvas_mobile.svg" alt="Curvas">
        <div class="footer-content w-100">
            <span>
                {{ 'onboarding.legals.knowOur' | translate}}
                <a [attr.href]="getCountryPrivacyLink()" target="_blank" >{{ 'onboarding.legals.privacy' | translate }}</a>, 
                <a [attr.href]="getCountryTermsLink()" target="_blank" >{{ 'onboarding.legals.terms' | translate }}</a> {{'onboarding.legals.prepositionY' | translate}}
                <a [attr.href]="getCountryCookiesLink()" target="_blank" >{{ 'onboarding.legals.cookies' | translate }}</a>
            </span>
        </div>
    </div>
</div>