<div class=" d-flex w-100 position-fixed background-B300 email-sent-container">
    <div class="h-100 w-100 w-md-60 overflow-auto d-flex flex-column align-items-center justify-content-center px-3 py-5 py-md-0 mx-auto">
        <div class="form-group-container d-flex flex-column">
            <img src="/assets/images/logo-involve.png" alt="Logo Involve RH" class="logo-involve mb-5 mx-auto d-block">
            <img src="/assets/svg/sobre.svg" alt="Logo Involve RH" class="image-md mt-5 mb-4 mx-auto d-block">
            <h3 class="mb-1 mt-1 w-100 text-center">
                <ng-content select="#title">
                </ng-content>
            </h3>
            <h5 class="mb-5 mt-2 w-100 text-center">
                <ng-content select="#detailedMessage">
                </ng-content>
            </h5>
            <h6 class="w-100 text-center">{{ 'emailSent.subtitle1' | translate }}</h6>
            <h6 class="w-100 text-center mb-5">{{ 'emailSent.subtitle2' | translate }}</h6>
            <div class="d-flex flex-column align-items-center pt-1 mt-2">
                <button *ngIf="isMainActionButtonShown" class="btn btn-primary btn-block mb-4 reesend-btn mt-2" (click)="onMainActionClicked()">{{ 'emailSent.resend' | translate }}</button>
                <button class="btn btn-primary reesend-btn" (click)="continue()" >Continuar sin verificar</button>
            </div>
        </div>
    </div>
</div>