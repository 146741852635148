import { Component } from '@angular/core';

@Component({
  selector: 'app-ui-library',
  templateUrl: './ui-library.component.html',
  styleUrls: ['./ui-library.component.scss']
})
export class UiLibraryComponent {

}
