import { Injectable } from '@angular/core';
import { AngularToastService } from 'angular-toasts';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastService: AngularToastService) { }

  success(title: string, message: string, showsImage = true){
    this.toastService.success(title, message, {
      hideImage: (!showsImage).toString(),
      timeOut: '5000'
    });
  }
  
  error(title: string, message: string, showsImage = true){
    this.toastService.error(title, message, {
      hideImage: (!showsImage).toString(),
      timeOut: '5000'
    });
  }
}
