<div class="container-fluid mt-5">
    <div class="row">
        <div class="text-center">
            <h2>Changelog</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ul class="ml-3">
                <li>
                    <span>
                        2.7.0 -
                        <small>
                            22/02/2024
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Feat: Nuevo flujo de postulación con pasos opcionales.</li>
                        <li>Fix: Apagado de cámara al finalizar y al reiniciar grabación en presentación.</li>
                        <li>Feat: Nuevo componente para reproducción de video con controles personalizados.</li>
                        <li>Feat: Rediseño mis postulaciones, buscador de vacantes, Destalle de vacante, flujo grabación de video y psicometricas.</li>
                        <li>Feat: rediseño y refactor de cuestionario.</li>
                        <li>Feat: HU Redirección de un candidato recién registrado a postulación.</li>
                        <li>Feat: Tabla de psicometrías con redirección directa a prueba.</li>
                        <li>Feat: Nuevo mapeo de respuestas por cambios de back en servicios (magnagement, user, files). </li>
                        <li>Feat: Ampliación de paginado de favoritos a 50.</li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.6.3 -
                        <small>
                            15/01/2024
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Fix: Mejora en estilos y distribucion de elementos en login y registro</li>
                        <li>Feat: Cambio en texto copy de bullets de registro</li>
                        <li>Feat: Se agrega video de Youtube a pantalla de registro</li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.6.2 -
                        <small>
                            05/12/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Feat: Se agregan pantallas de confirmación en Presentación, Video entrevista y Psicométricas</li>
                        <li>Fix: Se desactiva cámara al salir de componente de grabación</li>
                        <li>Feat: Se agrega pantalla para configuracion de notificaciones en onboarding</li>
                        <li>Feat: Se agrega modal de expiración por inactividad</li>
                        <li>Feat: Se agregan términos y condiciones en footer</li>
                        <li>Feat: Rediseño de pantalla de registro</li>
                        <li>Fix: Se arregla submit de formularios de CV</li>
                        <li>Feat: Rediseño de pantalla de mantenimiento</li>
                        <li>Feat: Se cambian servicios de /notification a /user</li>
                        <li>Feat: Se agrega sección de notificaciones desde configuracion</li>
                        <li>Fix: Se agrega validador de archivos PDF a OCR</li>
                        <li>Feat: Se define lenguaje por default al país de ubicación</li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.6.1 -
                        <small>
                            07/11/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat: - Se agrega rediseño de CV
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.6.0 -
                        <small>
                            16/10/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat: - Se muestra error cuando el CV archivo no es valido
                        </li>
                        <li>
                            Feat: -  Se agrega parametro fileName al componente de grabación
                        </li>
                        <li>
                            Fix: -  Mapeo de errores cuando el video grabado es muy corto
                        </li>
                        <li>
                            Fix: - Campo de años de experiencia ahora solo permite ingresar numeros
                        </li>
                        <li>
                            Feat: - Se quita campo pais de actualizar perfil candidato
                        </li>
                        <li>
                            Feat: - Se agrega servicio parser
                        </li>
                        <li>
                            Feat: - Nuevo onboarding de dos pasos
                        </li>
                        <li>
                            Feat: - Se corrige path eliminar vacantes
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.5.1 -
                        <small>
                            04/09/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3748/">INV-3748</a>
                            - Derecho de rectificación (Candidato) (position, country, state, city, nationality, salary)
                        </li>
                        <li>Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3872/">INV-3872</a>
                            - Actualización de email desde Configuración
                        </li>
                        <li>Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3859/">INV-3859</a>
                            - Actualización de número telefónico desde Configuración
                        </li>
                        <li>Fix: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3751/">INV-3751</a>
                            - Derecho de cancelación
                        </li>
                        <li>Fix: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2886/">INV-2886</a>
                            - Se puede agregar muchas veces la misma habilidad
                        </li>
                        <li>Fix: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2846/">INV-2846</a>
                            - Al elegir de la lista un puesto y se da un enter te regresa al paso anterior
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.5.0 -
                        <small>
                           21/08/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Feat: Nuevo componente para grabación de video</li>
                        <li>Rip: Javier Olvera ✞</li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.4.2 -
                        <small>
                            07/08/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Feat: Unificar microservios a User</li>
                        <li>Fix bug: Error 'replaceAll is not a function' enviado a rollbar</li>
                        <li>Fix bug: Error 'Item sent with null' enviado a rollbar </li>
                        <li>Fix bug: Error 'undefined currentCandidate' enviado a rollbar</li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.4.1 -
                        <small>
                            27/07/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Fix bugs: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3758/">INV-3758</a>
                            - Revisión España
                        </li>
                        
                    </ul>
                </li>
                <li>
                    <span>
                        2.4.0 -
                        <small>
                            25/07/2023
                        </small>
                    </span>
                </li>
                <li>
                    <span>
                        2.3.6 -
                        <small>
                            25/07/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>Fix: <a target="_blank" href="https://linear.app/involverh/issue/QA-106/correcciones-revision-candidato-mx-esp">QA-106</a>
                            - Correcciones Revisión Candidato MX ESP
                        </li>
                        <li>Feat: <a target="_blank" href="https://linear.app/involverh/issue/INV-3650/">INV-3650</a>
                            - Chat de Bitrix ESP
                        </li>
                        <li>Feat: <a target="_blank" href="https://linear.app/involverh/issue/INV-3728/">INV-3728</a>
                            - Componentes se crean dos veces pero sólo se visualizan una vez
                        </li>
                        <li>Feat: 
                            - Pantalla de mantenimiento
                        </li>
                        <li>Feat: 
                            - 🥳Implementación España
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.3.5 -
                        <small>
                            19/07/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3594/">INV-3594</a>
                            - Botón de eliminar sólo en postulaciones activas
                        </li>
                        <li>
                            Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3320/">INV-3320</a>
                            - Adaptaciones en pruebas psicométricas
                        </li>
                        <li>
                            Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3639/">INV-3639</a>
                            - Catálogo de estatus académico por nivel educativo
                        </li>
                        <li>Feat: Lista de postulaciones ordenadas por fecha de postulación</li>
                        <li>Feat: Locale en chat</li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.3.4 -
                        <small>
                            17/07/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2751/">INV-2751</a>
                            - Creación de airBrake Service (Se cambio por rollbar)
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.3.3 -
                        <small>
                            17/07/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix: Validación en Guard para steepsOnboarding
                        </li>
                        <li>
                            Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3639/">INV-3639</a>
                            - Catálogo de estatus académico por key system
                        </li>
                        <li>
                            Feat: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3317/">INV-3317</a>
                            - Validación de cuenta vía SMS por key system
                        </li>

                        <li>
                            Fix bug: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3326/">INV-3326</a>
                            - Moneda Euro para España
                        </li>
                        <li>
                            Fix bug: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2852/">INV-2852</a>
                            - Caracteres especiales en código de teléfono
                        </li>
                        <li>
                            Fix bug: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3684/">INV-3684</a>
                            - Legales en URLs públicas
                        </li>
                        <li>
                            Fix bug: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2891/">INV-2891</a>
                            - Editar puesto en onboarding al regresar desde el paso 8
                        </li>
                        <li>
                            Feat: Motor de traducción por idioma del navegador
                        </li>
                        <li>
                            Feat: Mensajes de debug para equipo de QA en ambientes de prueba
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.3.2 -
                        <small>
                            29/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix bug: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2852/">INV-2852</a>
                            - Caracteres especiales en campos de código OTP
                        </li>
                        <li>
                            Fix bug: 
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3314/">INV-3314</a>
                            - Se puede saltar el onboarding
                        </li>
                        <li>
                            Feat:
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3400/">INV-3400</a>
                            - Motor de traducción
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.3.1 -
                        <small>
                            20/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            hotfix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3558/">INV-3558</a>
                            - Cambio de flujo validación SMS
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.3.0 -
                        <small>
                            20/06/2023
                        </small>
                    </span>
                </li>
                <li>
                    <span>
                        2.2.7 -
                        <small>
                            19/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Re-fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3384/">INV-3384</a>
                             - Buscador de vacantes paginado
                        </li>
                        
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.6 -
                        <small>
                            19/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Re-fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3355/">INV-3355</a>
                             - Actualización de datos en CV
                        </li>
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3536/">INV-3536</a>
                             - No se pueden guardar habilidades duras que no existen en el catálogo
                        </li>
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3543/">INV-3543</a>
                             - Foto de perfil no se actualiza correctamente
                        </li>
                        <li>
                            Feat
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3384/">INV-3384</a>
                             - Buscador de vacantes paginado
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.5 -
                        <small>
                            12/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3355/">INV-3355</a>
                             - Actualización de datos en CV
                        </li>
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3356/">INV-3356</a>
                             - Actualización de datos en CV
                        </li>
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3358/">INV-3358</a>
                             - Actualización de datos en CV
                        </li>
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3340/">INV-3340</a>
                             - Cerrar sesión si getCandidate es null
                        </li>
                        <li>
                            Fix bug
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3316/">INV-3316</a>
                             - Doble toast al iniciar sesión con contraseña incorrecta
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.4 -
                        <small>
                            09/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3350/">INV-3350</a>
                             - Chat de Bitrix
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.3 -
                        <small>
                            09/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2834/">INV-2834</a>
                             - Flujo: Mi perfil
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.2 -
                        <small>
                            01/06/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3002/">INV-3002</a>
                             - Cambiar mecanismo de guardado de videos desde candidato
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.1 -
                        <small>
                            31/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3168/">INV-3168</a>
                             - Rango de edad en registro se modifica de 18 a 120 años
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3234/">INV-3234</a>
                             - Continuar proceso no redirige a psicométricas
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3235/">INV-3235</a>
                             - Envío de respuestas duplicadas si el servicio falla
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.2.0 -
                        <small>
                            25/05/2023
                        </small>
                    </span>
                </li>
                <li>
                    <span>
                        2.1.8 -
                        <small>
                            22/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2869/">INV-2869</a>
                             - Botón postulaciones activas/vencidas en mobile
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2600/">INV-2600</a>
                             - Detalle de vacante oculto si vacante no activa
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2703/">INV-2703</a>
                             - Logo involve con mala calidad
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.7 -
                        <small>
                            22/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feature
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2743/">INV-2743</a>
                             - Flujo: Ajustes
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.6 -
                        <small>
                            22/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Re-fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2952/">INV-2952</a>
                             - Búsqueda con 0 en min o max salary
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.5 -
                        <small>
                            22/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Re-fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2952/">INV-2952</a>
                             - Salario en buscador de vacantes
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.4 -
                        <small>
                            22/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Hotfix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3165/">INV-3165</a>
                             - Restablecer contraseña: Al bloquear la cuenta no me permite restablecerla
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.3 -
                        <small>
                            22/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2952/">INV-2952</a>
                             - Buscador de vacantes
                        </li>
                        <li>
                            Feat - Se oculta selector de país en búsqueda de vacantes
                        </li>
                        <li>
                            Feat - Confirmación en el navegador al salir mientras se está grabando
                        </li>
                        <li>
                            Fix - Reset password URL
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.2 -
                        <small>
                            18/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feat
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-3130/">INV-3130</a>
                             - Confirmación al salir de pantalla de videoentrevista 
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2894/">INV-2894</a>
                             - No me permite continuar con mi postulacion 
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.1 -
                        <small>
                            18/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix: Cerrar sesion para usuarios no autorizados
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.1.0 -
                        <small>
                            16/05/2023
                        </small>
                    </span>
                </li>
                <li>
                    <span>
                        2.0.4 -
                        <small>
                            16/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2984/">INV-2984</a>
                             - En el perfil de candidato no se muestran activas las redes sociales configuradas 
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2978/">INV-2978</a>
                             - Header en detalle de vacante sin sesión iniciada 
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2968/">INV-2968</a>
                             - Cursos: No debería de permitir el ingreso de 00 hrs en el campo de duración de horas 
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2974/">INV-2974</a>
                             - certificaciones: No debe de permitir el ingreso de emojis en los campos 
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2967/">INV-2967</a>
                             - cursos: No debería de poder ingresar una fecha futura
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.0.3 -
                        <small>
                            15/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2882/">INV-2882</a>
                             - Nombre en países
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2879/">INV-2879</a>
                             -  Educación: se puede ingresar una fecha mayor en el inicio que el termino
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2875/">INV-2875</a>
                             - Entrevista laboral: Se puede ingresar números negativos en gente a tu cargo
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2867/">INV-2867</a>
                             - PositionRequired en mi-cv
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2963/">INV-2963</a>
                             - Habilidades blandas: Se puede agregar muchas veces la misma habilidad.
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2969/">INV-2969</a>
                             - Cursos: No debe de permitir el ingreso de emojis y caracteres especiales
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2970/">INV-2970</a>
                             - Cursos: No se puede editar un curso se tiene que borrar y hacer nuevamente
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2975/">INV-2975 </a>
                             - Certificaciones: Debería de validar el campo de url que en verdad sea una URL
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2973/">INV-2973 </a>
                             - Certificaciones: No debería de poder agregar una fecha anterior a la fecha de expedición
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2972/">INV-2972 </a>
                             - Certificaciones: No debería de poder agregar una fecha futura en el campo de fecha de expedición
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2971/">INV-2971 </a>
                             - Certificaciones: Cuando se agrega una certificación se muestra una alerta de error
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2961/">INV-2961 </a>
                             - habilidades blandas: Si se ingresa una habilidad que no se encuentra dentro de la lista no se puede agregar una nueva habilidad.
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2896/">INV-2896 </a>
                             - video entrevista: No se muestra la alerta de que es el ultimo intento
                        </li>
                        <li>
                            Fix
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2863/">INV-2863 </a>
                             - postulaciones: Se debe de mostrar una alerta de confirmación al eliminar una vacante
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.0.2 -
                        <small>
                            09/05/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feature
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2740/">INV-2740</a>
                             - Agregar/Editar certificaciones
                        </li>
                        <li>
                            Feature
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2739/">INV-2739</a>
                             - Agregar/Editar cursos
                        </li>
                        <li>
                            Feature
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2741/">INV-2741</a>
                             - Agregar/Editar idiomas
                        </li>
                        <li>
                            Feature
                            <a target="_blank" href="https://linear.app/involverh/issue/INV-2738/">INV-2738</a>
                             - Agregar/Editar soft skills
                        </li>
                        <li>
                            Feature
                             - gráfico de porcentaje en CV
                        </li>
                    </ul>
                </li>
                <li>
                    <span>
                        2.0.1 -
                        <small>
                            28/04/2023
                        </small>
                    </span>
                    <ul class="ml-4">
                        <li>
                            Feature
                             - Número de versión en el header
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
