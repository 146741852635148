import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountStatusHandlerPageComponent } from './pages/account-status-handler-page/account-status-handler-page.component';
import { TermsAndConditionsComponent } from './modules/shared/pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyNoticeComponent } from './modules/shared/pages/privacy-notice/privacy-notice.component';
import { CookiesPolicyComponent } from './modules/shared/pages/cookies-policy/cookies-policy.component';
import { ChangelogComponent } from './modules/shared/pages/changelog/changelog.component';
import { UiLibraryComponent } from './modules/shared/pages/ui-library/ui-library.component';
import { ServicesMapComponent } from './modules/shared/pages/services-map/services-map.component';
import { VacancyDetailPageComponent } from './modules/shared/components/vacancy-detail-page/vacancy-detail-page.component';
import { SignInUpPageComponent } from './pages/sign-in-up-page/sign-in-up-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { ResetPasswordPageComponent } from './modules/shared/components/reset-password-page/reset-password-page.component';
import { CanActivateViaAuthGuard } from './services/guard/auth.guard';
import { CvGuard } from './services/guard/cv.guard';
import { appSettings } from './globals/appSettings';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { ChangeEmailComponent } from './modules/dashboard-outlet/pages/settings-outlet/components/change-email/change-email.component';
import { ChangeEmailVerifyComponent } from './pages/change-email-verify/change-email-verify.component';


  let routes: Routes = [
  {
    path: 'sign-in',
    component: SignInUpPageComponent
  },
  {
    path: 'sign-up',
    component: SignInUpPageComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPageComponent
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordPageComponent
  },
  {
    path: 'restore-password/:token',
    component: ResetPasswordPageComponent
  },
  {
    path: 'activate-account/:token',
    component: AccountStatusHandlerPageComponent
  },
  {
    // ! This path is added as legacy in order not to block users who clicks previous emails
    // ! In the future, we'll be able to remove this path and use only "activate-account". 
    // ! But not today...
    path: 'candidate-count/:token',
    component: AccountStatusHandlerPageComponent
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./modules/onboarding-outlet/onboarding-outlet.module').then(m => m.OnboardingOutletModule),
    canActivate: [CanActivateViaAuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard-outlet/dashboard-outlet.module').then(m => m.DashboardOutletModule),
    canActivate: [CanActivateViaAuthGuard, CvGuard]
  },
  {
    path: 'vacancy/:vacancyId',
    component: VacancyDetailPageComponent
  },
  {
    path: 'vacancy/:vacancyId/:apply',
    component: VacancyDetailPageComponent
  },
  {
    // This path is added as legacy in order not to block users who comes from "Bolsa de empleo"
    path: 'postulation/:vacancyId',
    component: VacancyDetailPageComponent,
    canActivate: [CanActivateViaAuthGuard, CvGuard]
  },
  {
    path: 'change-email',
    component: ChangeEmailVerifyComponent,
  },
  {
    path: 'public',
    children: [
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent
      },
      {
        path: 'privacy-notice',
        component: PrivacyNoticeComponent
      },
      {
        path: 'cookies-policy',
        component: CookiesPolicyComponent
      },
      {
        path: 'changelog',
        component: ChangelogComponent
      }
    ]
  },
  {
    path: 'private',
    children: [
      {
        path: 'ui-library',
        component: UiLibraryComponent
      },
      {
        path: 'services',
        component: ServicesMapComponent
      }
    ]
  },
  {
    path: '',
    component: SignInUpPageComponent
  },
  {
    path: 'maintenance',
    pathMatch: 'full',
    component: MaintenanceComponent
  }
  ,
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

if (appSettings.countrySettings.inMaintenance && !localStorage.getItem('mnt-dev')) {
  routes.unshift({
    path: '**',
    component: MaintenanceComponent
  })
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
