import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { appSettings } from '../globals/appSettings';

const currentKeySystem = 'COL';

const COUNTRY_DATA: { [key: string]: any } = {
  'USD': { code: 'USD', digits: '1.2', locale: 'en-US', enableCode: false, currencyCode: 'USD' },
  'MXN': { code: 'USD', digits: '1.2', locale: 'en-US', enableCode: false, currencyCode: 'MXN' },
  'EUR': { code: 'EUR', digits: '1.2', locale: 'de-DE', enableCode: false, currencyCode: 'EUR' },
  'COP': { code: 'COP', digits: '1.2', locale: 'es-CO', enableCode: false, currencyCode: 'COP' },
  'PEN': { code: 'PEN', digits: '1.2', locale: 'es-PE', enableCode: false, currencyCode: 'PEN' }, // Perú
  'CLP': { code: 'CLP', digits: '1.0', locale: 'es-CL', enableCode: false, currencyCode: 'CLP' }, // Chile
  'ARS': { code: 'ARS', digits: '1.2', locale: 'es-AR', enableCode: false, currencyCode: 'ARS' }, // Argentina
};

@Pipe({
  name: 'custom_currency',
})
export class CustomCurrencyPipe implements PipeTransform {

  transform(
    value: number | string,
    config: any = {
      country: currentKeySystem,
      enableCode :COUNTRY_DATA[currentKeySystem].enableCode
    }
  ): string {
    if(COUNTRY_DATA[config.country]?.locale){
      const formatedCurrency = formatCurrency(
        Number(value),
        COUNTRY_DATA[config.country].locale,
        getCurrencySymbol(COUNTRY_DATA[config.country].code, 'narrow'),
        COUNTRY_DATA[config.country],
        `${COUNTRY_DATA[config.country].digits}`,
      );
      return `${formatedCurrency} ${config.enableCode ? COUNTRY_DATA[config.country].currencyCode : ''}`;
    }else{
      return '';
    }
   
  }
}
