import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-media-player',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss']
})
export class MediaPlayerComponent {
  @Input() cvVideoPath: string | undefined;

  videoSrc: string = '';
  constructor(public sanitizer: DomSanitizer){

  }

  ngOnInit(){

    if(this.cvVideoPath && !this.cvVideoPath.includes('blob:')){
      this.setVideoSourceToObjectUrl(this.cvVideoPath);
    }else{
      const src:any =  this.sanitizer.bypassSecurityTrustUrl(this.cvVideoPath || '');
      console.log(src);
      
      this.videoSrc = src['changingThisBreaksApplicationSecurity'];
    }
  }

  setVideoSourceToObjectUrl = (url: string) => fetch(url)
  .then(response => response.blob()) //Encode the response as a blob
  .then(blob =>  {
      var blobUrl = URL.createObjectURL(blob)
      const src:any =  this.sanitizer.bypassSecurityTrustUrl(blobUrl);
      this.videoSrc = src['changingThisBreaksApplicationSecurity'];
  });

}
