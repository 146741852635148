import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OnboardingService } from '../onboarding/onboarding.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class CvGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router,
              private onboardingService: OnboardingService){

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentCandidate = JSON.parse(this.storageService.get('candidate') || 'null');
    
    if (currentCandidate){
      if (currentCandidate.steepsOnboarding < 2){
        // console.warn('El usuario no tiene sus pasitos');
        
        this.router.navigateByUrl(this.onboardingService.pathRedirection(currentCandidate))
        return false;
      }
      /*else if (!currentCandidate.user.phoneVerify){
        this.router.navigateByUrl('/onboarding/phone');
        return false;
      }*/
      else{
        return true
      }
    }    
    return false;
  }
  
}
