import { Directive, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appTogglePasswordType]'
})
export class TogglePasswordTypeDirective {
  isPasswordVisible = false;

  constructor(private el: ElementRef,
    private translateService: TranslateService ) { 
    const inputContainer: HTMLDivElement = this.el?.nativeElement.parentNode;
    const input: HTMLInputElement = this.el?.nativeElement as HTMLInputElement;
    const show = this.translateService?.get('togglePassword.show').toPromise();
    const hide = this.translateService?.get('togglePassword.hide').toPromise();
    Promise.all([show, hide]).then((texts: string[]) => {
      const togglePasswordElement = document.createElement('span');
      togglePasswordElement.classList.add('btn', 'btn-btn-link', 'p-0', 'toggle-password-button', 'd-flex', 'flex-row', 'justify-content-end');
      togglePasswordElement.innerHTML = 
        `<i class="eva eva-eye-outline show-password mr-1"></i><small class="show-password">${ texts[0] }</small>
        <i class="eva eva-eye-off-outline hide-password mr-1"></i><small class="hide-password">${ texts[1] }</small>`;

      togglePasswordElement.addEventListener('click', () => {
        this.isPasswordVisible = !this.isPasswordVisible;
        if (this.isPasswordVisible){
          input.setAttribute('type', 'text');
        }
        else{
          input.setAttribute('type', 'password');
        }
      });
      inputContainer.appendChild(togglePasswordElement);
    })
  }

}
