import { appSettings } from "../globals/appSettings";
import Rollbar from 'rollbar'
import packageInfo  from '../../../package.json'
import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';

const user = JSON.parse(localStorage.getItem('candidate') || '{}')?.user;
const rollbarConfig = {
  enabled: appSettings.integrationKeys.rollbar.enabled,
  accessToken: appSettings.integrationKeys.rollbar.key,
  payload: {
    code_version: packageInfo.version,
    environment: appSettings.integrationKeys.rollbar.env,
    user_id: user?.userId || 'unknown',
    email: user?.email || 'unknown',
    token: localStorage.getItem('token') || 'unknown'
  },
  captureUncaught: true,
  captureUnhandledRejections: true,
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
  }

  handleError(err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
    return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
