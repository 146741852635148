import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//importaciones para intenalizacion
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appSettings } from 'src/app/globals/appSettings';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http1:HttpClient) =>{
          return new TranslateHttpLoader(http1,`./assets/i18n/${ appSettings.currentCountry }-`,'.json')
        },
        deps: [HttpClient]
      }
    })
  ],
  exports:[TranslateModule]
})
export class InternalizacionModule {
  public activeLanguage:string = 'es'
  constructor(public translate: TranslateService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang(this.activeLanguage);
    //const browserLang = translate.getBrowserLang();
    translate.use(this.activeLanguage);
  }
}
