<div class="video-player-wrapper">
    <vg-player>
      <vg-overlay-play vgFor="my-video"></vg-overlay-play>
      <vg-controls>
        <vg-play-pause></vg-play-pause>
        <vg-playback-button></vg-playback-button>
        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
        <vg-buffering></vg-buffering>
        <vg-scrub-bar>
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>
        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
        <vg-mute></vg-mute>
        <vg-volume></vg-volume>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video #myMedia
      [vgMedia]="$any(myMedia)"  [vgMaster]="true" id="my-video">
      <source [src]="sanitizer.bypassSecurityTrustResourceUrl(cvVideoPath || '')" type="video/mp4">
      </video>
    </vg-player>
  </div>