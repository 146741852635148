import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EducationComponent } from './components/education/education.component';
import { ExperienceComponent } from './components/experience/experience.component';
import { VacancyDetailPageComponent } from './components/vacancy-detail-page/vacancy-detail-page.component';
import { ResetPasswordPageComponent } from './components/reset-password-page/reset-password-page.component';
import { PresentationPageComponent } from './componets/presentation-page/presentation-page.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyNoticeComponent } from './pages/privacy-notice/privacy-notice.component';
import { CookiesPolicyComponent } from './pages/cookies-policy/cookies-policy.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { UiLibraryComponent } from './pages/ui-library/ui-library.component';
import { ServicesMapComponent } from './pages/services-map/services-map.component';
import { EmailSentComponent } from './components/email-sent/email-sent.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TogglePasswordTypeDirective } from './directives/toggle-password-type/toggle-password-type.directive';
import { RouterModule } from '@angular/router';
import { LoderComponent } from './components/loder/loder.component';
import { HeaderComponent } from './components/header/header.component';
import { InternalizacionModule } from '../internalization/internalization.module';
import { CustomCurrencyPipe } from 'src/app/pipes/custom.currencypipe';
import { RecordComponent } from './components/record/record.component';
import { RecordService } from 'src/app/services/video-recording/record.service';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { MediaPlayerComponent } from '../dashboard-outlet/components/media-player/media-player.component';

@NgModule({
  declarations: [
    EducationComponent,
    ExperienceComponent,
    VacancyDetailPageComponent,
    ResetPasswordPageComponent,
    PresentationPageComponent,
    TermsAndConditionsComponent,
    PrivacyNoticeComponent,
    CookiesPolicyComponent,
    ChangelogComponent,
    UiLibraryComponent,
    ServicesMapComponent,
    EmailSentComponent,
    TogglePasswordTypeDirective,
    LoderComponent,
    HeaderComponent,
    CustomCurrencyPipe,
    RecordComponent,
    MediaPlayerComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    InternalizacionModule,
    VgCoreModule, 
    VgControlsModule, 
    VgOverlayPlayModule, 
    VgBufferingModule
  ],
  exports: [
    EducationComponent,
    ExperienceComponent,
    TermsAndConditionsComponent,
    PrivacyNoticeComponent,
    CookiesPolicyComponent,
    ChangelogComponent,
    UiLibraryComponent,
    EmailSentComponent,
    TogglePasswordTypeDirective,
    VacancyDetailPageComponent,
    LoderComponent,
    HeaderComponent,
    CustomCurrencyPipe,
    RecordComponent,
    MediaPlayerComponent
  ],
  providers:[
    RecordService,
    CustomCurrencyPipe
  ],
  bootstrap: [MediaPlayerComponent]
})
export class SharedModule { }
