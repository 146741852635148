import moment from 'moment';
import { appSettings } from 'src/app/globals/appSettings';

/**
 * Masks a string as `DD DDDD DDDD` OR `DDD DDD DDD` depending on the current key system
 * @param value A phone number
 * @returns A formatted phone number
 */
export function inputPhoneFormat(value: string): string{
  value = value.replace(/\D/g, '').trim();

  const formatted = value.match(appSettings.countrySettings.phoneFormaterPattern);
  return formatted ? `${formatted[1]}${formatted[2] ? ' ' + formatted[2] : ''}${formatted[3] ? ' ' + formatted[3] : ''}` : value;
}

export function formatClientEmployees(clientEmployees: string): string{
  const employeesEnum: any = {
    'DE1A10': 'de 1 a 10', 
    'DE11A50': 'de 11 a 50', 
    'DE51A250': 'de 51 a 250', 
    'MASDE250': 'más de 250',
  };
  return employeesEnum[clientEmployees] || '';
}

export function formatPublicationDate(publicationdate: string, browserLang: string): string{
  return moment(new Date(publicationdate)).locale(browserLang?.match(/en/)?'en':appSettings.countrySettings.momentLocale).startOf('hour').fromNow();
}

export function formatFunctions(functions: string): string[]{
  return functions.replace(/\* /g, '').split('\n');
}

export function formatAreas(vacancyAreas: any[]): string{
  return vacancyAreas
          .map((el: any) => el.area.name)
          .join(', ');
}

export function formatSpecialties(vacancySpecialties: any[]): string{
  return vacancySpecialties
          .map((el: any) => el.speciality.name)
          .join(', ');
}

export function formatLanguages(vacancyLanguages: any[]): string{
  return vacancyLanguages
          .map((el: any) => el.language.name)
          .join(', ');
}

export function formatAsFirstCapital(originalValue: string): string{
  if (originalValue && originalValue.trim() !== ''){
    return `${ originalValue[0].toUpperCase() }${ originalValue.substring(1).toLowerCase() }` 
  }
  else{
    return originalValue;
  }
}