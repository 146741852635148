import { Injectable } from '@angular/core';
import { appSettings } from 'src/app/globals/appSettings';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  detectDevice = (keyToken: string) => {
    if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
      document.location.href = `involve://${ appSettings.links.candidate }/candidate-count/${keyToken}`;
    }
  }

  detectDeviceRedirect = (url: string, keyToken:string) => {
    if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
      document.location.href = `involve://${ appSettings.links.candidate }/${url}/${keyToken}`;
    }
  }

  copyToClipboard(text: string): void{
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }
}
