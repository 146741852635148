import { Injectable } from '@angular/core';
import { ICandidate, IPermission, IPostulation } from 'src/app/models/candidate.models';
import { ToastService } from '../toast/toast.service';
import { StorageService } from '../storage/storage.service';
import { catchError, of, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {
  // TODO: Rename as 'redirectionService'

  constructor(private toastService: ToastService, 
              private storageService: StorageService,
              private translateService: TranslateService) { }

  pathRedirection(candidate: ICandidate): string{
      switch (candidate.steepsOnboarding){
        case 0:
          const hasPermision = candidate.user.permissions.find((permission: IPermission)=>{
            return permission.permissionType == 'JOB_VACANCIES'
          })
          if (candidate.user.password === null){
            return '/onboarding/set-password';
          } else if(!hasPermision?.createDate){
            return '/onboarding/notifications';
          }
          else{
            return '/onboarding/phone';
          }
        case 1:
          return '/onboarding/name';
        case 2:
          return '/dashboard';
        case 3:
          
        default:
          this.toastService.error('Parece que hay un error con el candidato', '');
          this.storageService.clear();
          return '';
      } 
  }

  getFilteredPermissions(postulation: IPostulation): any {
    const filteredPermissions = postulation.permissions.reduce((acc: any, permission: any) => {
      if (acc[permission.permissionType] && new Date(acc[permission.permissionType].createDate) < new Date(permission.createDate)) {
        acc[permission.permissionType] = permission;
      } else if (!acc[permission.permissionType]) {
        acc[permission.permissionType] = permission;
      }
      return acc;
    }, {});
    return Object.values(filteredPermissions);
  }

  async pathNewRedirectionPostulation(postulation: IPostulation, isTutorial: boolean = false): Promise<string> {
    let canContinue = postulation.vacant.status == 'ACTIVA' || postulation.vacant.status == 'PAUSADA';
    let cuestionaryComplete = postulation?.questionsAreaComplete && postulation?.questiosHardSkillComplete && postulation?.questionsSoftSkillComplete;
    if(!canContinue){
      this.toastService.error('Parece que esta vacante ya no está disponible.', '');
      return '/dashboard/vacancies/postulations';
    }
    else if (!cuestionaryComplete) {
      return `/dashboard/postulation/${ postulation.selectionProcessId }/questionary`;
    }else if(postulation?.currency == null && postulation?.periodicity == null){
      return `/dashboard/postulation/${ postulation.selectionProcessId }/salary`;
    }else if(postulation?.completeContractConditions == null && postulation?.vacant?.containContractConditions){
      return `/dashboard/postulation/${ postulation.selectionProcessId }/conditions`;
    }
    else {
          const userPetmissions = this.getFilteredPermissions(postulation);
          const hasVideoInterview = postulation.videoInterviewComplete;
          const psychometricComplete = postulation.psychometricTestComplete;
          const containPsychometricTest = postulation.vacant.containPsychometricTest;
        
          const checkPermission = async (
            permissions: any[],
            type: string,
            successCallback: (status: boolean, isTutorial: boolean) => void,
            failureCallback: () => string
        ) => {

            const filteredPermissions = userPetmissions.filter(
               (permission: any) => permission.permissionType === type
            );

            if (filteredPermissions.length === 0) {
              return failureCallback();
            } else if (filteredPermissions.length > 0 && filteredPermissions[0]?.status) {
               return await successCallback(filteredPermissions[0].status, isTutorial);
            } else if (filteredPermissions.length > 0 && !filteredPermissions[0]?.status) {
               return await successCallback(filteredPermissions[0]?.status, isTutorial);
            } 
        };
        
          const handlePsycometrics = (status: boolean, isTutorial: boolean = false) => {
            if (!hasVideoInterview && status) {
              if (isTutorial) {
                return `/dashboard/acceptance/videointerview/${ postulation.selectionProcessId }`;
              } else {
                return `/dashboard/postulation/${ postulation.selectionProcessId }/interview`;
              } 
            } else {
            return checkPermission(
              userPetmissions,
              'TESTING_SHARE',
              finallyCheck,
              () => {
                if (containPsychometricTest) {
                  return `/dashboard/permissions/psycometrics/${ postulation.selectionProcessId }`
                 }else{
                  this.toastService.success(this.translateService.instant('psychometric.completedProcess'), '');
                   return '/dashboard/vacancies/postulations';
                 }
              }
              );
            }
          };

          const finallyCheck = (status: boolean, isTutorial: boolean = false) =>{
            if(status && !psychometricComplete && containPsychometricTest){
              if (isTutorial) {
                return `/dashboard/acceptance/psycometrics/${ postulation.selectionProcessId }`;
              } else {
                return `/dashboard/postulation/${ postulation.selectionProcessId }/psychometric`
              } 
            }else{
              this.toastService.success(this.translateService.instant('psychometric.completedProcess'), '');
              return '/dashboard/vacancies/postulations';
            } 
          }
           
           const checkVideoPresentationRecorded = () => {
            return postulation?.candidate.pathPresentationVideo != null 
            && postulation?.candidate.pathPresentationVideo != '';
           };
           
           const handleVideoInterview = (status: boolean, isTutorial: boolean = false) => {
            const hasVideoPresentationRecorded = checkVideoPresentationRecorded();

            if(status && !hasVideoPresentationRecorded ){
              if (isTutorial) {
                return `/dashboard/acceptance/videopresentation/${ postulation.selectionProcessId }`;
              } else {
                return `/dashboard/postulation/${ postulation.selectionProcessId }/presentation`;
              } 
                
            } else {
               return checkPermission(
                 userPetmissions,
                 'VIDEOINTERVIEW',
                 handlePsycometrics,
                 () => `/dashboard/permissions/videointerview/${postulation.selectionProcessId}`
               );
            }
           };

          const handleVideoPresentation = (status: boolean) => {
            return checkPermission(
                 userPetmissions,
                 'VIDEOPRESENTATION_SHARE',
                 handleVideoInterview,
                 () => {
                    return `/dashboard/permissions/videopresentation/${ postulation.selectionProcessId }`
                  } 
            );
          };
          let redirectPath = await handleVideoPresentation(true);
          return redirectPath || '';
    }
 }

}
