import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { ICandidate } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
@Component({
  selector: 'app-account-status-handler-page',
  templateUrl: './account-status-handler-page.component.html',
  styleUrls: ['./account-status-handler-page.component.scss']
})
export class AccountStatusHandlerPageComponent {
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
    private apiService: ApiService,
    private router: Router,
    private toastService: ToastService,
    private onboardingService: OnboardingService,
    private translateService: TranslateService
  ) { 
    this.storageService.clear();
    const urlToken = this.activatedRoute.snapshot.paramMap.get('token');
    if (urlToken){
      this.storageService.set('token', urlToken.replace('Bearer+', ''));
      this.apiService.verifyEmailCandidate().subscribe(
        (response: any) => {
          this.apiService.getCandidateNoLogOut(true).subscribe({
            next: (getCandidateResponse: any) => {
              this.storageService.set('candidate', JSON.stringify(getCandidateResponse))
              this.handleSuccessResponse(getCandidateResponse.body);
            },
            error: (getCandidateError: HttpErrorResponse) => {
              if (getCandidateError.status === 403 || getCandidateError.status === 401){
                // TODO: Add this validation to interceptor
                // acoording to this: https://stackoverflow.com/a/74383750
                this.storageService.remove('token');
                this.refreshToken(urlToken)?.subscribe({
                  next: (refreshTokenResponse: HttpResponse<ICandidate>) => {
                    const refreshedToken = refreshTokenResponse.headers.get('token');
                    if (refreshedToken){
                      this.storageService.set('token', refreshedToken.replace('Bearer ', ''));
                      this.apiService.getCandidate(true).subscribe({
                        next: (getCandidateResponse: ICandidate) => {
                          this.storageService.set('candidate', JSON.stringify(getCandidateResponse))
                          this.handleSuccessResponse(getCandidateResponse);
                        },
                        error: (getCandidateError: HttpErrorResponse) => {
                          this.handleErrorResponse(getCandidateError.message)
                        }
                      });
                    }
                    else{
                      this.handleErrorResponse();
                    }
                  },
                  error: (refreshTokenError: HttpErrorResponse) => {
                    this.handleErrorResponse();
                  }
                });
              }
              else if (getCandidateError.status === 404){
                this.handleErrorResponse('No se encontró el usuario');
              }
              else{
                this.handleErrorResponse(getCandidateError.message);
              }
            }
          });
        },
        (error: HttpErrorResponse) => {
          this.handleErrorResponse(error.message);
        }
      )
      
    }
    else{
      this.handleErrorResponse('No se encontró el token');
    }
  }

  ngOnInit(): void {
    
  } 

  handleSuccessResponse(candidate: ICandidate | null): void{
    if (candidate){
        this.router.navigateByUrl( this.onboardingService.pathRedirection(candidate) );
    }
    else{
      this.handleErrorResponse();
    }
  }

  handleErrorResponse(errorMessage?: string): void{
    this.storageService.clear();
    this.router.navigateByUrl('/');
    this.toastService.error(this.translateService.instant('errors.generic.default'), errorMessage || this.translateService.instant('errors.generic.default'));
  }

  refreshToken(token: string): Observable<HttpResponse<ICandidate>> | null{
    try{
      const tokenPayload = JSON.parse( window.atob(token.split('.')[1]).toString());
      const params: any = {
        token: token,
        email: tokenPayload.emailUser
      };
      return this.apiService.refreshToken(params);
    }
    catch{
      return null
    }
  }
}
