<div class="h-100vh d-flex">
    <ng-container *ngIf="!emailSentInfo; else emailSentContent">
        <div class="h-100 w-100 w-md-50 overflow-auto d-flex flex-column align-items-center justify-content-center px-3 py-5 py-md-0 mx-auto">
            <div class="form-group-container">
                <img src="/assets/images/logo-involve.png" alt="Logo Involve RH" class="logo-involve mb-5 d-block mx-auto">
                <h2 class="mb-1 w-100">{{ 'forgotPassword.title' | translate }}</h2>
                <h5 class="mb-5 w-100">{{ 'forgotPassword.subTitle' | translate }}</h5>
                <form class="form-container w-100 flex-grow-1 d-flex flex-column" [formGroup]="forgotPasswordForm" >
                    <div class="form-group mb-3">
                        <label class="mb-1">{{ 'forgotPassword.email' | translate }}</label>
                        <input class="form-control" [placeholder]="'generic.userMail' | translate"
                            formControlName="email"
                            [ngClass]="{ 'is-invalid': hasFormControlError('email') }">
                            <small class="form-text color-E600" *ngIf="hasFormControlError('email', 'required')">{{ 'forgotPassword.inputError.required' | translate }}</small>
                            <small class="form-text color-E600" *ngIf="hasFormControlError('email', 'email')">{{ 'forgotPassword.inputError.email' | translate }}</small>
                    </div>
                    <button class="btn btn-primary btn-block mb-3" 
                            [ngClass]="{ 'disabled': !emailControl.valid }"
                            (click)="onRecoverPassword()">{{ 'forgotPassword.sendBtn' | translate }}</button>
                    <a class="btn btn-link btn-block mb-5" routerLink="/">{{ 'forgotPassword.backBtn' | translate }}</a>
                </form>
            </div>
        </div>
    </ng-container>
    <ng-template #emailSentContent>
        <app-email-sent [isMainActionButtonShown]="false">
            <ng-container id="detailedMessage">{{ emailSentInfo?.detailedMessage }}</ng-container>
        </app-email-sent>
    </ng-template>
</div>