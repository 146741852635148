import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/browser';


Sentry.init({
  dsn: "https://43b76f02f65f600cebe467a653b826c4@o4506344530837504.ingest.sentry.io/4506819977740288",
  tracesSampleRate: 1.0,
  environment: 'production-inter',
  // Performance Monitoring
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost","talent.involverh.com","talent.involverh.es"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
