import { Component } from '@angular/core';

@Component({
  selector: 'app-services-map',
  templateUrl: './services-map.component.html',
  styleUrls: ['./services-map.component.scss']
})
export class ServicesMapComponent {

}
