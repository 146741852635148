<div class="counter-container" *ngIf="remainingSecondToRecord > 0">
    <span>{{ remainingSecondToRecord }}</span>
</div> 
<ng-container  *ngIf="this.recordStatus == 'STOP' && downloadUrl != '';else preview">
    <app-media-player [cvVideoPath]="downloadUrl" class="w-100"></app-media-player>
</ng-container>
<ng-template #preview>
    <video class="video" [ngClass]="this.recordStatus == 'STOP' ? 'preview' : 'record'"  #video autoplay controls playsinline></video> 
</ng-template>
   
