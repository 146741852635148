import { HttpErrorResponse, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ICandidate, IPostulation } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { OnboardingService } from 'src/app/services/onboarding/onboarding.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { RecordService } from 'src/app/services/video-recording/record.service';
import * as Sentry from '@sentry/browser';
const QUESTIONARY_STEPS = ['welcome', 'selection', 'record', 'soft'];

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation-page.component.html',
  styleUrls: ['./presentation-page.component.scss']
})
export class PresentationPageComponent {
  currentQuestionaryStep = 'selection';
  questionsPresentation: any[] = [];
  currentDisplayQuestion!: string;
  isRecordedFinished = false;
  remainingSecondToRecord = 0;
  feedbackMessage!: string | null;
  currentDisplayQuestionNumber = 1;
  recordedtime!: string;
  recordedVideoUrl: any = undefined;
  currentPostulation!: IPostulation;
  recordedVideo!: Blob;
  currentCandidate!: ICandidate;

  mediaRecorder: any;
  recordedBlobs?: Blob[];
  isRecording: boolean = false;
  downloadUrl?: string;
  stream?: MediaStream;
  recordSubscription: any;
  mp4File?: File;
  recordStatus?: string;
  fileName: string = 'file';

  @ViewChild('recordErrorModal', { static: false }) private recordErrorElement:any;
  recordErrorStatus?:string;
  recordErrorText?:string;
  recordErrorSubscription: any;
  recordErrorModal:any
  constructor(
    private router: Router,
    private toastService: ToastService,
    private apiService: ApiService,
    private onboardingService: OnboardingService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    public recordService: RecordService,
    private storageService: StorageService,
    private modalService: NgbModal) {
      const navigationState = this.router.getCurrentNavigation()?.extras.state || history.state;
    if (navigationState && navigationState['postulation']) {
      this.currentPostulation = navigationState['postulation'];
    }
    else{
      if (!this.isRecordingFromMyCv()){
        const postulationId = this.router.url.replace('/dashboard/postulation/', '').replace('/presentation', '');
        if (postulationId){
          this.apiService.getPostulation(postulationId).subscribe({
            next: (getPostulationResponse: HttpResponse<IPostulation>) => {
              const postulation = getPostulationResponse.body;
              if (postulation !== null){
                this.currentPostulation = postulation;
              }
              else{
                this.router.navigateByUrl('/dashboard/vacancies/postulations');
                this.toastService.error(this.translateService.instant('errors.generic.getPostulation'), getPostulationResponse.statusText);
              }
            },
            error: (getPostulationError: HttpErrorResponse) => {
              this.router.navigateByUrl('/dashboard/vacancies/postulations');
              this.toastService.error(this.translateService.instant('errors.generic.getPostulation'), getPostulationError.message);
            }
          });
        }
        else{
          this.toastService.error(this.translateService.instant('errors.generic.default'), '');
          this.router.navigateByUrl('/dashboard/vacancies/postulations');
        }
      }
    }
    this.recordService.setTempVideo(true)
    this.recordSubscription = this.recordService.status.subscribe((value:string) => {
      this.recordStatus = value;
      this.isRecording = (value == 'RECORDING');
      this.isRecordedFinished = (value == 'STOP');
    })
    this.recordErrorSubscription = this.recordService.error.subscribe((value:string) => {
      this.openErrorModal(value)
    })
    this.recordService.getRecordedVideo().subscribe((value:File) => {
      this.mp4File = value;
    })
    this.recordService.getRecordedTime().subscribe((data) => {
      this.recordedtime = data;
      if (data === '01:01'){
        this.onDisplayNextQuestion();
      }
      else if (data.includes('02:')){
        if (this.router.url.includes('presentation')) {
          this.recordService.setStatus('STOP')
          this.recordService.stopRecordedTime()
        }
      }
    });
  }

  

  openErrorModal(value:string){
    if(!this.modalService.hasOpenModals()){
      switch (value) {
        case 'NOTALLOWED':
          this.recordErrorText = 'Verifica que los permisos del uso de tu cámara en la configuración de tu navegador  estén habilitados poder realizar tu video.';
          this.recordErrorStatus = value;
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        case 'NOTFOUND':
          this.recordErrorText = 'Tu dispositivo no tiene micrófono o cámara disponibles para la grabación. Verifica la conexión y funcionalidad de ambos dispositivos, o considera usar otro dispositivo compatible.';
          this.recordErrorStatus = value;
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        case 'NOTSUPPORTED':
          this.recordErrorText = 'Tu dispositivo no soporta la grabación, considera usar otro dispositivo compatible.';
          this.recordErrorStatus = value;
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        case 'USEDBYOTHER':
          this.recordErrorText = 'Otro programa está haciendo uso de la cámara de tu dispositivo, revisa tu configuración de la misma para poder realizar tu video.';
          this.recordErrorStatus = value;
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        case 'RECORDERROR':
          this.recordErrorText = 'Se produjo un error durante la grabación, inténtelo de nuevo más tarde.';
          this.recordErrorStatus = value;
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        case 'UPLOADERROR':
          this.recordErrorText = 'Error al grabar video. Verifica conexión, cámara y vuelve a intentar. Si persiste, contáctanos.';
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        case 'DEFAULT':
          this.recordErrorText = 'Se produjo un error durante la grabación, inténtelo de nuevo más tarde.';
          this.recordErrorStatus = value;
          this.recordErrorModal = this.modalService.open(this.recordErrorElement, { backdrop: 'static' });
          break;
        default:
          break;
      }
    }
  }

  async ngOnInit() {
    this.getCandidate();
    this.getQuestionsPresentation();
    this.onSetStep(1)
  }

  
  startRecording() {
   this.recordService.setStatus('START')
  }

  stopRecording() {
    this.recordService.stopRecordedTime()
    this.recordService.setStatus('STOP')
    if (this.isRecording){
      this.isRecording = false;
      this.isRecordedFinished = true;
    }
  }




  onSetStep(step: number, newAttemptRecording?: boolean): void{
    this.currentQuestionaryStep = QUESTIONARY_STEPS[step];
    if (step === 2){
      if (this.selectedQuestions.length === 2){
        this.currentDisplayQuestion = this.selectedQuestions[0].question;

        if (newAttemptRecording){
          this.recordService.setStatus('PREPARE')
        }
        this.feedbackMessage = '';
      }
      else{
        this.onSetStep(1);
      }
    }
  }

 

  onToggleSelection(question: any, event: Event): void{
    if (!question.isSelected && this.selectedQuestions.length < 2 ||
      question.isSelected){
        question.isSelected = !question.isSelected;
    }
    else{
      (event.target as HTMLInputElement).checked = false;
    }
  }

  onDisplayNextQuestion(): void{
    this.currentDisplayQuestion = this.selectedQuestions[1].question;
    this.currentDisplayQuestionNumber = 2;
  }

  onRestartRecording(): void{
    this.currentDisplayQuestion = this.selectedQuestions[0];
    this.currentDisplayQuestionNumber = 1;
    this.recordedVideoUrl = undefined;
    this.recordService.setStatus('RESTART')
    this.onSetStep(2, true);
  }

  onSubmitVideo(): void{
    this.loaderService.showLoader();
    const formData = new FormData();
    if(this.mp4File){
      const fileSizeInBytes = this.mp4File.size;
      const fileSizeInKB = fileSizeInBytes / 1024;
      if (fileSizeInKB > 500) {
        formData.append('file', this.mp4File);
        const body: any[] = this.selectedQuestions;
        this.apiService.savePresentationQuestions(body).subscribe({
          next: (savePresentationQuestionsResponse: HttpResponse<string>) => {
            this.apiService.savePresentationVideo(formData).subscribe({
              next: async (savePresentationVideoResponse: HttpResponse<any>) => {
                if (this.isRecordingFromMyCv()){
                  this.getCandidate(true);
                  this.toastService.success(this.translateService.instant('cv.presentation.videoUpdate'), '');
                  this.router.navigateByUrl('/dashboard/my-cv');
                }
                else{
                  this.currentPostulation.candidate.pathPresentationVideo = await this.recordService.getRecordedUrl() || ''
                  const candidate = JSON.parse(this.storageService.get('candidate') ?? '{}');
                  if(candidate){
                      candidate.pathPresentationVideo = await this.recordService.getRecordedUrl() || ''
                      this.storageService.set('candidate', JSON.stringify(candidate))
                  }
                  const targetPath = await this.onboardingService.pathNewRedirectionPostulation(this.currentPostulation, true);
                  this.router.navigateByUrl(targetPath, { replaceUrl: true, state: { postulation: this.currentPostulation } });
                }
              },
              error: (savePresentationVideoError: HttpErrorResponse) => {
                if(savePresentationVideoError.status == 412){
                  this.toastService.error('','El vídeo es muy corto y no se guardó, vuelve a intentarlo');
                }else{
                  this.toastService.error('',savePresentationVideoError.message);
                } 
              }
            });
    
          },
          error: (savePresentationQuestions: HttpErrorResponse) => {
            this.toastService.error('',savePresentationQuestions.message);
          },
        });
    }else {
      this.loaderService.hideLoader();
      Sentry.captureMessage('Error en grabación del video, Peso:' + fileSizeInKB);
      this.openErrorModal('UPLOADERROR')
    }
  }
   
  }

  get selectedQuestions(): any[]{
    return this.questionsPresentation.filter(question => question.isSelected);
  }

  isRecordingFromMyCv(): boolean{
    return this.router.url.indexOf('/dashboard/my-cv/presentation') === 0;
  }

  private getCandidate(refresh = false): void {
		this.apiService.getCandidate(refresh).subscribe({
      next: (getCandidateResponse: ICandidate) => {
        this.currentCandidate = getCandidateResponse;
        this.fileName = `presentation_${this.currentCandidate.candidateId}`;
      }
    });
	}

  private getQuestionsPresentation(): void{
    this.apiService.getPresentationQuestions().subscribe(
      (getQuestionPresentationResponse: any) => {
        this.questionsPresentation = getQuestionPresentationResponse.body.map((question: any) => {
          return {
            ... question,
            isSelected: false
          };
        });
      });
  }

  closeRecordModal(){
    this.recordErrorModal.close()
  }

  ngOnDestroy(){
    this.recordService?.setStatus('DESTROY')
  }
  
}
