<div class="h-100vh d-flex fade-in flex-column align-items-center">
    <div class="overflow-auto w-100 flex-grow-1">
        <div class="form-group-container d-flex flex-column p-3 pt-5 mx-auto">
            <div class="d-flex justify-content-between">
                <h2 class="mb-5">{{ 'cv.education.title' | translate }}</h2>
            </div>
            <form class="form-container w-100 d-flex flex-column" (submit)="save()" [formGroup]="educationForm">
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.education.academicLevel' | translate }}</label>
                    <select class="form-control" formControlName="academicLevel">
                        <option value="">{{ 'cv.generic.select' | translate }}</option>
                        <option [value]="option.catalogSystemId" *ngFor="let option of educationLevels">{{ option.name }}</option>
                    </select>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('academicLevel', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.education.optainedTitle' | translate }}</label>
                    <input class="form-control" [placeholder]="'cv.education.certificate' | translate" formControlName="degreeObtained">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('degreeObtained', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.education.institutionName' | translate }}</label>
                    <input class="form-control" [placeholder]="'cv.education.institution' | translate" formControlName="institutionName">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('institutionName', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.education.estatus' | translate }}</label>
                    <select class="form-control" formControlName="status">
                        <option value="">{{ 'cv.generic.select' | translate }}</option>
                        <ng-container *ngFor="let option of academicStatus">
                            <option [value]="option.catalogSystemId" >{{ option.name }}</option>
                        </ng-container>
                    </select>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('status', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.education.startYear' | translate }}</label>
                    <input type="tel" class="form-control" formControlName="startYear" type="text" maxlength="4" placeholder="aaaa" (change)="validateDate()">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('startYear', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('startYear', 'maxDateAllowed')">{{ 'cv.education.inputError.minDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('startYear', 'minDateAllowed')">{{ 'cv.education.inputError.minDateAllowed' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.education.endYear' | translate }}</label>
                    <input class="form-control" type="tel" formControlName="finishYear" type="text" maxlength="4" placeholder="aaaa" (change)="validateDate()">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('finishYear', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('finishYear', 'maxDateAllowed')">{{ 'cv.education.inputError.maxDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('finishYear', 'minDateAllowed')">{{ 'cv.education.inputError.minDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasDateError">{{ 'cv.education.inputError.dateError' | translate }}</small>
                </div>
                <div class="footer w-100 d-flex justify-content-between p-3">
                    <button type="button"  routerLink="/dashboard/my-cv" class="btn btn-min-width btn-outline-primary">{{ 'cv.generic.cancelBtn' | translate }}</button>
                    <button type="submit" class="btn btn-min-width btn-primary" [ngClass]="{ 'disabled': educationForm.invalid }">{{ 'cv.generic.saveBtn' | translate }}</button>
                </div>
            </form>
        </div>
        <div class="w-lg-25 w-md-50 mx-lg-auto mx-md-auto">
            <div id="includedContent"></div>
            <div *ngFor="let option of educations" class="d-flex flex-row experience-item justify-content-between w-100 py-3 px-3">
                <div class="d-flex flex-column">
                    <strong class="color-S500">
                        {{option.degree}}
                    </strong>
                    <span class="color-S200">
                        {{option.instituteName}}
                    </span>
                </div>
                <div class="d-flex flex-row">
                    <button *ngIf="option.catalogSystemId !== currentEducation?.catalogSystemId" class="btn btn-primary action-btn edit mb-1 mr-2" [routerLink]="['/dashboard/my-cv/education', option.educationId]">
                        <i class="eva eva-edit-2-outline"></i>
                    </button>
                    <button class="btn btn-primary action-btn delete mb-1 mr-2" (click)="deleteEducation(option.educationId)">
                        <i class="eva eva-trash-2-outline"></i>
                    </button>
                </div>
            </div> 
        </div>
    </div>
</div>