<div class="h-100vh d-flex fade-in flex-column align-items-center">
    <div class="overflow-auto w-100 flex-grow-1">
        <div class="form-group-container d-flex flex-column p-3 pt-5 mx-auto">
            <div class="d-flex justify-content-between">
                <h2 class="mb-5">{{ 'cv.experience.title' | translate }}</h2>
            </div>
            <form class="form-container w-100 d-flex flex-column" (submit)="saveExperience()" [formGroup]="experienceForm">
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.position' | translate }}</label>
                    <input class="form-control" placeholder="UX Leader" formControlName="position">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('position', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.positionType' | translate }}</label>
                    <select class="form-control" formControlName="positionType">
                        <option value="">{{ 'cv.generic.select' | translate }}</option>
                        <option [value]="option.positionTypeId" *ngFor="let option of positionTypes">{{ option.name }}</option>
                    </select>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('positionType', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.company' | translate }}</label>
                    <input class="form-control" [placeholder]="translateService.instant('cv.experience.company')" formControlName="company">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('company', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.startDate' | translate }}</label>
                    <input type="date" class="form-control" placeholder="dd/mm/aaaa" value="1990-01-01" formControlName="startDate" (change)="validateDate()">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('startDate', 'maxDateAllowed')">{{ 'cv.generic.inputError.maxDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('startDate', 'minDateAllowed')">{{ 'cv.generic.inputError.minDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('startDate', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.stillWorking' | translate }}</label>
                    <div class="form-check">
                        <div class="form-check form-check-inline">
                            <input type="radio" name="stillWorking" id="radio-options-1" formControlName="stillWorking" [value]=true (click)="setValidation('stillWorking','yes')">
                            <label for="radio-options-1" class="btn">{{ 'cv.experience.yesBtn' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="stillWorking" id="radio-options-2" formControlName="stillWorking" [value]=false (click)="setValidation('stillWorking','no')">
                            <label for="radio-options-2" class="btn">{{ 'cv.experience.noBtn' | translate }}</label>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-3" *ngIf="!stillWorkingControl.value">
                    <label class="mb-1">{{ 'cv.experience.endDate' | translate }}</label>
                    <input type="date" class="form-control" placeholder="dd/mm/aaaa" value="2000-01-01" formControlName="finishDate" (change)="validateDate()">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('finishDate', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('finishDate', 'maxDateAllowed')">{{ 'cv.generic.inputError.maxDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('finishDate', 'minDateAllowed')">{{ 'cv.generic.inputError.minDateAllowed' | translate }}</small>
                    <small class="form-text color-E600" *ngIf="hasDateError">{{ 'cv.experience.inputError.dateError' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.peopleCare' | translate }}</label>
                    <div class="form-check">
                        <div class="form-check form-check-inline">
                            <input type="radio" name="peopleInYourCare" id="radio-options-2-1" formControlName="peopleInYourCare" [value]=true (click)="setValidation('peopleInYourCare','yes')">
                            <label for="radio-options-2-1" class="btn">{{ 'cv.experience.yesBtn' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input type="radio" name="peopleInYourCare" id="radio-options-2-2" formControlName="peopleInYourCare" [value]=false (click)="setValidation('peopleInYourCare','no')">
                            <label for="radio-options-2-2" class="btn">{{ 'cv.experience.noBtn' | translate }}</label>
                        </div>
                    </div>
                </div>
                <div class="form-group mb-3" *ngIf="peopleInYourCareControl.value">
                    <label class="mb-1">{{ 'cv.experience.howMany' | translate }}</label>
                    <input class="form-control" type="tel" [placeholder]="'cv.experience.number' | translate" maxlength="6" formControlName="howManyPeople">
                    <small class="form-text color-E600" *ngIf="hasFormControlError('howManyPeople', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                </div>
                <div class="form-group mb-3">
                    <label class="mb-1">{{ 'cv.experience.functions' | translate }}</label>
                    <textarea class="form-control" [placeholder]="translateService.instant('cv.experience.tipFunctions')" formControlName="functions"></textarea>
                    <small class="form-text color-E600" *ngIf="hasFormControlError('functions', 'required')">{{ 'cv.generic.inputError.required' | translate }}</small>
                    <small class="form-text">{{ 'cv.experience.functionsTip' | translate }}</small>
                </div>
                <div class="footer w-100 d-flex justify-content-between p-3">
                    <button type="button" routerLink="/dashboard/my-cv" class="btn btn-min-width btn-outline-primary">{{ 'cv.generic.cancelBtn' | translate }}</button>
                    <button type="submit" class="btn btn-min-width btn-primary">{{ 'cv.generic.saveBtn' | translate }}</button>
                </div>
            </form>
        </div>
        <div class="w-lg-25 w-md-50 mx-lg-auto mx-md-auto">
            <div id="includedContent"></div>
            <div  *ngFor="let option of experiences" class="d-flex flex-row experience-item justify-content-between w-100 py-3 px-3">
                <div class="d-flex flex-column">
                    <strong class="color-S500">
                        {{option.company}}
                    </strong>
                    <span class="color-S200">
                        {{option.position.position}}
                    </span>
                </div>
                <div class="d-flex flex-row" >
                    <button *ngIf="option.workExperienceId !== currentExperience?.workExperienceId" class="btn btn-primary action-btn edit mb-1 mr-2" [routerLink]="['/dashboard/my-cv/work-experience', option.workExperienceId]">
                        <i class="eva eva-edit-2-outline"></i>
                    </button>
                    <button class="btn btn-primary action-btn delete mb-1 mr-2" (click)="deleteExperience(option.workExperienceId)">
                        <i class="eva eva-trash-2-outline"></i>
                    </button>
                </div>
            </div> 
        </div>
    </div>
</div>

