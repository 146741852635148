import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { ToastService } from 'src/app/services/toast/toast.service';

export interface IVideoConfig {
  time: number;
  video: any;
  audio: any;
  secondsToRecord: number;
}

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent {
  @Input() recordService?:any;
  @Input() fileName:String ='file';
  @Input() config:IVideoConfig = 
  {
    time:2,
    video: {
      facingMode: 'user',
      width: { min: 320, ideal: 640, max: 640 },
      height: { min: 240, ideal: 480, max: 480 },
      frameRate: { ideal: 15, max: 30 }
    },
    audio: {
      echoCancellation: true,
    },
    secondsToRecord: 3
  }
  
  @ViewChild('video') videoRecord!: ElementRef<HTMLVideoElement>;
  @ViewChild('preview') videoPreview?: ElementRef<HTMLVideoElement>;

  videoElement?: HTMLVideoElement;
  mediaRecorder: any;
  recordedBlobs?: Blob[] = [];
  isRecording: boolean = false;
  downloadUrl?: string = '';
  stream?: MediaStream | null;
  remainingSecondToRecord = 0;
  recordStatus: string = 'INIT';
  recordSubscription: any;
  supportedMimeTypes: string[] = []
  mp4File?: File;
  videoBuffer: any;
  mainType = "";
  constructor(private toastService: ToastService){

  }

  async ngOnInit() {
    this.recordSubscription = this.recordService?.status.subscribe((value:string) => {
      this.recordStatus = value
      switch (value) {
        case 'START':
          this.startRecording()
          break;
        
        case 'PREPARE':
          this.onPrepareRecording();
          break;
          
        case 'STOP':
          this.onStopRecording();
          break;

        case 'DESTROY':
          this.onDestroyRecording();
          break;
        
        default:
          break;
      }
    })
   

    this.onPrepareRecording();
  }

  startRecording() {
    this.remainingSecondToRecord = this.config.secondsToRecord;
    this.recordService?.setStatus('COUNTER')
    const timeInterval = setInterval(() => {
      this.remainingSecondToRecord--;
      if (this.remainingSecondToRecord === 0){
        clearInterval(timeInterval);
        if (window.MediaRecorder == undefined) {
          this.recordService?.setError('NOTSUPPORTED')
          Sentry.captureMessage('MEDIA RECORDER NOT SUPPORTED');
        } else {
          let userAgent = navigator.userAgent;

          if(userAgent.match(/chrome|chromium|crios/i)){
            this.mainType = "video/webm;codecs=vp8";
          }else if(userAgent.match(/firefox|fxios/i)){
            this.mainType = "video/webm;codecs=h264";
          }  
          const contentTypes = [
            this.mainType,
            'video/mp4;codecs=avc1',
            'video/mp4'
          ];
          contentTypes.forEach(contentType => {
            if(MediaRecorder.isTypeSupported(contentType)){
              this.supportedMimeTypes.push(contentType)
            }
          });
        }

        const options: any = { mimeType: this.supportedMimeTypes[0] };
        if(this.stream){
          try {
            const constraints = {
              width: { min: 320, ideal: 640, max: 640 },
              height: { min: 240, ideal: 480, max: 480 },
              frameRate: { ideal: 15, max: 30 }
            };
            if ( this.stream.active) {
              this.stream.getVideoTracks()[0].applyConstraints(constraints);
              this.mediaRecorder = new MediaRecorder(this.stream, options);
              this.mediaRecorder.start();
            } else {
              this.stream.getTracks().forEach(track => {
                track.enabled = true;
              });
              this.stream.getVideoTracks()[0].applyConstraints(constraints);
              this.mediaRecorder = new MediaRecorder(this.stream, options);
              this.mediaRecorder.start();
            }

            this.recordService?.setStatus('RECORDING')
            this.recordService.startRecordedTime()
            this.isRecording = true;
            this.onDataAvailableEvent();
            this.onErrorEvent();
            this.onStopRecordingEvent();
          } catch (error) {
            Sentry.captureMessage(this.handleUserMediaError(String(error)));
          }
        }

      }
    }, 1000);
  }

  onDataAvailableEvent() {
    try {
      this.mediaRecorder.ondataavailable  = (event:any) => {
        this.recordedBlobs = [];
        if (event.data && event.data.size > 0) {
          this.recordedBlobs.push(event.data);
        }else{
          Sentry.captureMessage(this.handleUserMediaError('NoDataAviable'));
          this.recordService?.setError('NODATAAVIABLE')
        }
      };
    } catch (error) {
      Sentry.captureMessage('Video error: '+ String(error));
      this.recordService?.setError('NODATAAVIABLE')
    }
  }

  onErrorEvent(){
    try {
      this.mediaRecorder.onerror = function (event: any) {
        Sentry.captureMessage('Error during recording:', event.error);
        this.recordService?.setError('RECORDERROR')
      };
    } catch (error: any) {
      Sentry.captureMessage('Error during recording:', error);
      this.recordService?.setError('RECORDERROR')
    }
  }

  onStopRecordingEvent() {
    try {
      this.mediaRecorder.onstop = () => {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        
        if(isSafari){
          this.videoBuffer = new Blob(this.recordedBlobs, {
            type: 'video/mp4'
          });
          this.mp4File = new File([this.videoBuffer], `${this.fileName}.mp4`, {
            type: 'video/mp4'
          });
        }else{
          this.videoBuffer = new Blob(this.recordedBlobs, {
            type: 'video/webm'
          });
          this.mp4File = new File([this.videoBuffer], `${this.fileName}.webm`, {
            type: 'video/webm'
          });
        }
          this.recordService?.setRecordedVideo(this.mp4File)
          const downloadUrl:any = URL.createObjectURL(this.videoBuffer);
          this.downloadUrl = downloadUrl;
          this.recordService?.setRecordedUrl(downloadUrl)
          this.setPreview(downloadUrl)
          

          if (this.stream) {
            this.stream.getAudioTracks().forEach((track: any) => track.stop());
            this.stream.getTracks().forEach((track:any) => {
              track.stop()
              track.enabled = false
            })
          }  

          if(this.videoElement){
            this.videoElement.srcObject = null;
            this.videoElement.src = downloadUrl;
            this.videoElement.muted = false;
            this.videoElement.volume = 1;
            this.videoElement.controls = true
            this.videoElement.pause();
          }

      };
    } catch (error) {
      Sentry.captureMessage(this.handleUserMediaError(String(error)));
    }
  }

  setPreview(downloadUrl: string){
    if (downloadUrl && this.videoRecord ) {
      this.videoRecord.nativeElement.srcObject = null;
      this.videoRecord.nativeElement.src = downloadUrl;
      this.videoRecord.nativeElement.muted = false;
      this.videoRecord.nativeElement.volume = 1;
      this.videoRecord.nativeElement.controls = true
      this.videoRecord.nativeElement.pause();
    }
  }

  onStopRecording(): void{
    if (this.recordStatus == 'STOP' &&  this.mediaRecorder){
      console.log( this.mediaRecorder);
      
      this.mediaRecorder.stop();
    }
  }

  onRestartRecording(): void{
    this.onPrepareRecording()
  }

  onDestroyRecording(){
    if (this.stream) {
      this.stream.getAudioTracks().forEach((track: any) => track.stop());
      this.stream.getTracks().forEach((track:any) => {
        track.stop()
        track.enabled = false
      })
      this.stream = null;
    }  
    if (this.mediaRecorder) {
      this.mediaRecorder = null;
    }
  }

  handleUserMediaError(error: any) {
    switch (error.name) {
      case 'NotAllowedError':
        this.recordService?.setError('NOTALLOWED')
          return 'User denied camera/microphone access';
      case 'NotFoundError':
        this.recordService?.setError('NOTFOUND')
        return 'No camera/microphone devices found';

      case 'NotReadableError':
        this.recordService?.setError('USEDBYOTHER')
        return 'Another program is using the camera';
        
      case 'NotSupportedError':
        this.recordService?.setError('NOTSUPPORTED')
        return 'Requested media type not supported';

      case 'NoDataAviable':
        this.recordService?.setError('NOTSUPPORTED')
        return 'Requested media type not supported';

      default:
        this.recordService?.setError('DEFAULT')
        return 'Error requesting camera/microphone permission:' + error;
    }
  }

  async onPrepareRecording() {
    const {video, audio} = this.config;
    try {
      const stream = await navigator.mediaDevices
      .getUserMedia({
        video,
        audio
      })
      this.stream = stream;
      if(this.videoRecord){
        this.videoRecord.nativeElement.srcObject = this.stream;
        this.videoRecord.nativeElement.muted = true;
        this.videoRecord.nativeElement.volume = 0;
        this.videoRecord.nativeElement.controls = false;
      }
    } catch (error) {
      Sentry.captureMessage(this.handleUserMediaError(error));
    }
  }

}


