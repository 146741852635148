import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  isShownMenuContainer = false;

  constructor(private router: Router) { }

  get isAtSearchView(): boolean{
    return this.router.url.indexOf('/dashboard/vacancies/search') === 0;
  }

  get isAtFAvoritesView(): boolean{
    return this.router.url.indexOf('/dashboard/vacancies/favorites') === 0;
  }
  
  get isAtPostulationsView(): boolean{
    return this.router.url.indexOf('/dashboard/vacancies/postulations') === 0;
  }

  get isAtProcessView(): boolean{
    return this.router.url.indexOf('/dashboard/postulation/') == 0 || 
    this.router.url.indexOf('/dashboard/permissions') == 0 ||
    this.router.url.indexOf('/dashboard/acceptance') == 0;
  }
  
  get isDisplayingActivePostulations(): boolean{
    return this.router.url.indexOf('/dashboard/vacancies/postulations/active') === 0;
  }
  
  get isAtVacanciesView(): boolean{
    return this.router.url.indexOf('/dashboard/vacancies/') === 0;
  }

  get isAtCvView(): boolean{
    return this.router.url.indexOf('/dashboard/my-cv') === 0;
  }


  onToggleMenuView(): void{
    this.isShownMenuContainer = !this.isShownMenuContainer;
  }

  onNavigateAndCloseMenu(path: string): void{
    this.router.navigateByUrl(`/dashboard/${ path }`);
    this.isShownMenuContainer = false;
  }

  onLogout(): void{
    localStorage.clear();
    this.isShownMenuContainer = false;
    this.router.navigateByUrl('/');
  }
}
