<div class="shadow index-1100 border-bottom main-header-container">
    <div class="py-2 text-center row mx-0 d-none d-md-flex align-items-center">
        <div class="col-md-2 col-lg-3 col-xl-2 text-left">
            <div class="tab logo-container px-4 d-flex flex-row">
                <img src="/assets/svg/involve_logo.svg" alt="">
                <div *ngIf="headerService.isAtCvView" class="tab profile-container px-4 d-flex flex-column justify-content-center" >
                    <b class="page-title">Mi perfil</b>
                </div>
            </div>
        </div>
        <div class="col-md-8 col-lg-6 col-xl-8 d-flex flex-row justify-content-between tabs-container mouse-pointer" >
            <div  *ngIf="headerService.isAtProcessView" (click)="redirectToPostulations()">
                <img id="sobre" src="/assets/svg/back_btn.svg" class="mr-2" alt="" >
                <strong>Postulación</strong>
            </div>
            <div class="tab active" *ngIf="headerService.isAtVacanciesView">
                <a routerLink="/dashboard/vacancies/search" routerLinkActive="active" class="btn btn-link btn-no-decoration d-flex align-items-center">
                    <img class="header-icon" [src]="'/assets/svg/seach-icon.svg'" alt=""> 
                    <span class="header-text">{{ 'header.search' | translate }}</span>
                </a>
            </div>
            <div>
                
            </div>
            <div class="tab" *ngIf="headerService.isAtVacanciesView">
                <a routerLink="/dashboard/vacancies/favorites" routerLinkActive="active" class="btn btn-link btn-no-decoration d-flex align-items-center">
                    <img class="header-icon" [src]="'/assets/svg/favorites-icon.svg'" alt=""> 
                    <span class="header-text">{{ 'header.favorites' | translate }}</span>
                </a>
            </div>
            <div class="tab" *ngIf="headerService.isAtVacanciesView">
                <a routerLink="/dashboard/vacancies/postulations" routerLinkActive="active" class="btn btn-link btn-no-decoration d-flex align-items-center">
                    <img class="header-icon" [src]="'/assets/svg/check-icon.svg'" alt="">
                    <span class="header-text">{{ 'header.postulation' | translate }}</span>
                </a>
            </div>
        </div>
        <div class="col-md-2 col-lg-3 col-xl-2 text-right">
            <a href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                <img class="menu-icon"  src="/assets/svg/hamburguesa-menu.svg" alt="">
            </a>
            <div class="dropdown-menu">
                <button *ngIf="currentPath != '/dashboard/my-cv'" href="#" class="btn btn-link dropdown-item" routerLink="/dashboard/my-cv">{{ 'header.myCv' | translate }}</button>
                <button *ngIf="currentPath != '/dashboard/my-profile'" href="#" class="btn btn-link dropdown-item" routerLink="/dashboard/my-profile">{{ 'header.myInfo' | translate }}</button>
                <button *ngIf="currentPath != '/dashboard/settings'" href="#" class="btn btn-link dropdown-item" [routerLink]="['/dashboard/settings']" >{{ 'header.settings' | translate }}</button>
                <button href="#" class="btn btn-link dropdown-item mb-3" (click)="headerService.onLogout()">{{ 'header.signOff' | translate }}</button>
                <small class="d-block text-center">v {{ appVersion }}</small>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end d-md-none text-right py-1 background-S500">
        <button class="btn btn-link btn-no-decoration align-items-center color-B300 d-none">
            <i class="eva eva-message-circle-outline eva-2x mr-2"></i>
            {{ 'header.chatbot' | translate }}
        </button>
        <button class="btn btn-link btn-no-decoration d-flex align-items-center color-B300"
            (click)="headerService.onToggleMenuView()" >
            <i class="eva eva-menu-outline eva-2x" *ngIf="isCandidateAuthenticated()"></i>
        </button>
    </div>
</div>
<div class="header-helper-div"></div> 
<div class="menu-container w-100 h-100vh background-B300 index-1200 flex-column" [ngClass]="{ 'd-none': !headerService.isShownMenuContainer, 'd-flex': headerService.isShownMenuContainer }">
    <div class="d-flex justify-content-between d-md-none text-right py-1">
        <div class="d-flex align-items-center">
            <img class="logo-involve-extra-small mx-3" src="/assets/images/logo-involve.png" alt="">
            <small>v {{ appVersion }}</small>
        </div>
        <button (click)="headerService.onToggleMenuView()" class="btn btn-link btn-no-decoration d-flex align-items-center color-S500">
            <i class="eva eva-close-outline eva-2x"></i>
        </button>
    </div>
    <div class="d-flex flex-grow-1 align-items-center justify-content-center flex-column">
        <a class="btn btn-link mb-2" (click)="headerService.onNavigateAndCloseMenu('vacancies/search')" [ngClass]="{ 'active': headerService.isAtSearchView }">
            {{ 'header.vacancies' | translate }}
        </a>
        <a class="btn btn-link mb-2 " (click)="headerService.onNavigateAndCloseMenu('vacancies/favorites')" [ngClass]="{ 'active': headerService.isAtFAvoritesView }">
            {{ 'header.favorites' | translate }}
        </a>
        <a class="btn btn-link mb-2 " (click)="headerService.onNavigateAndCloseMenu('vacancies/postulations')" [ngClass]="{ 'active': headerService.isAtPostulationsView }">
            {{ 'header.postulation' | translate }}
        </a>
        <a *ngIf="currentPath != '/dashboard/my-cv'" class="btn btn-link mb-2 " (click)="headerService.onNavigateAndCloseMenu('my-cv')">
            {{ 'header.myCv' | translate }}
        </a>
        <a *ngIf="currentPath != '/dashboard/my-profile'" class="btn btn-link mb-2 " (click)="headerService.onNavigateAndCloseMenu('my-profile')">
            {{ 'header.myInfo' | translate }}
        </a>
        <a *ngIf="currentPath != '/dashboard/settings'" class="btn btn-link mb-2 " (click)="headerService.onNavigateAndCloseMenu('settings')">
            {{ 'header.settings' | translate }}
        </a>
    </div>
    <div class=" d-flex flex-grow-1 justify-content-center py-3">
        <button class="btn btn-link mb-3" (click)="headerService.onLogout()">{{ 'header.signOff' | translate }}</button>
    </div>
</div>
