<div class="h-100vh d-flex flex-column">
    <app-header *ngIf="showHeader"></app-header>
    <ng-container *ngIf="currentVacancy?.status === 'ACTIVA'; else noVacancyDetailContainer">
        <div class="vacancy-detail flex-grow-1">
            <div class="container">
                <div class="row mt-3 mx-1 mx-md-0">
                    <div class="sticky-md-top col-12 row mx-0 mb-2">
                        <div class="col-12 col-md-6 mb-2">
                            <h3 class="vacancy-name">
                                {{ currentVacancy?.name }}
                            </h3>
                            <h4 class="salary-text">
                                <ng-container *ngIf="!currentVacancy?.salaryShow; else salaryContainer">
                                    {{ 'vacancyDetail.salaryNotShown' | translate }}
                                </ng-container>
                                <ng-template #salaryContainer>
                                    <ng-container *ngIf="isSalaryExactly; else salaryRangeContainer">
                                        {{ transformSalary(currentVacancy?.salaryExactly ,currentVacancy?.currency )}} {{ formatAsFirstCapital(currentVacancy?.typeSalary.name) }} {{ currentPeriodicitySalary }} 
                                    </ng-container>
                                    <ng-template #salaryRangeContainer>
                                        {{  transformSalary(currentVacancy?.salaryMinimum ,currentVacancy?.currency ) }} - {{  transformSalary(currentVacancy?.salaryMaximum ,currentVacancy?.currency ) }} {{ currentPeriodicitySalary}} {{ formatAsFirstCapital(currentVacancy?.typeSalary.name) }}
                                    </ng-template>
                                </ng-template>
                            </h4>
                            <p class="client-info">
                                <ng-container *ngIf="currentVacancy?.confidential; else clientContainer">
                                    {{ 'vacancyDetail.confidentialCompany' | translate }} - 
                                </ng-container>
                                <ng-template #clientContainer>
                                    {{ currentVacancy?.client.name }} - 
                                </ng-template>
                                {{ currentVacancy?.city.name }}, {{ currentVacancy?.city.state.name }}
                            </p>
                        </div>
                        <div class="d-none d-md-flex col-md-6 pr-md-0 align-items-center justify-content-end">
                            <div class="d-flex flex-column">
                                <div class="pb-3 d-flex flex-row justify-content-end" >
                                    <img *ngIf="this.showHeader" [src]="!isFavorite?'/../assets/svg/favorite-icon.svg':'./../assets/svg/favorite-full-icon.svg'"  (click)="onToggleFavorite(currentVacancy)" class="mr-3" alt="" srcset="">
                                    <img src="./../assets/svg/share-icon.svg" (click)="onShareVacancyLink(currentVacancy)" alt="" srcset="">
                                </div>
                                <div>
                                    <button class="btn btn-primary px-5 py-1 btn-postulation" *ngIf="!selectionProcessId && !displayingFromChatWindow" (click)="onPostulate()"  #postulationButton>{{ 'vacancyDetail.apply' | translate }}</button>
                                    <a class="btn btn-primary btn-block btn-postulation" *ngIf="selectionProcessId" [routerLink]="['/dashboard/postulation', selectionProcessId]">{{ 'postulations.continueProcess' | translate }}</a>
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12 d-flex mb-3 align-items-center border-bottom  border-top border-container">
                        <div class="w-50 d-flex flex-row client-container">
                            <img class="default-company-logo border border-radius mr-4" [src]="(currentVacancy?.client?.pathLogo? currentVacancy?.client?.pathLogo:'/assets/svg/company-icon.svg')" alt="">
                            <!-- TODO: Actualizar por logo del cliente -->
                            <div class="d-flex flex-column flex-grow-1 p-2 company-info">
                                <h4 class="mb-0">
                                    <ng-container *ngIf="currentVacancy?.confidential; else clientContainer">
                                        {{ 'vacancyDetail.confidentialCompany' | translate }}
                                    </ng-container>
                                    <ng-template #clientContainer>
                                        {{ currentVacancy?.client.name }}
                                    </ng-template>
                                </h4>
                                <p class="mb-0"> {{ 'vacancyDetail.company' | translate }} {{ currentVacancy?.client.typeCompany?.name }}, {{ formatClientEmployees(currentVacancy?.client.employees) }} {{ 'vacancyDetail.employees' | translate }}</p>
                                <p class="mb-0">{{ 'vacancyDetail.sector' | translate }}: {{ currentVacancy?.client.industry?.sector?.name }}</p>
                            </div>  
                        </div>
                        <div class="w-50 d-flex flex-row justify-content-end date-container">
                            <p class="mb-3 d-flex align-items-center">
                                <small class="published-date">{{ 'vacancyDetail.published' | translate }}: <b> {{ moment(currentVacancy?.publicationDate).format('DD | MMMM | YYYY') }}</b> </small>
                            </p>
                        </div>
                    </div>
                    <div class=" mb-md-3">
                        <div class="mb-3">
                            <strong>{{ 'vacancyDetail.description' | translate }}:</strong>
                            <p>
                                {{ currentVacancy?.confidential ? 'Confidencial' : currentVacancy?.client.name }}
                                {{ 'vacancyDetail.positionlike' | translate }} 
                                {{ currentVacancy?.position.position }} 
                                {{ 'vacancyDetail.for' | translate }} 
                                {{ currentVacancy?.mission }}
                            </p>
                        </div>
                        <div class="mb-3">
                            <strong>{{ 'vacancyDetail.funciones' | translate }}:</strong>
                            <ul>
                                <li *ngFor="let function of formatFuncions(currentVacancy?.functions || '')">{{ function }}</li>
                            </ul>
                        </div>
                        <div class="mb-3">
                            <strong>{{ 'vacancyDetail.skills' | translate }}:</strong>
                            <ul>
                                <li *ngFor="let skill of currentVacancy?.hardSkillVacant">{{ skill.skill.name }}</li>
                                <li *ngFor="let skill of currentVacancy?.softSkillVacant">{{ skill.skill.name }}</li>
                            </ul>
                        </div>
                        <div class="mb-3">
                            <strong>{{ 'vacancyDetail.requirements' | translate }}</strong>
                            <p class="mb-0">
                                {{ 'vacancyDetail.educationalLevel' | translate }}: {{ currentVacancy?.educationLevel?.name }}, {{ currentVacancy?.academicTitle }}
                            </p> 
                            <p class="mb-0">
                                {{ 'vacancyDetail.academicStatus' | translate }}: {{ currentVacancy?.educationStatus?.name }}
                            </p> 
                            <p class="mb-0">
                                {{ 'vacancyDetail.experience' | translate }} {{ (currentVacancy?.areasVacant || []).length > 1 ? 'las áreas' : 'el área' }}: {{ formatAreas(currentVacancy?.areasVacant || []) }}
                            </p> 
                            <p class="mb-0">
                                {{ 'vacancyDetail.specialty' | translate }}: {{ formatSpecialties(currentVacancy?.specialityVacant || []) }}
                            </p>
                            <p class="mb-0" *ngIf="(currentVacancy?.languageVacant || []).length > 0">
                                {{ 'vacancyDetail.Languages' | translate }}: {{ formatLanguages(currentVacancy?.languageVacant || []) }}
                            </p>
                        </div>
                        <div class="d-flex flex-column flex-md-row">
                            <div class="mb-3 w-md-50">
                                <strong>{{ 'vacancyDetail.weOffer' | translate }}:</strong>
                                <p class="mb-0">
                                    <strong>{{ 'vacancyDetail.monthlySalary' | translate }}:</strong>
                                    <span>
                                        <ng-container *ngIf="!currentVacancy?.salaryShow; else salaryContainer">
                                            {{ 'vacancyDetail.salaryNotShown' | translate }}
                                        </ng-container>
                                        <ng-template #salaryContainer>
                                            <ng-container *ngIf="isSalaryExactly; else salaryRangeContainer">
                                                {{ transformSalary(currentVacancy?.salaryExactly ,currentVacancy?.currency) }} {{ currentVacancy?.periodicitySalary }} {{ formatAsFirstCapital(currentVacancy?.typeSalary.name) }}
                                            </ng-container>
                                            <ng-template #salaryRangeContainer>
                                                {{ transformSalary(currentVacancy?.salaryMinimum ,currentVacancy?.currency ) }} - {{ transformSalary(currentVacancy?.salaryMaximum ,currentVacancy?.currency ) }} {{ currentPeriodicitySalary | translate }} {{ formatAsFirstCapital(currentVacancy?.typeSalary.name) }}
                                            </ng-template>
                                        </ng-template>
                                    </span>
                                </p>
                                <p class="mb-0">
                                    <strong>{{ 'vacancyDetail.workday' | translate }}: </strong>
                                    <span>{{ formatAsFirstCapital(currentVacancy?.workingDay?.name || '') }}</span>
                                </p>
                                <p class="mb-0">
                                    <strong>{{ 'vacancyDetail.modality' | translate }}: </strong>
                                    <span>{{ formatAsFirstCapital(currentVacancy?.modality?.name || '') }}</span>
                                </p>
                                <p class="mb-0">
                                    <strong>{{ 'vacancyDetail.schedule' | translate }}: </strong>
                                    <span>{{ currentVacancy?.schedule }}</span>
                                </p>
                                <p class="mb-0">
                                    <strong>{{ 'vacancyDetail.contractType' | translate }}: </strong>
                                    <span>{{ currentVacancy?.contract.name }}</span>
                                </p>
                            </div>
                            <div class="mb-3 w-md-50">
                                <strong>{{ 'vacancyDetail.benefits' | translate }}:</strong>
                                <ul>
                                    <li *ngFor="let benefit of currentVacancy?.benefitsInvolve">{{ benefit.benefit?.description || benefit.benefitOther?.description }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 py-3 background-B800 postulation-button-container" *ngIf="!displayingFromChatWindow">
                        <button class="btn btn-primary btn-block" *ngIf="!selectionProcessId" (click)="onPostulate()">{{ 'vacancyDetail.apply' | translate }}</button>
                        <a class="btn btn-primary btn-block" *ngIf="selectionProcessId" [routerLink]="['/dashboard/postulation', selectionProcessId]">{{ 'postulations.continueProcess' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #noVacancyDetailContainer>
        <div class="d-flex align-items-center justify-content-center flex-grow-1">
            <div class="w-100 w-md-75">
                <h3 class="text-center mb-2">{{ 'vacancyDetail.inactive' | translate }} 
                    <ng-container *ngIf="showHeader">{{ 'vacancyDetail.discouraged' | translate }} </ng-container>
                </h3>
                <a routerLink="/dashboard/vacancies/search">
                    <h3 class="text-center" *ngIf="showHeader">{{ 'vacancyDetail.moreHere' | translate }}</h3>
                </a>
            </div>
        </div>
    </ng-template>
</div>