import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { LoaderService } from '../loader/loader.service';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {
  requestCounter = 0;
  isForceToLogOut = false;
  constructor(
    private storageService: StorageService, 
    private loaderService:LoaderService, 
    private router:Router, 
    private toastService:ToastService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const sessiontoken = this.storageService.get('token');
    if (sessiontoken){
      return this.handleRequest(request.clone({
        setHeaders: {
          Authorization: `Bearer ${ sessiontoken.replace('Bearer ', '') }`
        }
      }), next);
    }
    else{
      return this.handleRequest(request, next);
    }
  }

  private handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    // Código final
    this.isForceToLogOut = false;
    this.requestCounter++;
    this.loaderService.showLoader();
    return next.handle(request).pipe(
      tap((response) => {
        if (response instanceof HttpResponse){
          this.requestCounter--;
          if (this.requestCounter === 0){
            this.loaderService.hideLoader();
          }
        }
      })
    ).pipe(
      catchError((error:HttpErrorResponse) => {
        /** TODO: Solicitar a back cambiar codigo de error */

        if ([401,403].includes(error.status) ) {
          if (!error?.url?.includes('/deleteUser/delete-candidate') && 
          !error?.url?.includes('/login') &&  !this.router.url.includes('/activate-account')){
            this.storageService.clear()
            this.requestCounter = 0;
            this.loaderService.hideLoader();
            this.router.navigateByUrl('/')
            if (!this.isForceToLogOut) {
              this.isForceToLogOut = true;
              this.toastService.error('','Porfavor vuelve a iniciar sesión')
            }
            return of(new HttpResponse({}));
          }
          else{
            this.requestCounter--;
            if (this.requestCounter === 0){
              this.loaderService.hideLoader();
            }
            return throwError(error);
          }
        }else{
          this.requestCounter--;
          if (this.requestCounter === 0){
            this.loaderService.hideLoader();
          }
          return throwError(error);
        }
      })
     );

    
  }
}
