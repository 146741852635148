export enum MicroServices {
    NOTIFICATION = 'notification',
    VACANCY = 'vacancy',
    MANAGEMENT = 'management',
    USER = 'user',
    AUTH = 'auth',
    PSYCHOMETRIC = 'psychometric',
    EXTRACCIONCV = 'extraccionCv',
    UPLOAD = 'upload',
    FILES = 'files',
    SMART_SEARCH = 'smartSearch',
    PARSER = 'parser'
}