import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';
import { appSettings } from 'src/app/globals/appSettings';

/**
 * The value is required and can not be a set of spaces
 * @returns Form control error or null
 */
export function required(control: AbstractControl): ValidationErrors | null{
  if ( control.value !== null && control.value !== undefined && control.value.toString().trim() !== ''){
    return null;
  }
  else{
    return {
      required: true
    };
  }
}

/**
 * The value must be a valid email. Does not allow emojis
 * @returns Form control error or null
 */
export function email(control: AbstractControl): ValidationErrors | null{
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  // * Based on https://regexr.com/3e48o

  if (control.value && emailRegex.test(control.value.toString().trim())){
    return null;
  }
  else{
    return {
      email: true
    };
  }
}

/**
 * The value must have a non-empty object
 * @returns Form control error or null
 */
export function object(control: AbstractControl): ValidationErrors | null{
  if (control.value && typeof control.value === 'object' && Object.keys(control.value).length > 0 ){
    return null;
  }
  else{
    return {
      emptyObject: true
    };
  }
}

/**
 * The value must have a currency format
 * i.e. '$0.00', '$123.00', '$123,456,780.00'
 * @returns Form control error or null
 */
export function currency(control: AbstractControl): ValidationErrors | null {
  const validCharacters = /^([^\d\s]{1}\s?[+-]?)(\d{1,3})(\,\d{3})*(\.\d{2})?$/gm;
  if (control.value && validCharacters.test(control.value)){
    return null;
  } else{
    return {
      currency: true
    };
  }
}

/**
 * Evaluates if a control value has a minimum length
 * @param minLength required length
 * @returns A validator function to evaluate the condition
 */
export function minLengthRequired(minLength: number): ValidatorFn{
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.toString().trim().length < minLength){
      return {
        minLength: true,
        lengthRequired: minLength,
        currentLength: control.value.toString().trim().length
      };
    }
    else{
      return null;
    }
  };
}


export function salaryRange(minSalaryControlName: string, maxSalaryControlName: string): ValidatorFn{  
  return (form: AbstractControl): ValidationErrors | null => {    
    const minSalaryControl = (form as FormGroup).get(minSalaryControlName);
    const maxSalaryControl = (form as FormGroup).get(maxSalaryControlName);
    
    if ((Number(minSalaryControl?.value) <= Number(maxSalaryControl?.value)) || 
        (minSalaryControl?.value === '' || minSalaryControl?.value === null || minSalaryControl?.value === 'null') || 
        (maxSalaryControl?.value === '' || maxSalaryControl?.value === null || maxSalaryControl?.value === 'null') ){
      return null;
    }
    else{
      return {
        salaryRange: true
      }
    }
  }
}


/**
 * Evaluates if a control value has a maximum length
 * @param maxLength required length
 * @returns A validator function to evaluate the condition
 */
export function maxLengthRequired(maxLength: number): ValidatorFn{
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.toString().trim().length > maxLength){
      return {
        maxLength: true,
        lengthRequired: maxLength,
        currentLength: control.value.toString().trim().length
      };
    }
    else{
      return null;
    }
  };
}

/**
 * Evaluates if a control value is all uppercased. It can also evaluate if there's, at leats, one uppercase character
 * @param atLeastOneUppercaseCharacter `false` if it's required a full-uppercase value
 * @returns Form control error or null
 */
export function uppercase(atLeastOneUppercaseCharacter: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (atLeastOneUppercaseCharacter){
      const uppercaseRegex = /[A-Z]/;

      if (control.value && uppercaseRegex.test(control.value.toString().trim())){
        return null;
      }
      else{
        return {
          uppercase: true
        };
      }
    }
    else{
      const onlyUppercaseRegex = /[^A-Z]/;

      if (control.value && onlyUppercaseRegex.test(control.value.toString().trim())){
        return {
          onlyUppercase: true
        };
      }
      else{
        return null;
      }
    }

  };

}

/**
 * Evaluates if a control value is all lowercased. It can also evaluate if there's, at leats, one lowercase character
 * @param atLeastOneLowercaseCharacter `false` if it's required a full-uppercase value
 * @returns Form control error or null
 */
export function lowercase(atLeastOneLowercaseCharacter: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (atLeastOneLowercaseCharacter){
      const lowercaseRegex = /[a-z]/;

      if (control.value && lowercaseRegex.test(control.value.toString().trim())){
        return null;
      }
      else{
        return {
          lowercase: true
        };
      }
    }
    else{
      const onlyLowercaseRegex = /[^a-z]/;

      if (control.value && onlyLowercaseRegex.test(control.value.toString().trim())){
        return {
          onlyLowercase: true
        };
      }
      else{
        return null;
      }
    }
  };
}

/**
 * Evaluates if a control value is a number. It can also evaluate if there's, at leats, one number in the value
 * @param atLeastOneNumber `false` if it's required a full-uppercase value
 * @returns Form control error or null
 */
export function number(atLeastOneNumber: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (atLeastOneNumber){
      const numberRegex = /[0-9]/;

      if (control.value && numberRegex.test(control.value.toString().trim())){
        return null;
      }
      else{
        return {
          number: true
        };
      }
    }
    else{
      const onlyNumberRegex = /[^0-9]/;

      if (control.value && onlyNumberRegex.test(control.value.toString().trim())){
        return {
          onlyNumber: true
        };
      }
      else{
        return null;
      }
    }
  };
}

/**
 * Looks for a list of error on a control
 * @param formControlName control name
 * @param errorName specific error name
 * @param validatesIsTheFirstError If `false`, does not validate that `errorName` is the only error in the list
 * @returns true|false if specific error was found. ValidationErrors list if not error name was passed
 */
export function hasFormControlError(formGroup: FormGroup, formControlName: string, errorName?: string, validatesIsTheFirstError?: boolean ): boolean | ValidationErrors {
  if (errorName !== undefined){
    return  (formGroup.get(formControlName)?.touched &&
            formGroup.get(formControlName)?.hasError(errorName) &&
            ( validatesIsTheFirstError === false ? // If not true, there could be other errors in the form control and still return true
              true :
              Object.keys(formGroup.get(formControlName)?.errors || {}).length > 0 &&
              Object.keys(formGroup.get(formControlName)?.errors || {})[0] === errorName
            ))
            || false;
  }
  else{
    return formGroup.get(formControlName)?.touched && formGroup.get(formControlName)?.errors || false;
  }
}

export function hasFormDynamicControlError(formControl: any, errorName?: string, validatesIsTheFirstError?: boolean ): boolean | ValidationErrors {
  if (errorName !== undefined){
    return  formControl?.touched &&
            formControl?.hasError(errorName) &&
            ( validatesIsTheFirstError === false ? // If not true, there could be other errors in the form control and still return true
              true :
              Object.keys(formControl?.errors || {}).length > 0 &&
              Object.keys(formControl?.errors || {})[0] === errorName
            )
            || false;
  }
  else{
    return formControl?.touched && formControl?.errors || false;
  }
}

/**
 * The value must have a format like `DD DDDD DDDD`
 * @param control Form control to be validated
 * @returns Form control error or null
 */
export function phoneFormat(control: AbstractControl): ValidationErrors | null{
  const phoneFormatRegex = appSettings.countrySettings.phoneValidatorPattern;
  
  if (control.value && phoneFormatRegex.test(control.value)){
    return null;
  }
  else{
    return {
      phoneFormat: true
    };
  }
}

/**
 * Validates if two controls in a form group have the same value. 
 * @param controlName1 The name in the formGroup for the first password control
 * @param controlName2 The name in the formGroup for the second password control
 * @returns 
 */
export function samePasswords(controlName1: string, controlName2 :string): ValidatorFn{
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (formGroup.get(controlName1)?.value === formGroup.get(controlName2)?.value){
      return null
    }
    else{
      return { 
        differentPassword: true 
      };
    }
  };
}

/**
 * Evaluates if a date control value is earlier to maxDate
 * @param maxDate A date, formatted as 'YYYY-MM-DD'. `today` if it is not passed
 * @returns A validator function to evaluate the condition
 */
export function maxDateAllowed(maxDate?: string): ValidatorFn{
  return (control: AbstractControl): ValidationErrors | null => {
    if (maxDate === undefined){
      const today = new Date().setHours(23, 59, 59);
      maxDate = moment(today).format('YYYY-MM-DD');
    }

    if (control.value && moment(control.value) > moment(maxDate)){
      return {
          maxDateAllowed: true,
          maxDate,
          selectedDate: control.value
      };
    }
    else{
      return null;
    }
  };
}

/**
 * Evaluates if a date control value is later to minDate
 * @param minDate A date, formatted as 'YYYY-MM-DD'. `today` if it is not passed
 * @returns A validator function to evaluate the condition
 */
export function minDateAllowed(minDate?: string): ValidatorFn{
  return (control: AbstractControl): ValidationErrors | null => {
    if (minDate === undefined){
      const today = new Date().setHours(0, 0, 0);
      minDate = moment(today).format('YYYY-MM-DD');
    }

    if (control.value && moment(control.value) < moment(minDate)){
      return {
          minDateAllowed: true,
          minDate,
          selectedDate: control.value
      };
    }
    else{
      return null;
    }
  };
}

/**
 * 
 */
export const onlyLetters = '^[a-zA-Z À-ÿ\u00f1\u00d1]+$';
// TODO: Documentar qué hace

/**
 * Used for checking if a form value is changed, in order to display a message "There are changes unsaved"
 * @param originalObject reference object
 * @param comparingObject validation object
 * @returns true if the original object is modified
 */
export function isObjectChanged(originalObject: any, comparingObject: any): boolean{
  return JSON.stringify(originalObject) !== JSON.stringify(comparingObject);
}


  const patterns = {
    'conditions' : /[^a-zA-Z0-9 ÁÉÍÓÚÜÑáéíóúüñ\(\)\-\.\+'\n']/g,
    'position' : /[^a-zA-Z0-9 ÁÉÍÓÚÜÑáéíóúüñ\(\)\-\.\+']/g,
    'textOnly' : /[^a-zA-Z ÁÉÍÓÚÜÑáéíóúüñ\(\)\-\.\+']/g,
    'numbersOnly' : /[^0-9']/g,
    'default' : /[^a-zA-Z ÁÉÍÓÚÜÑáéíóúüñ']/g
  }
/**
 * Masks a string as a valid text
 * @param value A input value
 * @returns returns a valid text
 */
export function inputTextFormat(value: string | any, type: string): string{
    const text = String(value)
    switch (type) {
      case 'text':
        return text.replace(patterns.position, '')
              .replace(/( ){2,}/g, ' ') // Remove double spaces
              .replace(/'{2,}/g, '\'') // Remove double apostrophes
              .replace(/( ' )/g, ' ') // Remove apostrophes between spaces
              .replace(/\.{2,}/g, '.') // Remove double periods
              .replace(/\+{2}/g, '+') // Remove double plus
                  .replace(/\-{2}/g, '-'); // Remove double minus
      case 'conditions':
        return text.replace(patterns.conditions, '')
              .replace(/( ){2,}/g, ' ') // Remove double spaces
              .replace(/'{2,}/g, '\'') // Remove double apostrophes
              .replace(/( ' )/g, ' ') // Remove apostrophes between spaces
              .replace(/\.{2,}/g, '.') // Remove double periods
              .replace(/\+{2}/g, '+') // Remove double plus
                  .replace(/\-{2}/g, '-'); // Remove double minus
        case 'textOnly':
          return text.replace(patterns.textOnly, '')
                .replace(/( ){2,}/g, ' ') // Remove double spaces
                .replace(/( ' )/g, ' ') // Remove apostrophes between spaces
                .replace('(', '')
                .replace(')', '')
        case 'numbersOnly':
          return text.replace(patterns.numbersOnly, '');
        case 'hours':
          const hours = text.replace(patterns.numbersOnly, '')
          if (hours.length > 0) {
            return String(parseInt(text,10));
          }else{
            return hours
          }
        default:
          return text.replace(patterns.default, '')
              .replace(/( ){2,}/g, ' ') // Remove double spaces
              .replace(/'{2,}/g, '\'') // Remove double apostrophes
              .replace(/( ' )/g, ' '); // Remove apostrophes between spaces
    }

}

export function url(control: AbstractControl): ValidationErrors | null{
  const reg = /^((http|https|ftp|www):\/\/)?([a-zA-Z0-9\~\!\@\#\$\%\^\&\*\(\)_\-\=\+\\\/\?\.\:\;\'\,]*)(\.)([a-zA-Z0-9\~\!\@\#\$\%\^\&\*\(\)_\-\=\+\\\/\?\.\:\;\'\,]+)/g;  
  if ((control.value && (reg.test(control.value.toString().trim())) || control.value.trim() === '')){
    return null;
  }
  else{
    return {
      url: true
    };
  }
}

 
