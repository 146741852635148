import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ICandidate } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';
import { HeaderService } from 'src/app/services/header/header.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import packageInfo  from './../../../../../../package.json';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  currentCandidate!: ICandidate;
  currentPath = '';
  isPostulationPath = false;
  appVersion = packageInfo.version;

  constructor(
    public headerService: HeaderService,
    private apiService: ApiService,
    private router: Router,
    private storageService: StorageService
    ){ 
      this.apiService.getCandidate().subscribe({
        next: (getCandidateResponse) => {
          this.currentCandidate = getCandidateResponse;
        }
      })

      this.storageService.profilePictureUpdated$.subscribe({
        next: (profilePicture: string) => {
          this.currentCandidate.user.photo = profilePicture;
        }
      });

      this.router.events.subscribe(value => {
        if(value instanceof NavigationEnd)    
        this.currentPath = this.router.url.toString();
        this.isPostulationPath = this.currentPath.includes('postulation/')
      });
    }

  isCandidateAuthenticated(): boolean{
    return this.storageService.isCandidateAuthenticated();
  }

  redirectToPostulations(){
    this.router.navigateByUrl('/dashboard/vacancies/postulations/active');
  }

}
