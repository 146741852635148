import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api/api.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-change-email-verify',
  templateUrl: './change-email-verify.component.html',
  styleUrls: ['./change-email-verify.component.scss']
})
export class ChangeEmailVerifyComponent {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public toastService: ToastService,
    private apiService: ApiService,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParamMap.has('token')){
      this.validateAccountBytoken(this.activatedRoute.snapshot.queryParamMap.get('token') || '',this.activatedRoute.snapshot.queryParamMap.get('email') || '');
    }
  }

  validateAccountBytoken(token: string, email: string): any{
    const tokenPayload = token;
    this.storageService.set('token', tokenPayload)
    const params = {
      newEmail: email
    }

    this.apiService.verifyEmail(params).subscribe(
      (validateAccountRespose: HttpResponse<any>) => {
        this.toastService.success('', 'Se actualizó correctamente tu email. Vuelve a iniciar sesión');
        this.storageService.clear()
        this.router.navigateByUrl('/');
      },
      (validateAccountError: HttpErrorResponse) => {
        this.toastService.success('', 'Error al actualizar email');
      }
    );
  }

}
