<div class="terms-and-conditions" id="TermsAndConditionsModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" [ngClass]="{ 'modal fade': displaysAsModal}">
    <div class="modal-dialog modal-dialog-centered modal-height-auto" role="document" [ngClass]="{ 'modal-dialog modal-dialog-centered': displaysAsModal }">
        <div class="modal-content">
            <div class="modal-header" *ngIf="displaysAsModal">
                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="currentCountry === 'MEX'; else legalsESPContainer" >
                    <h2 class="text-center mb-3">TÉRMINOS Y CONDICIONES</h2>
                    <h3>TÉRMINOS Y CONDICIONES DE USO DEL SERVICIO</h3>
                    <p class="upper">INTRODUCCIÓN</p>
                
                    <p>Bienvenidos a <b>INVOLVE RH</b>, antes de que decidas consultar y tomar los servicios que ofrece <b>INVOLVE RH</b>, te recomendamos que leas cuidadosamente los presentes Términos y Condiciones a los que estarás sujeto, ya que una vez que los admitas estarás aceptando un acuerdo obligatorio bajo el cual usted acepta utilizar <b>INVOLVE RH</b> disponible a través de cualquier dispositivo.</p>
                
                    <p class="upper"><b>DESCRIPCIÓN DE LOS SERVICIOS</b>
                    <br>
                
                    La presente página está diseñada para individuos, empleadores o reclutadores que buscan candidatos para ofrecerles empleo y/o individuos que buscan empleo, Usted debe usar este Sitio Web con intenciones legítimas. Los servicios que presta <b>INVOLVE RH</b> a través del Sitio Web son exclusivamente para promocionar opciones con la finalidad de que individuos puedan encontrar empleo, así como para que empleadores ajenos a <b>INVOLVE RH</b> puedan visualizar y encontrar candidatos que cumplan con el perfil que está buscando su empresa.
                    <br><br>
                
                    Al decidir usar y navegar por <b>INVOLVE RH</b>, estas aceptando los presentes términos y condiciones (en adelante únicamente "Términos y Condiciones") aplicables al uso de los contenidos, productos y servicios ofrecidos a través del sitio <a href="www.involverh.site" target="_blank">www.involverh.site</a> (en adelante, "Sitio Web"), del cual es titular <b>INVOLVE, S.A. DE C.V.</b> (en adelante, "Titular") quien tiene su domicilio establecido en la Ciudad de México, en la siguiente dirección:
                    <br><br>
                
                    Calle Río Lerma 196 BIS, Colonia Cuauhtémoc, Ciudad de México, C.P. 06500.</p>
                
                    <p class="upper"><b>DESCRIPCIÓN DE LOS SERVICIOS</b>
                    <br>
                
                    El Sitio Web están dirigido a empleadores, e individuos que desen buscar empleo, por lo que Usted deberá usar este Sitio Web con intenciones legítimas y de acuerdo con las indicaciones de uso.
                    <br><br>
                
                    Para los efectos de los presentes Términos y Condiciones, las partes acuerdan que por "Usuario" se entenderá a cualquier persona de cualquier naturaleza que ingrese al Sitio Web <a href="www.involverh.site" target="_blank">www.involverh.site</a> y/o a cualquiera de las subpáginas que desplieguen su contenido y/o a la persona de cualquier naturaleza que se dé de alta y/o use cualquiera de los servicios que se ofrecen a través de dicha página.
                    <br><br>
                
                    En caso de no aceptar en forma absoluta y completa los Términos y Condiciones de este contrato, el Usuario deberá abstenerse de acceder, utilizar y observar el Sitio Web y/o cualquier otro servicio que ofrezca <b>INVOLVE RH.</b>
                    <br><br>
                
                    En caso de que el Usuario acceda, utilice y observe el Sitio Web, se considerará como una absoluta y expresa aceptación de los Términos y Condiciones de Uso aquí estipulados, los demás documentos incorporados a los mismos por referencia, así como a las leyes y reglamento aplicables de conformidad a la legislación vigente para el uso del Sitio Web.
                    <br><br>
                
                    <b>INVOLVE RH</b> no guardará una copia individualizada del contrato celebrado entre el Usuario y la Empresa, por lo que se le recomienda al Usuario que guarde una copia de los presentes Términos y Condiciones de Uso para su propio expediente.
                    <br><br>
                
                    El Sitio Web se encuentra dirigido exclusivamente a personas que cuenten con la mayoría de edad (mayores de 18 años); en este sentido, INVOLVE, S.A. DE C.V. declina cualquier responsabilidad por el incumplimiento de este requisito.</p>
                
                    <p class="upper"><b>DESCRIPCIÓN DE LOS SERVICIOS</b><br>
                    <b>PARA QUIENES SE REGISTRARÓN PARA CUBRIR SUS VACANTES:</b>
                    <br>
                
                    Los servicios que presta <b>INVOLVE RH</b> a través del Sitio Web es hacer tu proceso de reclutamiento una tarea mucho más sencilla, a través de nuestra propia plataforma con Inteligencia Artificial, que mantiene un aprendizaje constante sobre cada perfil, entregando un set de candidatos con su reporte, que incluye CV, videopresentación, videoentrevista y psicometrías, además del porcentaje de compatibilidad entre el candidato y tu vacante.
                    <br><br>
                
                    Resulta importante informarle que <b>INVOLVE RH</b>, exceptúa cualquier responsabilidad por los daños y perjuicios de toda naturaleza que puedan deberse a los servicios prestados por terceros a través del Sitio Web, aunque no de modo exclusivo por los daños y perjuicios que puedan deberse al incumplimiento de la ley, la moral y las buenas costumbres generalmente aceptadas o de orden público como consecuencia de la prestación de servicios por terceros a través del Sitio Web; la infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, de compromisos contractuales de cualquier clase, de los derechos al honor, a la intimidad personal y familiar, y a la imagen de las personas, de los derechos de propiedad y de toda otra naturaleza pertenecientes a un tercero como consecuencia de la prestación de servicios por terceros a través del Sitio web; la realización de actos de competencia desleal y publicidad ilícita como consecuencia de la prestación de servicios por terceros a través del Sitio web, la falta de veracidad, exactitud, exhaustividad, pertinencia y/o actualidad de los contenidos transmitidos, difundidos, almacenados, recibidos, obtenidos, puestos a disposición o accesibles mediante los servicios prestados por terceros a través del sitio web; el incumplimiento, retraso, cumplimiento defectuosos o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros en relación o con motivo de la prestación de servicios a través del Sitio web; El Usuario no debe asignar o transferir sus obligaciones bajo estos términos, estas condiciones constituyen todo el contrato entre el Usuario e y <b>INVOLVE, S.A. DE C.V.</b> con respecto al uso del Sitio Web, INVOLVE RH se reserva el derecho a denegar o retirar el acceso al Sitio Web, en cualquier momento y sin necesidad de preaviso, a aquellos usuarios que incumplan estos Términos y Condiciones.
                    <br><br>
                
                    El acceso a parte de los contenidos y servicios del Sitio Web podrá realizarse previa suscripción o registro previo del Usuario.</p>
                
                    <p class="upper"><b>TRANSFERENCIA DE DATOS PERSONALES</b><br>
                    De conformidad con el artículo 36 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares vigente en México en adelante (LFPDPPP) <b>INVOLVE RH</b> se convierte en responsable receptor, por lo que, en cumplimiento con las disposiciones de la materia, el reclutador al contratar el servicio se obliga a:</p>
                
                    <ul>
                        <li>Mantener la confidencialidad de los datos personales, utilizándolos únicamente para efectos de reclutamiento, selección de personal y sin ningún otro propósito.</li>
                        <li>No transferir a ningún tercero, datos personales que recibe en su calidad de responsable receptor, salvo por lo dispuesto en el artículo 70 de Reglamento de LFPDPPP.
                        </li>
                        <li>Garantizar que solo sus empleados tendrán acceso a los datos transferidos y que ninguno de ellos dará un tratamiento distinto al de reclutamiento y selección de personal.
                        </li>
                    </ul>
                
                    <p>Estas obligaciones subsistirán de manera indefinida, aún después de finalizar la relación, INVOLVE RH se deslinda de cualquier responsabilidad presente o futura, que surja del uso no convenido de los datos personales que le han sido transferidos a los reclutadores.
                    <br><br>
                
                    La recopilación y el uso que hacemos de la información personal en relación con los servicios, es conforme a lo dispuesto en nuestro Aviso de Privacidad para Reclutador disponible en <a href="https://reclutalent.involverh.com/terms-and-conditions/" target="_blank">https://reclutalent.involverh.com/terms-and-conditions/</a> y Aviso de Privacidad Candidato para Usuarios, disponible en <a href="https://talent.involverh.com/terms-and-conditions/" target="_blank">https://talent.involverh.com/terms-and-conditions/</a></p>
                
                    <p class="upper"><b>OPINIONES</b><br>
                    Los comentarios y opiniones expresadas en los apartados disponibles son formulados por personas ajenas a <b>INVOLVE RH</b>, bajo su única y exclusiva responsabilidad. Todas las personas que accedan a este Sitio Web asumen la calidad de usuarios, y por ende se comprometen a la observancia y cumplimiento de estas disposiciones. Los participantes de dichos espacios se comprometen a utilizar los mismos de conformidad con la ley, estas condiciones generales, así como la moral y buenas costumbres generalmente aceptadas.
                    <br><br>
                
                    <b>INVOLVE RH</b> se exime de cualquier tipo de responsabilidad derivada de la información, opiniones, comentarios, ideas u otros contenidos realizados por los visitantes en su sitio web. Tampoco se permitirán comentarios difamatorios, injuriosos, calumniosos, obscenos, amenazadores, discriminatorios, o bien que inciten a la violencia.</p>
                
                    <p class="upper"><b>DERECHOS DE PROPIEDAD INTELECTUAL</b><br>
                    El Sitio Web y todos sus derechos, título e intereses son propiedad única de <b>INVOLVE, S.A. DE C.V.</b>, y se encuentran protegidos por las leyes mexicanas de derechos de autor y de los tratados internacionales. A excepción de las licencias de uso limitado expresamente otorgadas a usted en estos términos, <b>INVOLVE RH</b> se reserva para el mismo y sus licenciatarios todos los derechos, títulos e intereses, sin límite de propiedad en lo aquí mencionado, usted no puede reproducir, modificar, mostrar, vender o distribuir el contenido, o usarlo en cualquier otra forma para uso público o comercial.
                    <br>
                
                    El logotipo, diseños, formas, marcas nominativas, innominadas y mixtas de <b>INVOLVE, S.A. DE C.V.</b>, así como cualquier material estático o interactivo incluido en la Página y la App, está debidamente registrado ante las autoridades competentes y son propiedad de <b>INVOLVE, S.A. DE C.V.</b> Los derechos de propiedad intelectual correspondientes a los productos y servicios, así como los materiales y signos distintivos, dominios de la página, los derechos de uso y explotación correspondientes, mismos que incluyen mas no se limitan a su publicación, reproducción, divulgación, transformación y distribución son propiedad exclusiva de <b>INVOLVE, S.A. DE C.V.</b> El usuario no adquirirá en ninguna circunstancia concesión o derecho alguno sobre la propiedad intelectual e industrial de <b>INVOLVE, S.A. DE C.V.</b> por el mero uso de su página y App de los Servicios ofrecidos en las mismas; por lo tanto, su uso no podrá ser considerado como una autorización tácita o expresa para utilizar alguno de los elementos de la propiedad intelectual o de derecho de autor de <b>INVOLVE, S.A. DE C.V.</b> con algún fin diverso a los contemplados por el presente documento.</p>
                
                    <p class="upper"><b>DEL ACCESO Y NAVEGACIÓN EN EL SITIO WEB.</b><br>
                    <b>INVOLVE RH</b> se reserva la facultad de modificar en cualquier momento y sin previo aviso, la presentación, los contenidos, la funcionalidad, los productos, los servicios, y la configuración que pudiera estar contenida en el Sitio Web; en este sentido, el Usuario reconoce y acepta que INVOLVE RH en cualquier momento podrá interrumpir, desactivar o cancelar cualquiera de los elementos que conforman el Sitio Web o el acceso a los mismos.
                    <br><br>
                
                    El Sitio Web está dirigido principalmente a Usuarios residentes en la República Mexicana, por lo cual, <b>INVOLVE RH</b> no asegura que el Sitio Web cumpla total o parcialmente con la legislación de otros países, de forma que, si el Usuario reside o tiene su domicilio establecido en otro país y decide acceder o utilizar el Sitio Web lo hará bajo su propia responsabilidad y deberá asegurarse de que tal acceso y navegación cumple con la legislación local que le es aplicable, no asumiendo INVOLVE RH ninguna responsabilidad que se pueda derivar de dicho acto.
                    <br><br>
                
                    Se hace del conocimiento al Usuario que el responsable podrá administrar o gestionar el Sitio Web de manera directa o a través de un tercero, lo cual no modifica en ningún sentido lo establecido en los presentes Términos y Condiciones.
                    <br><br>
                
                    El acceso o utilización del Sitio Web, así como de los recursos habilitados para interactuar entre los usuarios, o entre el usuario y el titular tales como medios para realizar publicaciones o comentarios, confiere la condición de usuario del Sitio Web, por lo que quedará sujeto a los presentes términos y condiciones, así como a sus ulteriores modificaciones, sin perjuicio de la aplicación de la legislación aplicable, por tanto, se tendrán por aceptados desde el momento en el que se accede al Sitio Web. Dada la relevancia de lo anterior, se recomienda al usuario revisar las actualizaciones que se realicen a los presentes términos y condiciones.
                    <br><br>
                
                    Es responsabilidad del Usuario utilizar el Sitio Web de acuerdo con la forma en la que fue diseñado; en este sentido, queda prohibida la utilización de cualquier tipo de software que automatice la interacción o descarga de los contenidos o servicios proporcionados a través del Sitio Web. Además, el Usuario se compromete a utilizar la información, contenidos o servicios ofrecidos a través del Sitio Web de manera lícita, sin contravenir lo dispuesto en los presentes Términos y Condiciones, la moral y/o el orden público, y se abstendrá de realizar cualquier acto que pueda suponer una afectación a los derechos de terceros, o perjudique de algún modo el funcionamiento del Sitio Web.
                    <br><br>
                
                    Así mismo, el usuario se compromete a proporcionar información lícita y veraz en los formularios habilitados en el Sitio Web, en los cuales el usuario tenga que proporcionar ciertos datos o información para el acceso a algún contenido, producto o servicio ofrecido por el propio Sitio Web. En todo caso, el Usuario notificará de forma inmediata al Titular acerca de cualquier hecho que permita suponer el uso indebido de la información registrada en dichos formularios, tales como, robo, extravío, o acceso no autorizado a cuentas y/o contraseñas, con el fin de proceder a su inmediata cancelación.
                    <br><br>
                
                    <b>INVOLVE RH</b> se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren la ley, el respeto a la dignidad de la persona, que sean discriminatorios, atente contra los derechos de tercero o el orden público, o bien, que a su juicio no resulten adecuados para su publicación.
                    <br><br>
                
                    En cualquier caso, <b>INVOLVE RH</b> no será responsable de las opiniones vertidas por los Usuarios a través de comentarios o publicaciones que estos realicen.
                    <br><br>
                
                    El sólo acceso al Sitio Web no supone el establecimiento de ningún tipo de relación entre <b>INVOLVE RH</b> y el Usuario.
                    <br><br>
                
                    Al tratarse de un Sitio Web dirigido exclusivamente a personas que cuenten con la mayoría de edad, el Usuario manifiesta ser mayor de edad y disponer de la capacidad jurídica necesaria para sujetarse a los presentes Términos y Condiciones.
                    <br><br>
                
                    De igual forma <b>INVOLVE RH</b> no será responsable ni garantiza que el contenido al que pueda accederse a través del Sitio Web se encuentre libre de errores, software malicioso, o que pueda causar algún daño a nivel de software o hardware en el equipo a través del cual el Usuario accede al Sitio Web.
                    <br><br>
                
                    El Titular tampoco se hace responsable de los daños que pudiesen ocasionarse por un uso inadecuado del Sitio Web. En ningún caso INVOLVE RH será responsable por las pérdidas, daños o perjuicios de cualquier tipo que surjan por el sólo acceso o utilización del Sitio Web.</p>
                
                    <p class="upper"><b>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS.</b><br>
                    De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, el Usuario se compromete a adoptar las medidas necesarias que estén a su alcance para asegurar la privacidad de los datos personales recabados de forma que se garantice su seguridad, se evite su alteración, pérdida o tratamiento no autorizado.
                    <br><br>
                
                    Además, a efecto de dar cumplimiento a lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, todo dato personal que sea recabado a través del Sitio Web será tratado de conformidad con los principios de licitud, calidad, finalidad, lealtad, y responsabilidad. Todo tratamiento de datos personales quedará sujeto al consentimiento de su titular. En todo caso, la utilización de datos financieros o patrimoniales, requerirán de autorización expresa de sus titulares, no obstante, esta podrá darse a través del propio Sitio Web utilizando los mecanismos habilitados para tal efecto, y en todo caso se dará la mayor diligencia y cuidado a este tipo de datos. Lo mismo ocurrirá en el caso de datos personales sensibles, considerando por estos aquellos que debido a una utilización indebida puedan dar origen a discriminación o su divulgación conlleve un riesgo para el titular.
                    <br><br>
                
                    En todo momento se procurará que los datos personales contenidos en las bases de datos o archivos que en su caso se utilicen, sean pertinentes, correctos y actualizados para los fines para los cuales fueron recabados.
                    <br><br>
                
                    El Sitio Web podrá incluir hipervínculos o enlaces que permitan acceder a páginas web de terceros distintos de INVOLVE Los titulares RH, de dichos sitios web dispondrán de sus propias políticas de privacidad y protección de datos, por lo cual <b>INVOLVE RH</b>, no asume ningún tipo de responsabilidad por los datos que son facilitados por el Usuario a través de cualquier Sitio Web distinto a  <a href="www.involverh.site" target="_blank">www.involverh.site</a>
                    <br><br>
                
                    Por otra parte, el acceso al Sitio Web puede implicar la utilización de cookies, las cuales, son pequeñas cantidades de información que se almacenan en el navegador utilizado por el Usuario. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación, para ello, pueden recabar información para ingresar al Sitio Web, almacenar las preferencias del Usuario, así como la interacción que este tenga con el Sitio Web, como por ejemplo: la fecha y hora en la que se accede al Sitio Web, el tiempo que se ha hecho uso de este, los sitios visitados antes y después del mismo, el número de páginas visitadas, la dirección IP de la cual accede el usuario, la frecuencia de visitas, etc.
                    <br><br>
                
                    Este tipo de información será utilizada para mejorar el Sitio Web, detectar errores, y posibles necesidades que el Usuario pueda tener, lo anterior a efecto de ofrecer a los Usuarios servicios y contenidos de mejor calidad. En todo caso, la información que se recopile será anónima y no se identificará a usuarios individuales.
                    <br><br>
                
                    En caso de que el Usuario no desee que se recopile este tipo de información deberá deshabilitar, rechazar, restringir y/o eliminar el uso de cookies en su navegador de internet. Los procedimientos para realizar estas acciones pueden diferir de un navegador a otro; en consecuencia, se sugiere revisar las instrucciones facilitadas por el desarrollador del navegador. En el supuesto de que rechace el uso de cookies (total o parcialmente) el Usuario podrá continuar haciendo uso del Sitio Web, aunque podrían quedar deshabilitadas algunas de las funciones de este.
                    <br><br>
                
                    Es posible que en el futuro estas políticas respecto a las cookies cambien o se actualicen, por ello es recomendable revisar las actualizaciones que se realicen a los presentes Términos y Condiciones, con objetivo de estar adecuadamente informado sobre cómo y para qué utilizamos las cookies que se generan al ingresar o hacer uso del Sitio Web.</p>
                
                    <p class="upper"><b>POLÍTICA DE ENLACES.</b><br>
                    El SITIO WEB puede contener enlaces, contenidos, servicios o funciones, de otros sitios de internet pertenecientes y/o gestionados por terceros, como por ejemplo imágenes, videos, comentarios, motores de búsqueda, etc.
                    <br><br>
                
                    La utilización de estos enlaces, contenidos, servicios o funciones, tiene por objeto mejorar la experiencia del Usuario al hacer uso del Sitio Web, sin que pueda considerarse una sugerencia, recomendación o invitación para hacer uso de sitios externos a <b>INVOLVE RH</b> en ningún caso revisará o controlará el contenido de los sitios externos, de igual forma, no hace propios los productos, servicios, contenidos, y cualquier otro material existente en los referidos sitios enlazados; por lo cual, tampoco se garantizará la disponibilidad, exactitud, veracidad, validez o legalidad de los sitios externos a los que se pueda tener acceso a través del Sitio Web. Así mismo, el responsable no asume ninguna responsabilidad por los daños y perjuicios que pudieran producirse por el acceso o uso, de los contenidos, productos o servicios disponibles en los sitios web no gestionados por <b>INVOLVE RH</b> a los que se pueda acceder mediante el Sitio Web.
                    <br><br>
                
                    No se permite la reproducción (total o parcial) de los contenidos, productos o servicios disponibles en el Sitio Web sin la autorización expresa de <b>INVOLVE RH</b>, tampoco se permitirán manifestaciones falsas, inexactas o incorrectas sobre el Sitio Web, ni sobre sus contenidos, productos o servicios, pudiendo <b>INVOLVE RH</b> restringir el acceso al Sitio Web a toda aquella persona que incurra en este tipo de actos.</p>
                
                    <p class="upper"><b>POLÍTICA EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL.</b><br>
                    <b>INVOLVE RH</b> por sí o como parte cesionaria, es titular de todos los derechos de propiedad intelectual e industrial del Sitio Web, entendiendo por este el código fuente que hace posible su funcionamiento, así como las imágenes, archivos de audio o video, logotipos, marcas, combinaciones de colores, estructuras, diseños y demás elementos que lo distinguen. Serán, por consiguiente, protegidas por la legislación mexicana en materia de propiedad intelectual e industrial, así como por los tratados internacionales aplicables. Por consiguiente, queda expresamente prohibida la reproducción, distribución, o difusión de los contenidos del Sitio Web, con fines comerciales, en cualquier soporte y por cualquier medio, sin la autorización de <b>INVOLVE RH</b>.
                    <br><br>
                
                    El Usuario se compromete a respetar los derechos de propiedad intelectual e industrial del responsable. No obstante, además de poder visualizar los elementos del Sitio Web podrá imprimirlos, copiarlos o almacenarlos, siempre y cuando sea exclusivamente para su uso estrictamente personal.
                    <br><br>
                
                    Por otro lado, el Usuario, se abstendrá de suprimir, alterar, o manipular cualquier elemento, archivo, o contenido del Sitio Web, y por ningún motivo realizará actos tendientes a vulnerar la seguridad, los archivos o bases de datos que se encuentren protegidos, ya sea a través de un acceso restringido mediante un usuario y contraseña, o porque no cuente con los permisos para visualizarlos, editarlos o manipularlos.
                    <br><br>
                
                    En caso de que el Usuario o algún tercero consideren que cualquiera de los contenidos del Sitio Web suponga una violación de los derechos de protección de la propiedad industrial o intelectual, deberá comunicarlo inmediatamente a <b>INVOLVE RH</b> a través de los datos de contacto disponibles en el propio Sitio Web y/o a través de los siguientes medios:
                    <br><br>
                
                    Teléfono: 5550253793 <br>
                    Correo electrónico: <u>contacto@involverh.com</u> </p>
                
                    <p class="upper"><b>LEGISLACIÓN Y JURISDICCIÓN APLICABLE.</b><br>
                    <b>INVOLVE RH</b> se reserva la facultad de presentar las acciones civiles o penales que considere necesarias por la utilización indebida del Sitio Web, sus contenidos, productos o servicios, o por el incumplimiento de los presentes Términos y Condiciones.
                    <br><br>
                
                    La relación entre el Usuario y <b>INVOLVE RH</b> se regirá por la legislación vigente en México, específicamente en la Ciudad de México. De surgir cualquier controversia en relación con la interpretación y/o a la aplicación de los presentes Términos y Condiciones, las partes se someterán a la jurisdicción de los tribunales de la Ciudad de México renunciando las partes a cualquier jurisdicción presente o futura que les pudiera corresponder.
                    <br><br>
                
                    Fecha de actualización 27 de enero de 2023.</p>          

                </ng-container>
                <ng-template #legalsESPContainer>
                    <!-- 
                        Remover <body> y todo lo anterior
                        Remover </body> y todo lo posterior
                        Reemplazar `>` con `>\n`
                        Reemplazar `<o:p>.*\n*.*</o:p>` con ``
                        Buscar `<o:p>` y eliminar cada etiqueta manualmente
                        Reemplazar `.*<!\[if !supportLists\]>\n` con `\n`
                        Reemplazar `.*<!\[endif\]>\n` con `\n`
                        Reemplazar `\n\n\n` con `\n`
                        Reemplazar `\n\n` con `\n`
                        Alt + shif + F para indentar el código 
                    -->
                    <div class=WordSection1>
                        <p class=MsoNormal align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
                    auto;text-align:center;line-height:normal;mso-outline-level:1;background:white'>
                            <b>
                                <span style='font-size:22.0pt;font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-color-alt:windowtext;mso-fareast-language:
                    ES-MX'>
                                    TÉRMINOS Y CONDICIONES</span>
                            </b>
                            <b>
                                <span style='font-size:22.0pt;
                    font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
                    auto;text-align:center;line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INTRODUCCIÓN</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE S.A DE C.V, (en lo
                                sucesivo “INVOLVE”), con domicilio en Calle Rio Lerma, 196 Bis, Torre B, Piso
                                4, Número Interior 403, Colonia Cuauhtémoc, Ciudad de México, C.P. 06500, te da
                                a conocer por este medio los Términos y Condiciones generales de uso de su
                                sitio web https://www.involverh.com/home (en lo sucesivo “sitio web”), y de su
                                aplicación móvil denominada INVOLVE (en lo sucesivo “la App”) y/o cualquier
                                otra plataforma digital que INVOLVE ponga a tu disposición, en las cuales se
                                pueden encontrar en forma enunciativa más no limitativa, información,
                                servicios, consultas, contenidos y/o productos.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE te da la
                                bienvenida a su sitio web, es por ello por lo que antes de que decidas
                                regístrate y acceder a la increíble experiencia que es contar con los servicios
                                de INVOLVE, te recomendamos que leas cuidadosamente los presentes Términos y
                                Condiciones a los que estarás sujeto, ya que una vez que los aceptes estarás
                                aceptando un acuerdo obligatorio bajo el cual usted acepta utilizar INVOLVE
                                disponible a través de cualquier dispositivo.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Si usted no está de
                                acuerdo con alguno de los términos y condiciones establecidos aquí, no utilice
                                este sitio de Internet, su aplicación móvil, ni los servicios proporcionados
                                por INVOLVE, S.A. DE C.V.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
                    auto;text-align:center;line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                ACEPTACIÓN</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Los presentes Términos y
                                Condiciones Generales de Uso (en lo sucesivo “Términos” y/o “Condiciones”)
                                tienen un carácter obligatorio y vinculante; todo usuario deberá abstenerse de
                                usar “La App” y el “sitio web”, y/o los servicios ofrecidos por INVOLVE en caso
                                de no aceptarlos.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Toda aplicación o gestión
                                dentro de las INVOLVE y “Página web”, se entenderá como la aceptación expresa
                                de los presentes Términos y Condiciones. Al aceptar estos Términos, aceptas que
                                existe una relación jurídica válida y vinculante entre INVOLVE y tú, vigente
                                hasta en tanto no manifiestes tu deseo de terminar dicha relación, solicitando
                                el cierre de tu cuenta creada conforme a lo dispuesto en el Aviso de Privacidad
                                Cliente&nbsp;<u>
                                    <a href="https://reclutalent.involverh.es/public/terms-and-conditions/">
                                        <span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            https://reclutalent.involverh.</span>
                                        es/public<span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            /terms-and-conditions/</span>
                                    </a>
                                </u>
                                &nbsp;y Aviso
                                de Privacidad Candidato&nbsp;<u>
                                    <a href="https://talent.involverh.es/public/terms-and-conditions/">
                                        <span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            https://talent.involverh.</span>
                                        es/public<span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            /terms-and-conditions/</span>
                                    </a>
                                </u>
                                siguiente y
                                absteniéndote de utilizar cualquier servicio que INVOLVE ofrece o llegue a
                                ofrecer.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El acceso, uso, descarga o
                                instalación del sitio Web o App INVOLVE, implica su aceptación a estos términos
                                y condiciones. Asimismo, usted acuerda utilizar INVOLVE por su propio riesgo.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal align=center style='mso-margin-top-alt:auto;mso-margin-bottom-alt:
                    auto;text-align:center;line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                DEFINICIONES</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Para efectos de los
                                Términos y Condiciones, las siguientes palabras tendrán los significados que se
                                señalan a continuación:</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Inteligencia
                                    Artificial:</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-color-alt:windowtext;mso-fareast-language:
                    ES-MX'>
                                &nbsp;combinación de algoritmos planteados con el propósito de crear
                                máquinas que presenten las mismas capacidades que el ser humano. Usamos IA
                                para: Filtrar currículos de acuerdo con las vacantes, dando un porcentaje de
                                afinidad entre el candidato y la vacante a la que se postuló, en las video
                                entrevistas serán analizadas las emociones básicas, extracción de textos en el
                                CV del candidato, solo la experiencia laboral y profesional.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Pruebas
                                    psicométricas:</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                &nbsp;consiste en evaluar a los candidatos para
                                conocer qué tipo de comportamientos y personalidad presentan ante diferentes
                                situaciones, que competencias laborales son las que tienen desarrolladas y
                                cuáles no, además de conocer su coeficiente intelectual.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Big Data:</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                consiste en
                                los datos que son tan grandes, rápidos o complejos que es difícil o imposible
                                procesarlos con los métodos tradicionales. Usamos Big Data para almacenar
                                grandes cantidades de datos y poder organizarnos, por ejemplo: nuestra red de
                                talento, reportes y estadísticas.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span class=SpellE>
                                <b>
                                    <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                        Tooltips</span>
                                </b>
                            </span>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    : </span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Son
                                orientaciones que se dan a través de la plataforma y/o sitio web con </span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                    REGLAS DE USO GENERAL</span>
                            </b>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                La App y el Sitio Web
                                están diseñados para personas que buscan empleo, así como para empleadores o
                                reclutadores que buscan candidatos para ofrecerles empleo. Usted debe usar este
                                Sitio Web y App con intenciones legítimas y de acuerdo con las indicaciones de
                                uso.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                DESCRIPCIÓN DE LOS SERVICIOS</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Los servicios que presta
                                INVOLVE a través del sitio web y/o App son exclusivamente proporcionar una
                                licencia de uso temporal, no exclusiva, limitada y revocable para que
                                empleadores ajenos a INVOLVE en la cual tendrán acceso a los siguientes
                                servicios:</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <ol style='margin-top:0cm' start=1 type=a>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span class=SpellE>
                                    <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                        Multiposting</span>
                                </span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    de
                                    Vacantes </span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Análisis biométricos en vídeo entrevistas de
                                    voz y comportamiento a través de Inteligencia Artificial.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Video entrevista de presentación, video
                                    entrevista técnica.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Plantillas editables para crear vacantes.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Posibilidad de pausar vacante y reactivarla en
                                    otro momento.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Filtros excluyentes al crear vacantes para
                                    recibir solo perfiles 100% compatibles.</span>
                                <span style='font-family:
                         "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                         ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Pruebas psicométricas enfocadas a competencias
                                    laborales, personalidad, inteligencia, comportamiento, ventas, idioma y
                                    valores.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Administra todas tus vacantes en un solo
                                    lugar.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Descarga de reportes detallados de candidatos.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Diseñar tu propio modelo de entrevistas a
                                    candidatos.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l2 level1 lfo1;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Notificaciones sobre creaciones de vacantes,
                                    postulaciones, candidatos recibidos, etc.</span>
                                <span style='font-family:
                         "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                         ES-MX'>
                                </span>
                            </li>
                        </ol>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Asimismo, los candidatos que
                                publiquen su currículum en el sistema. En virtud de lo anterior, INVOLVE no es
                                responsable por el contenido o veracidad de las publicaciones realizadas por
                                los empleadores/reclutadores, ni por la información de los candidatos publicada
                                en el Sitio Web y/o App.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE le informa que
                                para mejorar los servicios tiene acuerdos con otras plataformas con el objetivo
                                de mejorar la calidad en sus servicios.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                    LICENCIA DE USO PARA PERFIL DE CANDIDATO</span>
                            </b>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE otorga un acuerdo
                                de responsabilidad limitada, revocable y con derechos de no exclusividad para
                                acceso y uso del Sitio Web y App para el uso personal en la búsqueda de
                                oportunidades de empleo para usted mismo. Esto le autoriza a usted a ver el
                                material del Sitio Web y App solamente para su uso personal y no para uso
                                comercial con fines de lucro. Usted deberá abstenerse de publicar información
                                falsa en su perfil o suplantar la identidad de un tercero. El uso del Sitio Web
                                y App es un privilegio, por lo que INVOLVE se reserva el derecho de suspender o
                                terminar este privilegio por cualquier razón y en cualquier momento.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                    LICENCIA DE USO PARA EMPLEADORES</span>
                            </b>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE otorga, durante la
                                vigencia de los servicios contratados, una licencia de uso revocable, de
                                responsabilidad limitada, terminable y con derechos de no exclusividad para acceder
                                y usar el Sitio Web, (previa contratación) para el uso interno del reclutador
                                en la búsqueda de candidatos que cumplan con la vacante ofertada.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Esto le autoriza al
                                empleador a ver por un periodo limitado de tiempo de acuerdo con el plan
                                contratado, el material del Sitio Web. INVOLVE se reserva el derecho de
                                suspender temporal o definitivamente sus claves de acceso o dar por terminado
                                su contrato si se determina que existe un incumplimiento de cualquiera de estos
                                términos y condiciones. Usted no podrá comercializar ni revender en forma
                                alguna los servicios o porciones de éstos. Estos términos se hacen extensibles
                                al uso de todas las modalidades y herramientas cuya propiedad intelectual
                                detenta INVOLVE</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                    SU FUNCIONAMIENTO</span>
                            </b>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Perfil de
                                    Candidato</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";color:black;mso-color-alt:windowtext;mso-fareast-language:
                    ES-MX'>
                                , una vez que se registre en INVOLVE plataforma o sitio web INVOLVE
                                otorgar al candidato un perfil de usuario como candidato, registrado en su
                                sitio web, con el cual podrá alimentar y crear su curriculum para postularse a
                                las vacantes de su interés.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Perfil de Cliente:</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                una vez
                                realizado el registro y adquiridos y pagados los servicios, se procederá a la
                                carga de estos dentro del sistema y el Usuario tendrá acceso a la funcionalidad
                                del sitio web para el levantamiento de la vacante deseada.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:0cm;line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                PARTICULARIDADES
                                DE USO DEL SITIO WEB Y APP.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                En este momento Usted
                                avala y acepta que no usará (o planeará, motivará o ayudará a otros a usar) el
                                Sitio Web y App para cualquier otro propósito o que en cualquier manera esté
                                prohibido por los términos aquí mencionados o los que sean aplicables por ley.
                                Consciente de que es su responsabilidad asegurar que Usted use el Sitio Web y
                                App de acuerdo con los términos y condiciones aquí especificados.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                    REGLAS DE PUBLICACIÓN, CONDUCTA Y SEGURIDAD</span>
                            </b>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El buen uso, manejo y
                                custodia de las firmas, llaves privadas, claves de acceso, contraseñas y
                                cualquier otro dato de acceso a las Cuentas de los Usuarios son responsabilidad
                                de éstos. INVOLVE no será responsable de la falta de diligencia, negligencia o
                                impericia a cargo de los Usuarios en el control y cuidado de éstas, así como de
                                las posibles consecuencias que esto pueda implicar, las cuales incluyen, de
                                manera enunciativa más no limitativa, posibles pérdidas de Fondos de Pago Electrónico
                                o de información.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Para efectos del párrafo
                                anterior y en términos de la legislación aplicable, los mensajes de datos que
                                el Usuario emita o comunique de manera posterior a su autenticación se tendrán
                                por válidos para todos los efectos a que haya lugar y como emitidos a través de
                                dispositivos seguros y aprobados por el Usuario, así como recibidos
                                íntegramente y sin alteraciones en los servidores de INVOLVE. Por el solo hecho
                                de acceder al Sitio Web y ordenar o celebrar cualquier operación, el Usuario se
                                obliga a responder con la totalidad de su patrimonio de las obligaciones que le
                                sean atribuibles o de aquellas en las cuales haya incurrido.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE en ningún caso
                                será responsable por el mal uso de las formas de autenticación que a su
                                discreción utilice el Usuario. INVOLVE no se hace responsable de phishing,
                                suplantación de identidad, ataques cibernéticos o de cualquier tipo de ataque
                                de los que el Usuario sea víctima.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Tampoco será responsable
                                por actos de terceros que afecte la Plataforma, la red mundial de Internet o
                                cualquier otra plataforma de interconexión de forma general.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE se reserva el
                                derecho, en cualquier caso y sin que exista alguna comunicación o explicación,
                                de prohibir el acceso a su Plataforma a cualquier persona, incluyendo el acceso
                                a su Cuenta.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El proceso de recuperación
                                de las claves de acceso o contraseñas solamente se realizará mediante los pasos
                                que para ello ha establecido INVOLVE tomando en cuenta los más altos estándares
                                de seguridad. Dicho proceso únicamente podrá ser iniciado por el Usuario y
                                nunca será iniciado por parte de INVOLVE. En ninguna circunstancia INVOLVE le
                                pedirá a sus Usuarios, por correo domiciliado, correo electrónico ni por vía
                                telefónica que revelen las claves de acceso o contraseñas que el Usuario utiliza
                                para acceder a su Cuenta.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Es responsabilidad del
                                Usuario no compartir ni proporcionar sus datos de identificación, claves de
                                acceso y contraseñas, así como asegurarse que ingresa sus datos o claves en la
                                dirección segura y certificada del Sitio Web. Es responsabilidad del Usuario
                                acceder al sitio oficial de INVOLVE, por lo que el último no será responsable,
                                por ningún motivo, de la seguridad de la conexión desde la cual ingrese el
                                Usuario a la Aplicación Móvil y a la Página Web.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Derivado de lo anterior, es
                                responsabilidad del Usuario notificar a INVOLVE si cree o sospecha que su
                                Cuenta o sus claves de acceso pueden estar comprometidas, o sin un tercero está
                                actuando por su cuenta, para lo cual deberá informar a través de un mensaje por
                                el servicio de atención al cliente habilitado en la App en la Página Web.
                                Además, el Usuario se obliga a tomar todas las medidas necesarias o
                                convenientes que le solicite INVOLVE, así como a seguir los procedimientos
                                establecidos por INVOLVE para recuperar el control de su Cuenta, con la pena de
                                perderlas definitivamente</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                CESIÓN:</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El Usuario no podrá ceder
                                o transferir cualquiera de sus derechos u obligaciones respecto de estos
                                Términos y Condiciones. El Usuario no podrá directa o indirectamente acordar,
                                asignar, ni transferir a un tercero cualquier reclamación en contra de INVOLVE
                                como consecuencia de los presentes Términos y Condiciones.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Los usuarios (Usted) que
                                violen dichas reglas, les será cancelado o suspendido el uso y el acceso del
                                sitio y aplicación a discreción exclusiva de INVOLVE las reglas de Conducta,
                                Publicación y Seguridad son como se describe a continuación:</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                GENERAL</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El Usuario (Usted) se
                                compromete a utilizar el Sitio Web y App de conformidad con la ley, estos
                                Términos y Condiciones, así como con la moral y buenas costumbres generalmente
                                aceptadas y el orden público.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Resulta importante
                                informarle que INVOLVE exceptúa cualquier responsabilidad por los daños y
                                perjuicios de toda naturaleza que puedan deberse a los servicios prestados por
                                terceros a través del Sitio Web y/o App, y en particular, aunque no de modo
                                exclusivo por los daños y perjuicios que puedan deberse al incumplimiento de la
                                ley, la moral y las buenas costumbres generalmente aceptadas o de orden público
                                como consecuencia de la prestación de servicios por terceros a través del Sitio
                                Web y App; la infracción de los derechos de propiedad intelectual e industrial,
                                de los secretos empresariales, de compromisos contractuales de cualquier clase,
                                de los derechos al honor, a la intimidad personal y familiar y a la imagen de
                                las personas, de los derechos de propiedad y de toda otra naturaleza
                                pertenecientes a un tercero como consecuencia de la prestación de servicios por
                                terceros a través del Sitio web y App; la realización de actos de competencia desleal
                                y publicidad ilícita como consecuencia de la prestación de servicios por
                                terceros a través del Sitio web y/o App, la falta de veracidad, exactitud,
                                exhaustividad, pertinencia y/o actualidad de los contenidos transmitidos,
                                difundidos, almacenados, recibidos, obtenidos, puestos a disposición o
                                accesibles mediante los servicios prestados por terceros a través del sitio web
                                y App; el incumplimiento, retraso, cumplimiento defectuosos o terminación por
                                cualquier causa de las obligaciones contraídas por terceros y contratos
                                realizados con terceros en relación o con motivo de la prestación de servicios
                                a través del Sitio web y App; Usted no debe asignar o transferir sus
                                obligaciones bajo estos términos, estas condiciones constituyen todo el
                                contrato entre usted y INVOLVE, S.A. DE C.V. con respecto al uso del Sitio web
                                y App INVOLVE se reserva el derecho a denegar o retirar el acceso al Sitio Web
                                y App, en cualquier momento y sin necesidad de preaviso, a aquellos usuarios
                                que incumplan estos Términos y Condiciones.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Reglas de
                                    Publicación para CLIENTES</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El material que redacte y
                                publique en el Sitio Web no debe contener:</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Hipervínculos, hiperenlace, ligas o
                                    direcciones <span class=SpellE>
                                        URL’s</span>
                                    que no estén previamente
                                    contratados e incluidos en nuestro “Paquete de Direccionamiento&quot;.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    No debe publicar simultáneamente el mismo
                                    anuncio de empleo.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Publicación de diversos perfiles en el mismo
                                    anuncio de empleo.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Publicidad para eventos de reclutamiento.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Datos personales sensibles.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Descripciones discriminatorias o que tengan
                                    por intención menoscabar los derechos y libertades de las personas.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Invitaciones para acudir a eventos de
                                    reclutamiento de ferias de empleo.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Información confidencial o secretos
                                    corporativos (a menos que se tengan los derechos y/o el permiso del autor
                                    para su publicación).</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Material que infrinja cualquier otro derecho
                                    de autor o viole la privacidad o los derechos de publicación de otros.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Cualquier material que sea sexualmente
                                    explícito, obsceno, difamatorio, amenazante, acosador, abusivo, dañino.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Cualquier cosa que sea embarazosa u ofensiva
                                    para otra persona o entidad.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Solicitud de servicios personales.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Trabajos que sean pagados solamente por
                                    comisión.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Trabajos que no paguen por lo menos el salario
                                    mínimo diario.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Trabajos que requieran que el candidato, o
                                    empleado, haga una inversión de capital o pago.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Trabajos que requieran que el candidato, o
                                    empleado, vaya a un entrenamiento no pagado para ser contratado.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Trabajo que implique tercerizar servicios.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Ningún otro idioma que no sea el <span class=GramE>
                                        Español</span>
                                    o el Inglés.</span>
                                <span style='font-family:
                         "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                         ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Representar a otra persona, viva o muerta.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Publicar información falsa, imprecisa o
                                    engañosa que atraigan a los candidatos con el fin de obtener información
                                    personal o de empleo que él o ella no divulgarán si la intención de dicho
                                    anuncio fuera previamente conocida por el candidato.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Publicar cadenas de cartas o esquemas
                                    piramidales.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Publicar opiniones o noticias, anuncios
                                    comerciales, promoción de cursos y/o de productos.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l6 level1 lfo3;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Recolectar información masiva de candidatos.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                REDACCIÓN DEL MATERIAL:</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Sus materiales deben
                                contener suficientes detalles para transmitir claramente al usuario la
                                naturaleza y requerimientos de la oportunidad de trabajo, o las calificaciones
                                como un candidato para el empleo (datos biográficos completos, datos propios
                                verídicos).</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Es responsabilidad directa
                                de los reclutadores que la redacción de anuncios de empleo publicados a través
                                del Sitio Web y Aplicación Móvil de INVOLVE esté apegada a la ley de acuerdo
                                con la jurisdicción del país al que corresponde, dependiendo de la localidad en
                                la cual se encuentra el anuncio de empleo.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Es de suma importancia
                                cumplir con las obligaciones antidiscriminatorias que establece la legislación
                                y no restringir el acceso al empleo en virtud de origen étnico o nacional, el
                                color de la piel, la cultura, el sexo, el género, la edad, las discapacidades,
                                la condición social, la condición económica, las condiciones de salud, la
                                condición jurídica, la religión, la apariencia física, las características
                                genéticas, la situación migratoria, el embarazo, la lengua, las opiniones, las
                                preferencias sexuales, la identidad o filiación política, el estado civil, la
                                situación familiar, las responsabilidades familiares, el idioma, los
                                antecedentes penales o cualquier otra que atente contra la dignidad humana y
                                tenga por objeto anular o menoscabar los derechos y libertades de las personas,
                                lo que podría constituir una conducta que tiene el efecto de anular el
                                ejercicio de sus derechos y la igualdad real de oportunidades con respecto al
                                resto de los y las demás aspirantes a ocupar un empleo.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE en todo momento
                                pone a disposición de los candidatos las herramientas necesarias para poder
                                postularse a todos los anuncios de empleo a través del Sitio Web y App, sin
                                hacer distinción alguna entre los candidatos.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Reglas de Conducta</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <ol style='margin-top:0cm' start=1 type=1>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l5 level1 lfo4;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Usted no puede responder a publicaciones a
                                    nombre de otros usuarios de ninguna manera y para ningún otro propósito
                                    que el esperado (ejemplo, para aplicar al trabajo o para iniciar una
                                    discusión con referencia al trabajo).</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l5 level1 lfo4;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Comunicaciones solicitando el negocio del
                                    empleador están prohibidas.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l5 level1 lfo4;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Usted no puede enviar correos electrónicos
                                    comerciales a los usuarios.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                        </ol>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Reglas de
                                    Seguridad</span>
                            </b>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Los usuarios tienen
                                prohibido violar o atentar contra la seguridad del Sitio Web y App, incluyendo,
                                sin limitación:</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <ol style='margin-top:0cm' start=1 type=a>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l4 level1 lfo5;
                         tab-stops:list 36.0pt;background:white'>
                                <span class=SpellE>
                                    <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                        Accesar</span>
                                </span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    datos
                                    que no sean previstos para ese usuario o ingresar a un servidor o cuenta
                                    en la cual el usuario no esté autorizado para <span class=SpellE>
                                        accesar</span>
                                    .
                                    Si usted tiene una contraseña que le permita acceso a un área no pública
                                    de este sitio web o su aplicación móvil, no podrá divulgar o compartir su
                                    contraseña con terceros o utilizar su contraseña para cualquier fin no
                                    autorizado.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l4 level1 lfo5;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Atentar, explorar o probar la vulnerabilidad
                                    del sistema o de la red o tratar de traspasar la seguridad o las medidas
                                    de autenticación sin autorización previa;</span>
                                <span style='font-family:
                         "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                         ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l4 level1 lfo5;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Atentar e interferir con el servicio de
                                    cualquier usuario, servidor o red,&nbsp;<i>
                                        (host, <span class=SpellE>
                                            network</span>
                                        )</i>
                                    a través del envío de un virus al Sitio Web o App, u otros actos como
                                    sobrecarga&nbsp;<i>
                                        (<span class=SpellE>
                                            overloading</span>
                                        , <span class=SpellE>
                                            flooding</span>
                                        )</i>
                                    ;</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l4 level1 lfo5;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Enviar e-mails no solicitados, incluyendo
                                    promociones y/o publicidad de productos o servicios;</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                        </ol>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE no se hace
                                responsable por la información, en particular por los datos personales que los
                                candidatos envíen de manera directa a los empleadores/reclutadores a través de
                                medios ajenos a la plataforma de INVOLVE, como correo electrónico, WhatsApp o
                                vía telefónica.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                La violación a estas
                                Reglas de Seguridad puede resultar en Responsabilidad Administrativa, Civil o
                                Penal. INVOLVE investigará los casos que puedan presentar dichas violaciones y
                                cooperará con las respectivas autoridades en el proceso jurídico de los
                                usuarios que estén implicados en esas violaciones.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:0cm;line-height:normal'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;background:white;mso-fareast-language:
                    ES-MX'>
                                    Transferencia de datos personales</span>
                            </b>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE es responsable
                                transferente y el reclutador se convierte en responsable receptor, por lo que,
                                en cumplimiento con las disposiciones de la materia, el reclutador al contratar
                                el servicio se obliga a:</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l1 level1 lfo6;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Mantener la confidencialidad de los datos
                                    personales, utilizándolos únicamente para efectos de reclutamiento y
                                    selección de personal y con ningún otro propósito.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l1 level1 lfo6;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    No transferir a ningún tercero, datos
                                    personales que recibe en su calidad de responsable <span class=GramE>
                                        receptor,.</span>
                                </span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l1 level1 lfo6;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Garantizar que solo sus empleados tendrán
                                    acceso a los datos transferidos y que ninguno de ellos dará un tratamiento
                                    distinto al de reclutamiento y selección de personal.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Estas obligaciones
                                subsistirán de manera indefinida, aún después de finalizar la relación, INVOLVE
                                se deslinda de cualquier responsabilidad presente o futura, que surja del uso
                                no convenido de los datos personales que le han sido transferidos a los reclutadores,
                                por lo que en caso de contravenir estas disposiciones se obligan a sacar en paz
                                y a salvo a INVOLVE, pagando los daños y perjuicios causados por esta
                                situación.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                La recopilación y el uso
                                que hacemos de la información personal en relación con los Servicios es
                                conforme a lo dispuesto en nuestro Aviso de Privacidad para Reclutador
                                disponible en&nbsp;<u>
                                    <a href="https://reclutalent.involverh.es/public/terms-and-conditions/">
                                        <span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            https://reclutalent.involverh.</span>
                                        es/public<span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            /terms-and-conditions/</span>
                                    </a>
                                </u>
                                &nbsp;y Aviso
                                de Privacidad Candidato para Usuarios, disponible en&nbsp;<u>
                                    <a href="https://talent.involverh.es/public/terms-and-conditions/">
                                        <span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            https://talent.involverh.</span>
                                        es/public<span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            /terms-and-conditions/</span>
                                    </a>
                                </u>
                            </span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                OPINIONES</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Los comentarios y
                                opiniones expresadas en los apartados disponibles son formulados por personas
                                ajenas a INVOLVE, bajo su única y exclusiva responsabilidad. Todas las personas
                                que accedan a este sitio web y su App asumen la calidad de usuarios, y por ende
                                se comprometen a la observancia y cumplimiento de estas disposiciones. Los
                                participantes de dichos espacios se comprometen a utilizar los mismos en
                                conformidad con la ley, estas condiciones generales, así como con la moral y
                                buenas costumbres generalmente aceptadas.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE se exime de
                                cualquier tipo de responsabilidad derivada de la información, opiniones,
                                comentarios, ideas u otros contenidos realizados por los visitantes en su sitio
                                web y App. Tampoco se permitirán comentarios difamatorios, injuriosos,
                                calumniosos, obscenos, amenazadores, discriminatorios, o bien que inciten a la
                                violencia.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                    DERECHOS DE PROPIEDAD INTELECTUAL</span>
                            </b>
                            <b>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                                </span>
                            </b>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El Sitio Web, App y todos
                                sus derechos, título e intereses son propiedad única de INVOLVE, y se
                                encuentran protegidos por las leyes mexicanas de derechos de autor y de los
                                tratados internacionales. A excepción de las licencias de uso limitado
                                expresamente otorgadas a usted en estos términos, INVOLVE se reserva para el
                                mismo y sus licenciatarios todos los derechos, títulos e intereses, sin límite
                                de propiedad en lo aquí mencionado usted no puede reproducir, modificar,
                                mostrar, vender o distribuir el contenido, o usarlo en cualquier otra forma
                                para uso público o comercial.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE y todas aquellas
                                marcas de su propiedad son Marcas Registradas, protegidas por la Ley de la
                                Propiedad Industrial tanto en México como en el extranjero según las leyes
                                aplicables. La utilización, difusión, exhibición, explotación, comercialización
                                o cualquier otro uso, sea parcial o total, de forma idéntica o que confunda en
                                menor o mayor grado; sea por cualquier medio, incluyendo mas no limitándose al
                                impreso, magnético, óptico, electrónico o informático, está expresamente
                                prohibido sin previa autorización por escrito del titular de los derechos de
                                autor y/o marca correspondiente; que en el caso concreto es INVOLVE. Cualquier
                                contravención a lo anteriormente expuesto o la legislación aplicable en materia
                                de propiedad industrial y derechos de autor será considerada y perseguida como
                                un delito penal de comisión directa.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El logotipo, diseños,
                                formas, marcas nominativas, innominadas y mixtas de INVOLVE, así como cualquier
                                material estático o interactivo incluido en la Página y las Apps, está
                                debidamente registrado ante las autoridades competentes y son propiedad de
                                INVOLVE. Los derechos de propiedad intelectual correspondientes a los productos
                                y servicios, así como los materiales distintivos y dominios de la Página, los
                                derechos de uso y explotación correspondientes mismos que incluyen más no se
                                limitan a su publicación, reproducción, divulgación, transformación y
                                distribución son propiedad exclusiva de INVOLVE. El usuario no adquirirá en
                                ninguna circunstancia concesión o derecho alguno sobre la propiedad intelectual
                                e industrial de INVOLVE por el mero uso de su Página y Apps de los Servicios
                                ofrecidos en las mismas; por lo tanto, su uso no podrá ser considerado como una
                                autorización tácita o expresa para utilizar alguno de los elementos de la
                                propiedad intelectual o de derecho de autor de INVOLVE con algún fin diverso a
                                los contemplados por el presente documento.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                LIMITACIÓN DE RESPONSABILIDAD</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE no asume ninguna
                                responsabilidad por materiales publicados en el Sitio Web y/o App por los
                                usuarios y no tiene responsabilidad por sus actividades, omisiones o conducta
                                de los usuarios.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <u>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                    Por ningún
                                    motivo los servicios prestados por INVOLVE deben entenderse como servicios
                                    conocidos como outsourcing, agencia de colocación, manejo de nóminas, ni ningún
                                    servicio similar, por lo que INVOLVE no puede ser considerado por ningún medio
                                    o forma como patrón en términos del artículo 10 de la Ley Federal del Trabajo
                                    vigente en los Estados Unidos Mexicanos. Los anuncios de empleo publicados en
                                    el sitio web y <span class=GramE>
                                        app</span>
                                    , y las relaciones individuales de
                                    trabajo que puedan derivar de ellas, son exclusiva responsabilidad de la
                                    persona que lleva a cabo su publicación, motivo por el cual, cualquier persona
                                    que publique un anuncio de empleo, libera desde este momento y en el futuro a
                                    INVOLVE de cualquier responsabilidad derivada de la Ley Federal del Trabajo
                                    vigente en los Estados Unidos Mexicanos.</span>
                            </u>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                EXCLUSIÓN DE RESPONSABILIDAD</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE no es una agencia
                                de empleos ni una firma de reclutamiento, y no tiene ninguna representación ni
                                garantiza la efectividad o el tiempo en la obtención de empleo para los
                                usuarios. INVOLVE, S.A. DE C.V. no garantizan los materiales publicados en este
                                sitio web y su aplicación móvil por usuarios que resulten candidatos
                                contratados o por puestos por cubrir y no es responsable de ninguna decisión
                                sobre un empleo, por cualquier razón hecho por cualquier usuario.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                EXCLUSIÓN DE ERRORES Y PRECISIÓN DE LOS MATERIALES
                                PUBLICADOS EN EL SITIO WEB Y APLICACIÓN MÓVIL</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE no garantiza la
                                veracidad, exactitud, vigencia o confiabilidad de ninguno de los materiales
                                publicados por los usuarios, o por cualquier otra forma de comunicación que sea
                                comprometida por los usuarios. Los materiales pueden contener inexactitudes o
                                errores tipográficos. Usted acepta que cualquier consecuencia en materiales publicados
                                por los usuarios, o en cualquier otra forma de comunicación con los usuarios,
                                será a su propio riesgo.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                NOTIFICACIONES</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El uso de la plataforma
                                INVOLVE implica la aceptación a la recepción de notificaciones para los
                                usuarios mediante diversos medios, tales como notificaciones de escritorio, vía
                                WhatsApp, a través de correo electrónico, o cualquier otro medio con el cual
                                sea posible contactar al usuario con la información proporcionada en el Sitio
                                Web y/o App.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INDEMNIZACIÓN DE INVOLVE</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Usted acepta defender,
                                indemnizar y declarar exenta de responsabilidad a la Empresa y sus agentes
                                asociados, sus funcionarios, directores, empleados y agentes, frente y contra
                                cualquier reclamación, acción judicial o demanda, incluido sin limitaciones los
                                daños, costos legales y contables derivados o resultantes de cualquier alegato
                                resultado o en conexión <b>
                                    <u>
                                        con su uso</u>
                                </b>
                                del Sitio Web y App, de
                                cualquier material publicado por usted o por cualquier incumplimiento a estos
                                términos. Por lo que INVOLVE le informará <span class=GramE>
                                    que</span>
                                al
                                encontrarse en alguno de los supuestos antes mencionados como reclamaciones,
                                demandas o procedimientos judiciales, le asistirá a su costo, en la defensa de
                                tales reclamaciones, demandas o procedimientos judiciales.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                TERMINACIÓN</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE y sus agentes
                                asociados se reservan el derecho, a su entera discreción, a perseguir todos los
                                remedios legales, incluyendo, sin limitación, la eliminación de sus
                                publicaciones en este Sitio Web, la terminación inmediata de claves de acceso a
                                este Sitio Web y/o cualesquier otros servicios que le brinde la empresa y sus
                                agentes asociados, debido a cualquier incumplimiento suyo a estos términos y
                                condiciones de uso o si la empresa es incapaz de verificar o autentificar
                                cualquier información que usted presente al sitio de Internet u otros
                                registros; En caso de cancelación del servicio por estas causas INVOLVE ni sus
                                agentes asociados harán ningún reembolso de cantidades pagadas por la
                                terminación del servicio.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                ELIMINACIÓN DE CUENTA</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El Usuario puede, en el
                                momento que lo desee, dar por terminada la relación establecida por los
                                presentes Términos y Condiciones y, por ende, cancelar su cuenta con INVOLVE.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                Si deseas
                                solicitar la eliminación de tu cuenta, solo deberás colocarte en el icono de
                                ajustes y darle clic en la opción de eliminar cuenta, en el que te aparecerá un
                                mensaje para su confirmación, una vez que le des clic en confirmar, se
                                eliminará todos tus datos registrados, recibiendo un mensaje de la
                                confirmación.
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE garantiza al
                                Usuario que eliminará toda la información del Usuario contenida en la cuenta de
                                manera permanente, a excepción de las obligaciones contenidas en la Ley y otras
                                disposiciones legales aplicables. Asimismo, el Usuario podrá ejercer sus
                                Derechos ARCO definidos en los Aviso de Privacidad para el Reclutador&nbsp;<u>
                                    <a href="https://reclutalent.involverh.es/public/terms-and-conditions/">
                                        <span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            https://reclutalent.involverh.</span>
                                        es/public<span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            /terms-and-conditions/</span>
                                    </a>
                                </u>
                                &nbsp;y <span class=SpellE>
                                    el</span>
                                Candidato&nbsp;<u>
                                    <a href="https://talent.involverh.es/public/terms-and-conditions/">
                                        <span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            https://talent.involverh.</span>
                                        es/public<span style='font-family:"Calibri",sans-serif;mso-ascii-theme-font:minor-latin;
                    mso-fareast-font-family:Calibri;mso-fareast-theme-font:minor-latin;mso-hansi-theme-font:
                    minor-latin;mso-bidi-font-family:"Times New Roman";mso-bidi-theme-font:minor-bidi;
                    mso-fareast-language:EN-US'>
                                            /terms-and-conditions/</span>
                                    </a>
                                </u>
                            </span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE se reserva el
                                derecho de suspender, cancelar o modificar la Cuenta de cualquier Usuario
                                (Usted) sin previo aviso, sin que ello derive en responsabilidad alguna para
                                INVOLVE.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                INVOLVE se reserva el
                                derecho de restringir o cancelar el acceso del Usuario (Usted) a su cuenta en
                                aquellos casos en que, a juicio de INVOLVE, son:</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <ol style='margin-top:0cm' start=1 type=I>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l3 level1 lfo7;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Que INVOLVE reciba cualquier requerimiento por
                                    parte de alguna autoridad que así lo requiera;</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l3 level1 lfo7;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    El Usuario realizó alguna infracción conforme
                                    a los Términos y Condiciones.</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l3 level1 lfo7;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Se tenga una sospecha razonable o indicios de
                                    que el Usuario está realizando conductas en contravención de las políticas
                                    internas;</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                         "Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l3 level1 lfo7;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    El Usuario proporcione información falsa o
                                    engañosa acerca de su identidad;</span>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:0cm;line-height:normal;mso-list:l3 level1 lfo7;
                         tab-stops:list 36.0pt;background:white'>
                                <span style='font-family:"Tahoma",sans-serif;
                         mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                         mso-fareast-language:ES-MX'>
                                    Por cualquier motivo en el que las Operaciones
                                    que realice el Usuario representen un riesgo o daño para INVOLVE.</span>
                                <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                         mso-fareast-language:ES-MX'>
                                </span>
                            </li>
                        </ol>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                DOMICILIO Y LEGISLACIÓN APLICABLE</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Se fija como domicilio de
                                “INVOLVE” el señalado en las manifestaciones previas.</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Para lo no previsto en
                                estos Términos y Condiciones, los Clientes y Usuarios acuerdan someterse a las
                                leyes aplicables de los Estados Unidos Mexicanos.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Para la interpretación y
                                ejecución de los presentes Términos y Condiciones, el Cliente o Usuario acepta
                                someterse a la jurisdicción y competencia de los tribunales competentes de la
                                Ciudad de México, renunciando expresamente al fuero que pudiese corresponderle
                                por razón de su domicilio presente o futuro.</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                INFORMACIÓN DEL USUARIO</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                El Aviso de Privacidad de
                                INVOLVE incorpora este acuerdo.</span>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    line-height:normal;background:white'>
                            <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-color-alt:windowtext;
                    mso-fareast-language:ES-MX'>
                                CONTACTO Y PREGUNTAS</span>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";mso-fareast-language:
                    ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
                    text-align:justify;line-height:normal;background:white'>
                            <span style='font-family:
                    "Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";color:black;
                    mso-color-alt:windowtext;mso-fareast-language:ES-MX'>
                                Preguntas acerca del uso
                                de este Sitio Web y App deberán ser dirigidas al correo electrónico contacto@involverh.com</span>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:"Times New Roman";
                    mso-fareast-language:ES-MX'>
                            </span>
                        </p>
                        <p class=MsoNormal>
                            <span style='font-family:"Tahoma",sans-serif;mso-fareast-font-family:
                    "Times New Roman";mso-fareast-language:ES-MX'>
                                Fecha de actualización 11 de <span class=GramE>
                                    Octubre</span>
                                de 2022.
                            </span>
                        </p>
                    </div>
                </ng-template>
                <!--  -->
                <div class="row mt-5 mx-auto" *ngIf="displaysAsModal">
                    <div class="col pr-2 pl-0">
                        <a *ngIf="showCancelbutton" data-dismiss="modal" class="btn btn-outline-primary btn-block" (click)="decline()">{{ 'onboarding.legals.rejectButton' | translate }}</a>
                    </div>
                    <div class="col pl-2 pr-0">
                        <a data-dismiss="modal" class="btn btn-primary btn-block" (click)="accept()">{{ 'onboarding.legals.acceptButton' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>