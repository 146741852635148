import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent {
  @Output() acceptEvent = new EventEmitter<string>()
  @Output() declineEvent = new EventEmitter<string>()
  @Input() showCancelbutton?: boolean = true;
  @Input() displaysAsModal: boolean = false;
  accept(){
    this.acceptEvent.emit('cookies')
  }

  decline(){
    this.declineEvent.emit('cookies')
  }
}
