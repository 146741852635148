import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CanActivate } from "@angular/router";
import { StorageService } from "../storage/storage.service";

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  constructor(private storageService: StorageService, private router: Router) {}

  canActivate() {
    if (!this.storageService.get('token')) {
        this.storageService.clear()
        this.router.navigate(["/"]);
        return false;
    }

    return true;
  }
}

