import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { email, hasFormControlError, required } from '../../services/custom-validators/custom-validators.service';

import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss']
})
export class ForgotPasswordPageComponent {
  forgotPasswordForm: FormGroup;
  emailSentInfo: any;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    public toastService: ToastService
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: this.fb.control('', [required, email]),
    });
  }

  ngOnInit() {
  }

  /**
   * Triggered when the user wants to recover its password
   */
  onRecoverPassword():void{
    this.emailControl.markAsTouched();
    if(this.forgotPasswordForm.valid){
      this.apiService.recoverPassword(this.emailControl.value.trim()).subscribe({
        next: (recoverPasswordResponse: HttpResponse<string>) => {
          this.emailSentInfo = {
            detailedMessage: 'Te envíamos un correo para reestablecer tu contraseña',
          }
        },
        error: (recoverPasswordError: HttpErrorResponse) => {
          if (recoverPasswordError.status === 404){
            this.toastService.error('Parece que el correo no se encuentra registrado', '');
          }
          else{
            this.toastService.error('Ocurrió un error', recoverPasswordError.message);
          }
        }}); 
    }
  } 

  hasFormControlError(formControlName: string, errorName?: string): boolean | ValidationErrors {
    return hasFormControlError(this.forgotPasswordForm, formControlName, errorName, true);
  }

  get emailControl(): FormControl{
    return this.forgotPasswordForm.get('email') as FormControl;
  }

}
