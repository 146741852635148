import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { appSettings } from 'src/app/globals/appSettings';
import { ICandidate } from 'src/app/models/candidate.models';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private searchFiltersViewSubject = new Subject<void>();
  searchFiltersViewToggled$ = this.searchFiltersViewSubject.asObservable();

  private profilePictureUpdateSubject = new Subject<string>();
  profilePictureUpdated$ = this.profilePictureUpdateSubject.asObservable();

  currentKeySystem =  '';
  constructor() {

  }

  get(property: string) {
    const value = localStorage.getItem(property);
    return value;
  }

  set(property: string, value: string | null ){
    if (value !== null){
      localStorage.setItem(property, value)
    }
    else{
      this.remove(property);
    }
  }

    /**
  *
  * @returns 'MEX' | 'ESP' according to user's keySystem
  */
    getCurrentKeySystem(){
      let candidateStorage  = localStorage.getItem('candidate')?localStorage.getItem('candidate'):'{}';
      if (candidateStorage) {
        const candidate: ICandidate = JSON.parse(candidateStorage)
        this.currentKeySystem = candidate.user.keySystem;
        return this.currentKeySystem;
      }else{
        return ''
      }
      
    }

  remove(property: string){
    localStorage.removeItem(property);
  }

  clear(): void{
    localStorage.clear();
  }

  isCandidateAuthenticated(): boolean{
    return this.get('token') !== null && this.get('candidate') !== null;
  }

  toggleSearchFiltersContainer(): void{
    // TODO: Cambiar a servicio de utils / reactivo
    this.searchFiltersViewSubject.next();
  }

  /**
   * Triggered when the user updated its profile picture. Used to replicate the change to cv and header components
   * @param profilePicture updated profile picture as base64 image
   */
  updateProfilePicture(profilePicture: string): void{
    // TODO: Cambiar a servicio de utils / reactivo
    this.profilePictureUpdateSubject.next(profilePicture);
  }
}
