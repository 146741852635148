import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { appSettings } from 'src/app/globals/appSettings';
import { ICandidate } from 'src/app/models/candidate.models';
import { ApiService } from 'src/app/services/api/api.service';
import { hasFormControlError, inputTextFormat, maxDateAllowed, minDateAllowed, required } from 'src/app/services/custom-validators/custom-validators.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-education', 
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent {
  educationForm: FormGroup;
  academicStatus: any[] = [];
  educationLevels: any[] = [];
  currentEducation : any;
  action = "CV_CREATE";
  currentCandidate : ICandidate | undefined;
  educations : any; 
  hasDateError = false;
  constructor(
    private router: Router,
    private apiService: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private storageService: StorageService,
    ) {
    const maxDate = moment(moment()).add(5, 'year').format('YYYY');
    const minDate =  moment(moment()).subtract(80, 'year').format('YYYY');
    this.educationForm = this.fb.group({
      academicLevel: this.fb.control('', [required]),
      degreeObtained: this.fb.control('', [required]),
      institutionName: this.fb.control('', [required]),
      status: this.fb.control('', [required]),
      startYear: this.fb.control('', [required, maxDateAllowed(maxDate), minDateAllowed(minDate)]),
      finishYear: this.fb.control('', [required, maxDateAllowed(maxDate), minDateAllowed(minDate)])
    });
  }

  ngOnInit(): void {
    this.getCatalogs(); 
    this.startYearControl.valueChanges.subscribe(value => {
      this.startYearControl.setValue(inputTextFormat(value,'numbersOnly'), { emitEvent: false})
    });
    this.finishYearControl.valueChanges.subscribe(value => {
      this.finishYearControl.setValue(inputTextFormat(value,'numbersOnly'), { emitEvent: false})
    });
  }

  getCandidateData(refresh = false){
    this.apiService.getCandidate(refresh).subscribe(
      (getCandidateResponse: ICandidate) => {
        this.currentCandidate = getCandidateResponse;
        this.route.params.subscribe(params => {
          this.educations = this.currentCandidate?.academicHistory || [];
          const id = params['educationId'];
          if(this.educations.length > 0 && id){
            this.currentEducation = this.educations.filter(function(education: { educationId: any; }){
              return education.educationId === id
            })[0]
            if(this.currentEducation){
              this.action = 'CV_EDIT';
              this.academicLevelControl.setValue(this.currentEducation.educationLevel.catalogSystemId);
              this.degreeObtainedControl.setValue(this.currentEducation.degree);
              this.institutionNameControl.setValue(`${this.currentEducation.instituteName}`);
              this.statusControl.setValue(this.currentEducation.educationStatus.catalogSystemId) 
              this.startYearControl.setValue(this.currentEducation.startYear)
              this.finishYearControl.setValue(this.currentEducation.endYear) 
            }
          }else{
            if (this.action != "CV_CREATE") {
              this.router.navigateByUrl('dashboard/my-cv');
            }  
          }
        })
      }
    );
  }

  getCatalogs() {
    this.apiService.getCatalogByKeySystem('educationStatus').subscribe({
    next:(getCatalogResponse: any) => {
      this.academicStatus = getCatalogResponse.body;
      this.getCandidateData();
    }, 
    error:(getCatalogError) => {
      this.toastService.error('', 'Error al obtener el catalogo de educacion');
    }});
    this.apiService.getCatalogByKeySystem('educationLevel').subscribe({
    next:(getCatalogResponse: any) => {
      this.educationLevels = getCatalogResponse.body;
    }, 
    error:(getCatalogError) => {
      this.toastService.error('', 'Error al obtener el catalogo de educacion');
    }});

  }

  validateDate(){
    if(this.startYearControl.valid && this.finishYearControl.valid) {
      this.hasDateError = this.startYearControl.value > this.finishYearControl.value;
    }
  }

  saveEducation(institutionMatch = []){
    const endpoint = this.action == 'CV_CREATE'?'addNewEducation':'editEducation';
    const experience =  this.action == 'CV_CREATE'?[this.getEducationObject(institutionMatch)]: this.getEducationObject(institutionMatch);
    this.apiService[endpoint](experience).subscribe({
      next:(response) => {
        this.getCandidateData(true);
        switch (this.action) {
          case 'CV_CREATE':
            this.router.navigateByUrl('dashboard/my-cv');
            break;
          case 'CV_EDIT':
            this.router.navigateByUrl('dashboard/my-cv');
            break;
          case 'EXTRACTION_CREATE':
            this.router.navigateByUrl('onboarding/validate-extraction');
            break;
        
          default:
            break;
        }
        
      }, 
      error:(error) => {
        localStorage.removeItem('editing-item');
        this.router.navigateByUrl('onboarding/validation-ocr');
      }
    })
  }

  save(){
    this.educationForm.markAllAsTouched()
    this.validateDate()
    if (this.educationForm.valid && !this.hasDateError) {
      this.apiService.getCatalog(`/institution?query=${this.institutionNameControl.value}`).subscribe({
        next:(getCatalogResponse: any) => {
          const institutionMatch = getCatalogResponse.body;
          this.saveEducation(institutionMatch)
        }, 
        error:(getCatalogError) => {
          this.toastService.error('', 'Error al obtener el catalogo de educacion');
        }
      });
    }
  }


  getEducationObject (institutionMatch:any) {
    const education = 
    {
      educationStatus: this.academicStatus.find(option => option.catalogSystemId === this.statusControl.value ),
      educationLevel: this.educationLevels.find(option => option.catalogSystemId === this.academicLevelControl.value ),
      countryInstitute: 'México',
      degree: this.degreeObtainedControl.value,
      instituteName: this.institutionNameControl.value,
      startYear: this.startYearControl.value,
      endYear: this.finishYearControl.value,
      ...this.currentEducation?.educationId && {educationId:this.currentEducation.educationId},
      ...this.currentCandidate?.candidateId && {candidateId:this.currentCandidate?.candidateId},
      ...institutionMatch.length>0 && {institute:{name:institutionMatch[0].name, idInstitution:institutionMatch[0].idInstitution, codeCountry:institutionMatch[0].codeCountry}}
    };

    return education;
  }

  deleteEducation(id: string){
    this.apiService.deleteEducation(id).subscribe({
      next:(response) => {
        this.getCandidateData(true);
        if (id === this.currentEducation?.educationId) {
          this.router.navigateByUrl('dashboard/my-cv');
        }
      }
    })
  }


  get academicLevelControl():FormControl{
    return this.educationForm.get('academicLevel') as FormControl;
  }

  get degreeObtainedControl(): FormControl{
    return this.educationForm.get('degreeObtained') as FormControl;
  }

  get institutionNameControl(): FormControl{
    return this.educationForm.get('institutionName') as FormControl;
  }

  get statusControl(): FormControl{
    return this.educationForm.get('status') as FormControl;
  }

  get startYearControl(): FormControl{
    return this.educationForm.get('startYear') as FormControl;
  }

  get finishYearControl(): FormControl{
    return this.educationForm.get('finishYear') as FormControl;
  }

  hasFormControlError(formControlName: string, errorName?: string): boolean | ValidationErrors{
    return hasFormControlError(this.educationForm, formControlName, errorName, true);
  }
}
