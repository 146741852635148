import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { currentCountry } from 'src/app/globals/appSettings';

@Component({
  selector: 'app-privacy-notice',
  templateUrl: './privacy-notice.component.html',
  styleUrls: ['./privacy-notice.component.scss']
})
export class PrivacyNoticeComponent {
  @Output() acceptEvent = new EventEmitter<string>()
  @Output() declineEvent = new EventEmitter<string>()
  @Input() showCancelbutton?: boolean = true;
  @Input() displaysAsModal: boolean = false;
  currentCountry = currentCountry;

  accept(){
    this.acceptEvent.emit('privacity')
  }

  decline(){
    this.declineEvent.emit('privacity')
  }
}


