<div class="privacy-notice" id="privacyNoticeModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" [ngClass]="{ 'modal fade': displaysAsModal}">
    <div class="modal-height-auto" role="document" [ngClass]="{ 'modal-dialog modal-dialog-centered': displaysAsModal}">
        <div class="modal-content">
            <div class="modal-header" *ngIf="displaysAsModal">
                <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="currentCountry === 'MEX'; else legalsESPContainer">
                    <h2 class="text-center mb-3">AVISO DE PRIVACIDAD CANDIDATO</h2>
                    <p>El presente aviso se emite en cumplimiento a lo dispuesto en los artículos 15 y 43 en su fracción III de
                        la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), así como el
                        artículo 23 de su Reglamento.
                        <br><br>
        
                        <b>INVOLVE, S.A. DE C.V.</b> (comercialmente conocido bajo el nombre de <b>“INVOLVE RH”</b>)
                            comprometido con sus usuarios reconoce que al usar el sitio web y/o plataforma, nos estás
                            proporcionando tus datos, por lo que te comunicamos que nuestro compromiso es tratar estos, para la
                            promoción laboral de vacantes para el candidato (usuario), frente a terceros propios de este sitio y
                            externos que contraten los servicios que brinda INVOLVE, S.A. DE C.V.; asimismo ofrecerte asesoría
                            en la elaboración de tu Currículum Vitae en lo sucesivo “CV” con la finalidad de que te posiciones
                            en alguna vacante, promociones comerciales de bienes o servicios propios o de terceros que
                            consideramos que pueden ser de tu interés.
                            <br><br>
        
                            Antes de proporcionarnos cualquier tipo de información, deberás leer y comprendes detenidamente los
                            alcances del presente Aviso de Privacidad, ya que, al aceptarlo, consientes de manera expresa la
                            transmisión de información a las empresas reclutadoras o a las que apliques para una vacante laboral
                            disponible.
                    </p>
        
                    <p class="upper-normal"><b>IDENTIDAD Y DOMICILIO DEL RESPONSABLE.</b></p>
                    <p>Este documento es el Aviso de Privacidad de <b>INVOLVE, S.A. de C.V.</b> (el "responsable"), quien será
                        el encargado de tratar los datos personales que usted (el "Titular") nos proporcione. Para los efectos
                        señalados en el presente Aviso de Privacidad, el responsable señala como su domicilio el ubicado en:
                        Calle Río Lerma, 196 Bis, Colonia Cuauhtémoc, Ciudad de México, C.P. 06500.</p>
        
                    <p class="upper-normal"><b>DATOS PERSONALES QUE SERÁN SOMETIDOS A TRATAMIENTO.</b></p>
                    <p>Los datos personales que el Titular proporcione a través del llenado y remisión electrónica de
                        formularios contenidos en la aplicación móvil y/o sitio web que serán sometidos a tratamiento son:</p>
                    <br><br>
        
                    <b><u>PARA REGISTRAR UN PERFIL DE CANDIDATO:</u></b>
                    <ol type="i">
                        <li>Correo electrónico </li>
                        <li>Nombre(s)</li>
                        <li>Apellido paterno </li>
                        <li>Apellido materno</li>
                        <li>Fecha de Nacimiento</li>
                        <li>Genero</li>
                        <li>Número Telefónico </li>
                        <li>Estado Civil.</li>
                        <li>Nacionalidad.</li>
                    </ol>
        
                    <b><u>Información Laboral:</u></b>
                    <ol type="i">
                        <li>Información sobre grados académicos</li>
                        <li>Lo relativo a historial académico y laboral;</li>
                        <li>Datos relativos a interés en puestos de trabajo;</li>
                        <li>Habilidades y capacidades;</li>
                        <li>Pretensiones laborales (incluyendo sueldo);</li>
                        <li>Currículum vitae (los "Datos").</li>
                    </ol>
        
                    <p>También <b>INVOLVE</b> te informa que a través de su sitio web y/o App, con motivo de los servicios que
                        brinda a sus suscriptores, te solicitará una video presentación y una entrevista técnica la cual será
                        videograbada, en la cual se te preguntará sobre tu experiencia laboral, tus habilidades profesionales y
                        descripción como colaborador, las cuales se desarrollan de la siguiente forma:</p>
        
                    <ul>
                        <li><b>Video presentación:</b> consiste en un video elaborado por el postulante (Titular) en el cual
                            contestara a su libre arbitrio las siguientes preguntas:
                            <ol type="a">
                                <li>¿Cuál consideras tu experiencia laboral más importante? y ¿Por qué?</li>
                                <li>¿Cuáles son tus habilidades profesionales más relevantes?</li>
                                <li>¿Qué es lo que más disfrutas de tu profesión?</li>
                                <li>¿Cómo te describirían como colaborador?</li>
                            </ol>
                        </li>
                        <li><b>Entrevista Técnica:</b> por este medio contestaras máximo 4 (cuatro) preguntas que serán
                            designadas por el reclutador, la cuales estarán enfocadas directamente a la experiencia del
                            candidato y/o conocimientos técnicos que necesita para el desempeño de sus funciones en la vacante
                            ofertada (las cuales podrás consultar una vez postulado).</li>
                    </ul>
        
                    <p>Lo anterior con la intención de brindar un mejor servicio a nuestros suscriptores y ellos puedan conocer
                        de mejor manera tu perfil.
                        <br><br>
        
                        Por otro lado, te informamos que como parte de los servicios que incluye la plataforma, serán aplicadas
                        pruebas psicométricas, las cuales incluyen cuestionamientos únicas y específicos respecto a tu perfil
                        laboral, con la finalidad de conocer mejor tus habilidades y aptitudes mismas que se describen a
                        continuación:
                    </p>
        
                    <ul>
                        <li>CLEAVER: <br>
                            Esta prueba mide comportamientos y habilidades, analizado cómo actúa la persona en un ambiente
                            normal, los cambios en su comportamiento cuando existe motivación y cómo actúa bajo presión ya sea,
                            de carácter interno o externo, además mide las capacidades de empuje (Dominio), Influencia
                            (Persuasión), Constancia y Apego (Cumplimiento) que posee la persona evaluada. Permite determinar si
                            la persona posee las competencias laborales idóneas para un determinado puesto o bien la
                            personalidad para desempeñarse en una profesión determinada.</li>
        
                        <li><b>MOSS</b> <br>
                            Permite identificar las cualidades de la personalidad que pueden ser a un determinado estilo de
                            liderazgo. Se evalúa la capacidad de identificar dificultades y afrontarlas de manera oportuna y
                            efectiva, suele usarse sobre todo en mandos intermedios como jefes y Gerentes.
                        </li>
        
                        <li><b>IPV:</b> <br>
                            Este test permite evaluar un conjunto amplio de características de personalidad consideradas
                            relevantes para el éxito de los profesionales comerciales. Determinando el grado de características
                            que posee el candidato o candidata en su personalidad ante una situación o puesto específicos.
                        </li>
        
                        <li><b>KOSTICK</b> <br>
                            Esta prueba permite conocer la personalidad limitándose a siete factores: grado de energía,
                            adaptación al trabajo, naturaleza social, liderazgo, naturaleza emocional, subordinación y modo de
                            vida, midiendo el comportamiento en el Área Laboral.
                        </li>
        
                        <li><b>TERMAN:</b> <br>
                            El objetivo de esta prueba es medir el coeficiente intelectual de las personas que cuentan con un
                            grado de escolaridad suficiente que puedan comprender problemas expuestos en forma escrita.
                        </li>
        
                        <li><b>LIFO:</b> <br>
                            Es un método de valoración de existencias o inventarios, para mostrar una imagen más fiel y realista
                            de la empresa.
                        </li>
        
                        <li><b>ZAVIC</b> <br>
                            Prueba psicométrica que da la posibilidad de indagar en los valores e intereses que poseen los
                            candidatos, para así tener la certeza de que se trata de personas honestas y responsables que
                            aportarán beneficios a la organización.
                        </li>
        
                        <li><b>16 PF:</b> <br>
                            Indica la capacidad de apertura mental o el respeto a los modos de hacer tradicionales, midiendo
                            también el nivel de confiabilidad de las prospuestas.
                        </li>
        
                        <li><b>BARSIT:</b> <br>
                            Tiene el objetivo de medir la inteligencia a través de la valoración de factores de inteligencia
                            verbal y razonamiento numérico.
                        </li>
        
                        <li><b>WONDERLIC:</b> <br>
                            Abastece una estimación precisa de la inteligencia de una persona, midiendo las habilidades del
                            evaluado con respecto a los requisitos de un trabajo o tarea especifica.
                        </li>
        
                        <li><b>GORDON</b> <br>
                            Este test permite evaluar la personalidad del individuo mediante 9 rasgos que son significativos en
                            el funcionamiento diario de la persona, como son: Ascendencia, Responsabilidad, Estabilidad
                            Emocional, Sociabilidad, Cautela, Originalidad, Relaciones personales y Vigor.
                        </li>
        
                        <li><b>RAVEN:</b> <br>
                            Es un Test profesional a través de Matrices Progresivas de Raven, miden la capacidad para dar
                            sentido a un material desorganizado o confuso, es decir, constructos claramente no verbales que
                            facilitan la captación de una estructura compleja.
                        </li>
        
                        <li><b>INGLÉS</b> <br>
                            Es importante resaltar que dichas psicométricas solo realizan preguntas enfocadas a sus habilidades,
                            las pruebas psicométricas antes descritas son realizadas por un proveedor a través de una API
                            denominado Psicometrias.mx.
                        </li>
                    </ul>
        
                    <p>¿Qué es API?
                        <br>
        
                        Es una Interfaz de programación de aplicaciones
                        <br><br>
        
                        ¿Cómo funciona la API de Psicometrias.mx?
                        <br>
                        Se utiliza para la integración de las pruebas psicométricos con INVOLVE RH.
                        <br>
                        Una vez que el candidato complete el envió de la video presentación y video entrevista técnica, tiene la
                        posibilidad de iniciar sus pruebas psicométricas (siempre y cuando la vacante tenga asociada alguna
                        prueba psicométrica), este proceso es iniciado por el candidato desde la App y/o página web de
                        <b>INVOLVE RH.</b>
                        <br><br>
        
                        Una vez iniciado este proceso, <b>INVOLVE RH</b> enviara un correo electrónico a el candidato y le dará
                        clic al botón “ir a las pruebas” el cual lo redireccionara a la plataforma de “Pruebas Psicométricas”,
                        únicamente se encarga de realizar las pruebas psicométricas, por lo que una vez que culmine dichas
                        Pruebas Psicométricas automáticamente envía una copia de dicho resultando a <b>INVOLVE RH.</b>
                        <br><br>
        
                        Las pruebas en todo momento pertenecen a “Pruebas Psicométricas”, podrás encontrar el aviso de
                        privacidad y los términos y condiciones en las siguientes URL <a href="https://psicometricas.mx/"
                            target="_blank">https://psicometricas.mx/</a> y <a href="https://psicometricas.mx/aviso-privacidad"
                            target="_blank">https://psicometricas.mx/aviso-privacidad</a>
                        <br><br>
        
                        Es importante resaltar, que dichas pruebas son realizadas por expertos, a través de estas pruebas solo
                        se realizarán preguntas diseñadas para conocer las habilidades, aptitudes y capacidades de los
                        candidatos, en ningún momento se solicitara información personal o sensible, el único objetivo de la
                        aplicación de estas pruebas es reclutar personal que cuente con el perfil específico y/o características
                        muy particulares, debido a que facilitan la observación de los puntos clave de cada persona.
                        <br><br>
        
                        Con la aceptación de este aviso de privacidad consientes y aceptas que se transmitan los datos y las
                        videograbaciones antes aludidas.
                        <br><br>
        
                        El Titular (Candidato) no deberá añadir datos personales no requeridos o diferentes a aquellos
                        solicitados por el responsable en los campos designados para ello, ya que cualquier conducta contraria
                        <b><u>no garantiza la confidencialidad de dicha información.</u></b>
                    </p>
        
                    <p class="upper-normal"><b>CONSERVACIÓN DE LOS DATOS.</b></p>
                    <p>Los datos personales del Titular serán conservados por el INVOLVE RH para la prestación de los servicios
                        durante el tiempo que el Titular esté registrado en el Sitio Web y aplicación móvil, a menos que el
                        Titular solicite la supresión de estos mediante el procedimiento establecido en este aviso mismo que más
                        adelante se detalla. En todo caso el usuario acepta el uso de sus datos personales para los fines
                        propios de la naturaleza de la plataforma la cual es de indole laboral y profesional, por lo que el
                        usuario titular está obligado a darse de baja para la eliminación total de sus datos personales, de lo
                        contrario su información seguirá almacenada y resguardada por INVOLVE RH para los fines conducentes</p>
                    <br><br>
        
                    <p class="upper-normal"><b>DATOS PERSONALES SENSIBLES.</b></p>
                    <p>De conformidad con el artículo 3, fracción VI de la LFPDPPP, se entiende como Datos personales sensibles:
                        aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida
                        pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran
                        sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y
                        futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical,
                        opiniones políticas, preferencia sexual.”</p>
        
                    <ol type="a">
                        <li><b>Genero</b> <br>
                            De conformidad con el artículo 9 de la LFPDPPP los datos personales sensibles, deberán ser
                            proporcionados por el titular de forma expresa, toda vez que INVOLVE RH a través del sitio web y/o
                            aplicación solicita de forma optativa nos proporciones tu género en sus dos variantes masculino y
                            femenino, también encontraras la opción de omitir esta respuesta, información que si decides
                            proporcionar, INVOLVE RH la solicita con el único propósito de establecer una clara visión y
                            dirección, de comunicar nuestro compromiso con la promoción de la igualdad de género e inclusión.
                            <br><br>
        
                            El Responsable no solicitará de forma alguna, y el Titular no proporcionará en ningún momento al
                            Responsable, algún otro dato sensible, distinto al antes expuesto, es decir, aquellos datos
                            personales íntimos o cuya utilización indebida pueda dar origen a discriminación o conlleve un
                            riesgo grave para el Titular. El Titular se obliga a no proporcionar al Responsable ninguna
                            información relativa a su origen racial o étnico, estado de salud presente y/o futuro, información
                            genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas
                            y/o preferencia sexual. En caso de que el Titular proporcione datos sensibles a través de documentos
                            subidos al sitio web y/o aplicación móvil, o por medio de los formularios, acepta y consiente que
                            los mismos sean tratados conforme a los términos y condiciones del sitio web y a su Aviso de
                            Privacidad, siendo obligación del Titular no proporcionar este tipo de datos en la información que
                            sea subida al sitio web y/o aplicación móvil.
                        </li>
                    </ol>
                    <br>
        
                    <p class="upper-normal"><b>USO A SUS DATOS.</b></p>
                    <p>Sus Datos serán tratados para las siguientes finalidades:</p>
        
                    <ol type="a">
                        <li><b>Objetivos Primarios:</b> Prestación de servicios a través de su sitio web y/o aplicación móvil
                            con el propósito de proporcionar una plataforma para la promoción de ofertas laborales y búsqueda de
                            empleo por terceros, de acuerdo con lo siguiente:
                            <ol type="i">
                                <li>Creación de tu perfil como candidato.</li>
                                <li>Asesoría con la creación de tu perfil como candidato;</li>
                                <li>Envío de tu currículo a las empresas que buscan tu perfil o se interesan en el mismo;</li>
                                <li>Permitir que las empresas, a través de una video entrevista conozca más a detalle sobre tus
                                    habilidades y formación laboral.</li>
                                <li>Recibir alertas de ofertas laborales que podrían resultar de tu interés de acuerdo con tu
                                    perfil;</li>
                                <li>Contactarte para el seguimiento de reportes de ofertas de trabajo que consideres
                                    fraudulentas;</li>
                                <li>Informarte sobre cambios o nuevos productos y servicios;</li>
                                <li>vIdentificar de forma única el comportamiento de los usuarios dentro de la plataforma;</li>
                                <li>Obtener reporte psicométrico.</li>
                                <li>Brindarte asesoría y soporte técnico.</li>
                            </ol>
                        </li> <br>
        
                        <li><b>Objetivos Secundarios:</b> Mejora del proceso comercial y de mercadotecnia, utilizando la
                            información para:</li>
                        <ol type="i">
                            <li>i. Gestionar un servicio eficaz y suministrar una mejor atención para mejorar su experiencia en
                                el uso del sitio web y/o App;</li>
                            <li>ii. Informarle y presentar servicios que puedan resultar relevantes o atractivos;</li>
                            <li>iii. Enviarle por cualquier medio todo tipo de comunicación para participar en pruebas,
                                promociones, encuestas, ofertas y campañas de publicidad, propias o de terceros;</li>
                            <li>iv. Evaluar la calidad del servicio o realizar estudios sobre hábitos de consumo.</li>
                        </ol>
                    </ol>
        
                    <p class="upper-normal"><b>USO DE DATOS HESK</b>
                        <br>
                        Actualmente INVOLVE RH, ofrece a usted titular, la oportunidad levantar incidencias, para mejorar la
                        calidad en el servicio del sitio web y/o App, es por ello que contrato los servicios de HESK que permite
                        un contacto rápido, así como una comunicación directa con <b>INVOLVE RH</b>, de esta forma, si un
                        interesado se pone en contacto con el controlador a través de un formulario de contacto, los datos
                        personales transmitidos por el interesado se almacenan automáticamente.
                        Sus Datos serán tratados para las siguientes finalidades:
                        <br><br>
        
                        Dichos datos personales transmitidos de manera voluntariamente al controlador de datos, se almacenan con
                        el fin de procesar o contactar al sujeto de datos.
                        <br><br>
        
                        No hay transferencia de estos datos personales a terceros. El propósito del componente HESK es analizar
                        y evaluar el uso de nuestro sitio web y que el servicio brindado sea de la mejor calidad y que sus
                        inquietudes sobre su estancia en la App y/o sitio web sean resueltas de forma rápida y ordenada.
                        <br><br>
        
                        Puede obtener más información y las disposiciones reales de protección de datos de HESK en <a
                            href="https://www.hesk.com/knowledgebase/index.php?article=78"
                            target="_blank">https://www.hesk.com/knowledgebase/index.php?article=78</a>.
                    </p>
                    <br>
        
                    <p class="upper-normal"><b>TRATAMIENTO DE LOS DATOS Y SU NEGATIVA.</b>
                        <br>
                        El Responsable estará facultado para dar el tratamiento a los Datos recabados, siempre y cuando este
                        tratamiento se haga con relación a las finalidades señaladas en los incisos (a) y (b) de las finalidades
                        del presente Aviso de Privacidad. El Responsable podrá realizar dicho tratamiento en tanto no reciba la
                        negativa por parte del Titular.
                        <br><br>
        
                        A efecto de que el Titular pueda manifestar su negativa o revocar el consentimiento para el tratamiento
                        de sus datos personales, deberá dirigirse al Responsable por medio del procedimiento establecido para el
                        ejercicio de los Derechos ARCO (cuyo término se define más adelante), en el presente Aviso de
                        Privacidad.
                        <br><br>
        
                        Es importante que considere que la solicitud de negativa al tratamiento de sus datos, en ciertos casos
                        no podrá ser atendida de manera inmediata, puesto que si subsiste una obligación legal para el
                        Responsable, éste tratara sus datos personales hasta culminar con el cumplimiento de la misma. Asimismo,
                        la revocación del consentimiento al tratamiento de sus datos implica que no se podrá continuar con la
                        prestación de los servicios, teniendo como consecuencia la terminación de los mismos.
                    </p>
                    <br>
        
                    <p class="upper-normal"><b>TRANSFERENCIA DE DATOS.</b>
                        <br>
                        Para poder cumplir con las finalidades de este Aviso de Privacidad si cuentas con un perfil de
                        candidato, transferiremos todo o parte de tus Datos a terceros cuando apliques a alguna oferta
                        publicada, o bien cuando el reclutador te busque dentro de la base de datos de candidatos del
                        Responsable. Los terceros receptores de tus datos <b><u>podrán ser personas físicas o morales,
                                nacionales o extranjeras</u></b>, dedicadas a la industria, el comercio y/o los servicios,
                        quienes quedarán obligados a mantener la confidencialidad de los Datos y tratarlos conforme a este Aviso
                        de Privacidad, tratando tus datos personales únicamente para fines de reclutamiento y selección.
                        <br><br>
        
                        En ambos casos, el Responsable podrá transferir sus datos personales en los casos específicamente
                        previstos en la Ley
                    </p>
        
                    <p class="upper-normal"><b>EJERCICIO DE LOS DERECHOS ARCO.</b>
                        <br>
                        <b>¿En qué consisten los Derechos de ARCO?</b>
                    </p>
        
                    <ol type="1">
                        <li><b>Acceso. -</b> Implica conocer en todo momento sus datos personales en posesión de INVOLVE RH, así
                            como el Aviso de privacidad correspondiente.</li>
                        <li><b>Rectificación. -</b> Si alguno de sus datos es inexacto o incompleto, podrá solicitar su
                            modificación, a nuestra área jurídica legal@involverh.com.mx adjuntando la documentación que
                            acredite dicha corrección.</li>
                        <li><b>Cancelación. -</b> Podrá requerir cuando así lo considere la cancelación de sus datos y, en caso
                            de ser procedente, su información personal entrará en un periodo de bloqueo para proceder
                            posteriormente a su eliminación. Al entrar a dicho periodo, su información ya no podrá ser tratada
                            por INVOLVE RH.</li>
                        <li><b>Oposición. -</b> Podrá en todo momento y por causa legítima objetar el tratamiento de sus datos
                            personales. Si su solicitud resulta procedente, INVOLVE RH ya no podrá hacer uso de estos.</li>
                    </ol>
        
                    <p>El Titular tendrá derecho para solicitar al Responsable, en cualquier momento el acceso, rectificación,
                        cancelación u oposición respecto de sus Datos (los "Derechos ARCO"), para lo cual deberá enviar una
                        solicitud al Responsable, a la atención del área de <b><u>Protección de Datos Personales</u></b>, a
                        través de a la siguiente dirección: contacto@involverh.com o al correo electrónico: arco@involverh.com.
                        <br><br>
        
                        Para ejercer los Derechos ARCO, o para revocar el consentimiento al tratamiento de los datos personales,
                        deberá contener y estar acompañada de lo siguiente:
                    </p>
        
                    <ol type="a">
                        <li>Nombre</li>
                        <li>Apellido paterno y materno del Titular;</li>
                        <li>Domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su
                            solicitud;</li>
                        <li>Documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular;
                        </li>
                        <li>Descripción clara y precisa de los Datos respecto de los que se busca ejercer alguno de los derechos
                            antes mencionados, incluyendo si se desea eliminar.</li>
                        <li>Cualquier otro elemento o documento que facilite la localización de los Datos del Titular.</li>
                    </ol>
        
                    <p>Una vez que el Responsable reciba el escrito del Titular referente al ejercicio de sus Derechos ARCO, el
                        Responsable dentro de los 15 días hábiles tendrá que analizar, atender y enviar al Titular la respuesta
                        correspondiente. El medio del envío de la respuesta podrá ser por la forma que indique el Titular o
                        utilizando el mismo medio por el cual el Titular le hizo llegar el correspondiente escrito al
                        Responsable, mismo que aplicara en caso de que no se especifique algún otro medio de contacto.
                        <br><br>
        
                        <b>MAILING y SMS.</b>
                        <b>PROCESO DE VERIFICACIÓN DE USUARIOS, POR MEDIO DEL CANAL SMS.</b>
                        <br><br>
        
                        INVOLVE RH te informa que a través de Twillo, que es una API interfaz de programación de aplicaciones,
                        permite él envió de mensajería de texto (SMS), el cual tiene únicamente como función de confirmación de
                        registro de cuentas, ayudando a verificar que no sea un robot el que esté realizando el proceso de
                        registro de candidato, el cual únicamente funge como tercero, por lo que en ningún momento tiene acceso
                        a la información proporcionada por el usuario, lo cual podrás corroborar en su aviso de privacidad en el
                        siguiente link <a href="https://www.twilio.com/legal/privacy.">https://www.twilio.com/legal/privacy.</a>
                        <br><br>
        
                        Para suprimir la recepción de Mailyng publicitario, el Titular podrá realizarlo seleccionando la opción
                        de suscribir o un subscribe que se encuentra al final de la comunicación recibida, o bien, realizando la
                        petición al correo electrónico contacto@involverh.com con el asunto “de suscribir publicidad”.
                    </p>
        
                    <p class="upper-normal"><b>GEOLOCALIZACIÓN.</b>
                        <br>
        
                        Para los efectos del presente la geolocalización será entendida como el uso de coordenadas de latitud y
                        longitud de un dispositivo obtenidas a través de GPS. Esta información será requerida únicamente durante
                        el uso de la Aplicación Móvil y/o Sitio Web en cualquier dispositivo.
                        <br><br>
        
                        Es necesaria únicamente para validar el lugar físico desde donde la persona se encuentra solicitando su
                        alta.
                        <br><br>
        
                        En caso de que el usuario no esté de acuerdo con que se recabe la geolocalización de su dispositivo,
                        deberá denegar los permisos para acceder a la misma dentro de la Aplicación Móvil, o bien, dentro de la
                        configuración de privacidad (en específico ubicación) de su navegador de Internet.
                    </p>
        
                    <p class="upper-normal"><b>MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.</b>
                        <br>
        
                        Para limitar el tratamiento de los Datos, el Titular únicamente tendrá los medios señalados en el
                        presente Aviso de Privacidad. Quedará a elección del Titular, acudir o solicitar vía telefónica su
                        inscripción al Registro Público de Consumidores previsto por la Ley Federal de Protección al Consumidor,
                        a efecto de limitar el uso de los datos personales en posesión de los particulares.
                    </p>
        
                    <p class="upper-normal"><b>ELIMINACIÓN DE CUENTA.</b>
                        <br>
        
                        Si deseas solicitar la eliminación de tu cuenta, para App solo deberás colocarte en el icono de ajustes
                        y darle clic en la opción de eliminar cuenta, y para sitio web solo deberas colocarte en la opción de
                        eliminación de cuenta, posterior te aparecerá un mensaje para su confirmación, una vez que le des clic
                        en confirmar, se eliminrá todos tus datos registrados, recibiendo un mensaje de la confirmación.
                    </p>
        
                    <p class="upper-normal"><b>UNIDADES DE COMUNICACIÓN.</b>
                        <br>
        
                        Cualquier comunicación que el Titular dirija al Responsable relacionada al presente Aviso de Privacidad,
                        deberá realizarse a las direcciones proporcionadas para el ejercicio de los Derechos ARCO, mencionando
                        de manera clara y precisa los siguientes datos:
                    </p>
        
                    <ol type="1">
                        <li>Nombre</li>
                        <li>Apellido paterno y Materno del Titular;</li>
                        <li>Domicilio y/o correo electrónico y/o cualquier otro medio para comunicarle la respuesta a su
                            solicitud;</li>
                        <li>Documentos oficiales que acrediten la identidad o, en su caso, la representación legal del Titular;
                            y</li>
                        <li>Descripción clara y precisa del objeto de su comunicación.</li>
                    </ol>
        
                    <p>De la misma manera, el Responsable atenderá y responderá cualquier comunicación que le sea dirigida
                        dentro de los 20 días hábiles al haber recibido dicho escrito, ya sea en la en la misma forma en el que
                        le hicieron llegar la comunicación o en la forma en que hubiesen indicado.</p>
        
                    <p class="upper-normal"><b>MEDIOS REMOTOS.</b>
                        <br>
        
                        En caso de que el Titular proporcione sus Datos a través de medios electrónicos, incluyendo el sitio web
                        (Internet) y/o aplicación móvil del Responsable, entonces el Titular entiende, acepta y reconoce que:
                        <br><br>
        
                        a) El sitio web y/o aplicación móvil del Responsable puede incluir enlaces a sitios web de terceros, que
                        de accederse, ocasionará que se abandone el sitio web y/o aplicación móvil del Responsable, por lo cual
                        el Responsable no asume ninguna responsabilidad en relación con esos sitios web de terceros que el
                        Titular decida visitar.
                        <br><br>
        
                        b) El sitio web y/o aplicación móvil del Responsable puede incluir enlaces a sitios que administran
                        redes sociales, en cuyo caso el Titular acepta que al proporcionar cualquier tipo de información o Datos
                        en dichos sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida y tratada por
                        cualquier persona, y por lo tanto libera de cualquier responsabilidad al Responsable.
                        <br><br>
        
                        c) Es posible que los sistemas del Responsable recopilen Datos del Titular tales como tipo de navegador,
                        sistema operativo, páginas de Internet visitadas, dirección IP, etc., a través "cookies" o "web
                        involverh.com", entre otros.
                        <br><br>
        
                        Involve utiliza cookies, web beacons y/o Kits de Desarrollo de Software (“SDKs”) para facilitar la
                        navegación en sus Plataformas.
                        <br><br>
        
                        Las cookies constituyen una herramienta empleada por los servidores web para almacenar y recuperar
                        información que se guarda en el navegador utilizado por los usuarios o visitantes del sitio web, lo que
                        permite guardar tus preferencias personales para brindarte una mejor experiencia de navegación.
                        <br><br>
        
                        Las cookies tienen fecha de caducidad, que puede oscilar desde el tiempo que dura la sesión o visita al
                        sitio web hasta una fecha específica a partir de la cual dejan de ser operativas. Las cookies empleadas
                        en las Plataformas se asocian únicamente con un Usuario anónimo y su equipo informático, no proporcionan
                        referencias que permitan deducir el nombre y apellidos del Usuario, no pueden leer datos de su disco
                        duro ni incluir virus en sus textos.
                        <br><br>
        
                        Puedes configurar tu navegador para aceptar o rechazar automáticamente todas las cookies o para recibir
                        un aviso en pantalla sobre la recepción de cada cookie y decidir en ese momento su implantación o no en
                        tu disco duro.
                        <br><br>
        
                        Te confirmamos que “INVOLVE RH” recaba datos personales a través de estos medios, es por ello por lo que
                        te informamos que a través de estas recaban los siguientes datos: (i)Nombre; (ii) Apellido materno y
                        Paterno; (iii) correo electrónico, conforme a lo señalado en la política de cookies talent.involverh.com
                        <br><br>
        
                        d) El sitio web y la aplicación móvil del responsable incluye para desarrollar sus funcionalidades los
                        servicios de la persona moral extranjera Textkernel LLS US comercializado como SOVREN, cabe aclarar que
                        en ningún momento la moral antes referida va a hacer uso de los datos que el Titular (Usted) nos
                        proporcione, ya que solo almacenara la información.
                    </p>
        
                    <p class="upper-normal"><b>¿Cómo funciona AWS e INVOLVE?</b>
                        <br>
        
                        Al guardar o recibir un CV en la App y/o sitio web INVOLVE se envía el documento codificado hacia el API
                        de AWS, conocido como servicios en la nube es una red de servidores remotos conectados a internet para
                        almacenar, administrar y procesar datos, servidores, bases de datos, redes y software, fungiendo como
                        una computadora de almacenamiento extraída por nosotros para su despliegue en INVOLVE.
                        <br><br>
        
                        En ningún momento guarda información del análisis realizado, cuenta con una certificación de privacidad
                        y seguridad en la cual garantizan la eliminación de la información recibida y procesada, información que
                        podrás corroborar en el sitio web donde se encuentra su Aviso de Privacidad, mismo que podrá visualizar
                        en la siguiente liga <a
                            href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_Spanish_Translation.pdf"
                            target="_blank">https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_Spanish_Translation.pdf</a>
                        <br><br>
        
                        Para que el Titular pueda deshabilitar estos programas, deberá de acceder a las "opciones de internet"
                        ubicadas en la sección de las "herramientas", o funciones similares, del navegador que utilice.
                    </p>
        
                    <p class="upper-normal"><b>MODIFICACIONES AL AVISO DE PRIVACIDAD.</b>
                        <br>
        
                        <b>INVOLVE, S.A. de C.V.</b> modificará el presente Aviso de Privacidad las veces que considere
                        necesarias, a petición de las autoridades competentes y/o a nuestra propia conveniencia siempre y cuando
                        el Responsable no modifique su identidad y se requieran del Titular más datos de los aquí mencionados,
                        se modifique el objeto del presente Aviso de Privacidad y/o cambien las condiciones.
                        <br><br>
        
                        Asimismo el Titular está conforme, en que la forma de notificar cualquier cambio a este Aviso de
                        Privacidad será a través de la publicación en la página de Internet <a
                            href="https://talent.involverh.com" target="_blank">https://talent.involverh.com</a> la cual es
                        obligación del Titular visitar periódicamente con el fin de verificar la versión más actual del Aviso de
                        Privacidad. Para los casos en que el consentimiento del Titular sea necesario por los cambios realizados
                        al presente Aviso de Privacidad, el Responsable le hará llegar el aviso correspondiente a cualquiera de
                        los medios de contacto que el Titular nos haya brindado; se entenderá que el Titular acepta dicho Aviso
                        hasta que no recibamos cualquier comunicación en sentido contrario.
                    </p>
        
                    <p class="upper-normal"><b>ACEPTACIÓN DE LOS TÉRMINOS.</b>
                        <br>
        
                        Esta declaración de Privacidad está sujeta a los términos y condiciones de todos los sitios web y/o
                        aplicación móvil del Responsable antes descritos, lo cual constituye un acuerdo legal entre el Usuario y
                        del Responsable.
                        <br><br>
        
                        Si el usuario utiliza los servicios en cualquiera de los sitios y/o aplicación móvil del Responsable,
                        registrando un perfil de candidato, significa que <b><u>ha leído, entendido y aceptado los términos
                                antes expuestos.</u></b> Al momento de registrarse y previo al tratamiento de los datos, se pone
                        a su disposición este Aviso de Privacidad integral, mismo que usted acepta al dar clic en el botón
                        <b><u>“Crear Perfil”</u></b> o a través del mecanismo que se ponga a su disposición. La oposición a la
                        aceptación del presente Aviso de Privacidad traerá como consecuencia la imposibilidad para prestar el
                        servicio dentro de la plataforma para oferentes y demandantes de empleo. En caso de no aceptar el
                        presente Aviso de Privacidad, no debe proporcionarnos datos personales, ni suscribirse a los sitios del
                        Responsable.
                    </p>
        
                    <p class="upper-normal"><b>DE LA PROTECCIÓN.</b>
                        <br>
        
                        La seguridad y confidencialidad de los datos que el titular nos proporcionen al registrase en la
                        plataforma o sitio web, <b><u>estarán protegidos por un servidor seguro bajo el protocolo Secure Socket
                                Layer (SSL), de tal forma que los datos enviados se transmitirán encriptados para asegurar su
                                resguardo.</u></b>
        
                        “INVOLVE RH” protege tus datos personales usando el estándar de la industria en materia de encriptación,
                        de esta forma garantizamos que los datos que se envían desde la página de Internet lleguen seguros a
                        nuestros servidores. Almacenamos y procesamos tu información manteniendo siempre medidas de seguridad
                        orientadas a proteger tus datos personales.
                        <br><br>
        
                        En “INVOLVE RH” contamos con procedimientos que dictan quién y bajo qué condiciones se puede tener
                        acceso a los datos, dando siempre la máxima prioridad a la protección de tu privacidad. Asimismo,
                        asumimos medidas de seguridad físicas para prevenir el acceso no autorizado a nuestros sistemas e
                        instalaciones.
                        <br><br>
        
                        Para verificar que se encuentra en un entorno protegido asegúrese de que aparezca una S en la barra de
                        navegación. Ejemplo: httpS://. Sin embargo, y a pesar de contar cada día con herramientas más seguras,
                        la protección de los datos enviados a través de Internet no se puede garantizar al 100%; por lo que una
                        vez recibidos, se hará todo lo posible por salvaguardar la información.
                        <br><br>
        
                        Durante la prestación de los servicios, el Responsable puede hacer uso de una serie de procesos
                        automatizados de tratamiento de sus datos a fin de brindarle servicios más personalizados, como realizar
                        recomendaciones de ofertas laborales o educativas de acuerdo con su perfil, así como recomendación
                        automática de perfiles laborales.
                        <br><br>
        
                        El Responsable se compromete a contar con las medidas físicas, administrativas y técnicas (incluyendo
                        técnicas como data masking) de seguridad suficientes y necesarias para garantizar que sus Datos
                        permanezcan seguros.
                    </p>
        
                    <p class="upper-normal"><b>AUTORIDAD.</b>
                        <br>
        
                        Si considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene
                        el derecho de acudir ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección
                        de Datos Personales (INAI) en <a href="https://home.inai.org.mx/."
                            target="_blank">https://home.inai.org.mx/.</a>
                        <br><br>
        
                        Al utilizar esta plataforma, usted está otorgando su consentimiento al Responsable para procesar sus
                        datos personales bajo los términos de este Aviso de Privacidad.
                        <br><br>
        
                        Al brindarle sus servicios, el Responsable puede hacer uso de una serie de procesos automatizados que
                        utilizan sus datos personales y su actividad en nuestro Sitio y/o aplicación móvil con el fin de
                        brindarle servicios más personalizados y relevantes, como brindar oportunidades de trabajo y
                        recomendaciones de currículos a través de comunicaciones por correo electrónico y dentro del Sitio y/o
                        aplicación móvil.
                        <br><br>
        
                        Si tiene alguna inquietud en relación con el tratamiento o procesamiento de sus datos personales, no
                        dude en contactarnos a <u>contacto@involverh.com</u>
                        <br><br>
        
                        Versión de este Aviso de Privacidad fue actualizada por última vez el 27 de enero de 2023.
                    </p>
                </ng-container>
                <ng-template #legalsESPContainer>
                    <!-- 
                        Remover <body> y todo lo anterior
                        Remover </body> y todo lo posterior
                        Reemplazar `>` con `>\n`
                        Reemplazar `<o:p>.*\n.*</o:p>` con ``
                        Buscar `<o:p>` y eliminar cada etiqueta manualmente
                        Reemplazar `.*<!\[if !supportLists\]>\n` con `\n`
                        Reemplazar `.*<!\[endif\]>\n` con `\n`
                        Reemplazar `\n\n\n` con `\n`
                        Reemplazar `\n\n` con `\n`
                        Alt + shif + F para indentar el código 
                    -->
                    <div class=WordSection1>
                        <p class=MsoNormal>
                            <a name="_Hlk89267426">
                            </a>
                            <a name="_Hlk89172295">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Tahoma",sans-serif;
                    mso-fareast-font-family:"Times New Roman";color:#222222;mso-fareast-language:
                    ES-MX'>
                                        </span>
                                    </b>
                                </span>
                            </a>
                        </p>
                        <p class=MsoNormal align=center style='text-align:center'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif;mso-bidi-font-family:Arial'>
                                            AVISO
                                            DE PRIVACIDAD CANDIDATO
                                        </span>
                                    </b>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        El
                                        presente aviso se emite en cumplimiento a lo dispuesto en el Reglamento (UE)
                                        2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo
                                        a la protección de las personas físicas en lo que respecta al tratamiento de
                                        datos personales y a la libre circulación de estos datos (RGPD), así como en la
                                        Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y
                                        garantía de los derechos digitales.
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                        </span>
                                    </b>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            INVOLVE,
                                            S.A. DE C.V.</span>
                                    </b>
                                </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        (comercialmente conocido bajo el nombre de “<b>
                                            INVOLVE</b>
                                        ”) comprometido con
                                        sus usuarios reconoce que al usar el sitio web y/o plataforma, nos estás
                                        proporcionando tus datos, por lo que te comunicamos que nuestro compromiso es
                                        tratar estos, para la promoción laboral de vacantes para el candidato (usuario),
                                        frente a terceros propios de este sitio y externos que contraten los servicios
                                        que brinda <b>
                                            INVOLVE, S.A. DE C.V.;</b>
                                        asimismo ofrecerte asesoría en la elaboración
                                        de tu Currículum Vitae en lo sucesivo “CV” con la finalidad de que te
                                        posiciones en alguna vacante, promociones comerciales de bienes o servicios
                                        propios o de terceros que consideramos que pueden ser de tu interés.
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89172295'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Antes
                                        de proporcionarnos cualquier información, deberás de leer y comprender detenidamente
                                        los términos del presente <b>
                                            Aviso de Privacidad</b>
                                        , manifestando expresamente
                                        su consentimiento al Aceptar los mismos, lo anterior en virtud de que al
                                        aceptarlo en nuestro sitio web consientes expresamente su transmisión a las
                                        empresas reclutadoras a las que el candidato aplique para la vacante laboral
                                        respectiva.
                                    </span>
                                </span>
                            </span>
                        </p>
                        <span style='mso-bookmark:_Hlk89172295'>
                        </span>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <a name="_Hlk89172673">
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            IDENTIDAD
                                            Y DOMICILIO DEL RESPONSABLE Y SU REPRESENTANTE.
                                        </span>
                                    </b>
                                </a>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Este
                                        documento es el Aviso de Privacidad de <b>
                                            INVOLVE</b>
                                        , S.A. de C.V. (el &quot;<span class=GramE>
                                            Responsable</span>
                                        &quot;), quien será el responsable del
                                        tratamiento de los datos personales que usted (el &quot;Interesado&quot;) nos
                                        proporcione. Para los efectos señalados en el presente Aviso de Privacidad, el
                                        responsable señala como su domicilio el ubicado en: Calle Río Lerma, 196 Bis,
                                        Colonia Cuauhtémoc, Ciudad de México, C.P. 06500.
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        De
                                        conformidad con lo previsto en el artículo 27 RGPD, se informa al Interesado de
                                        que el <span class=GramE>
                                            Responsable</span>
                                        ha designado como representante en
                                        la Unión Europea a la siguiente entidad:
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:35.4pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        INVOLVE IBÉRICA, S.L. (el
                                        “Representante del <span class=GramE>
                                            Responsable</span>
                                        ”)
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:35.4pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        NIF: B67841775
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:35.4pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Domicilio en Orense 81, 4ª
                                        planta, 28020 Madrid
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:35.4pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                </span>
                            </span>
                            <a href="mailto:privacidad@esloraproyectos.com">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='mso-bookmark:_Hlk89172673'>
                                        <span style='font-family:"Century Gothic",sans-serif;
                    color:windowtext;text-decoration:none;text-underline:none'>
                                            privacidad@involverh.com</span>
                                    </span>
                                </span>
                            </a>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        El
                                        Representante del <span class=GramE>
                                            Responsable</span>
                                        tiene encomendado que
                                        atienda toda consulta, en particular, de la Agencia Española de Protección de
                                        Datos (AEPD) y de los interesados, sobre todos los asuntos relativos al
                                        tratamiento de datos.
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        La
                                        designación del Representante del <span class=GramE>
                                            Responsable</span>
                                        se
                                        entiende sin perjuicio de las acciones que pudieran emprenderse contra el
                                        propio Responsable.
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            DATOS
                                            PERSONALES QUE SERÁN SOMETIDOS A TRATAMIENTO.
                                        </span>
                                    </b>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89172673'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Los
                                        datos personales que el Interesado proporcione a través del llenado y remisión
                                        electrónica de formularios contenidos en la aplicación móvil y sitio web que
                                        serán sometidos a tratamiento son:
                                    </span>
                                </span>
                            </span>
                        </p>
                        <span style='mso-bookmark:_Hlk89172673'>
                        </span>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <u>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Para registrar un perfil de
                                            candidato:
                                        </span>
                                    </u>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (i)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Correo
                                    electrónico
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (ii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Nombre(s)
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (iii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Apellido
                                    paterno
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (iv)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Apellido
                                    materno
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (v)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Fecha
                                    de Nacimiento
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (vi)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Genero
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (vii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Número Telefónico
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (viii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Estado Civil.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-left:54.0pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (ix)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Nacionalidad.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <u>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Información Laboral:
                                        </span>
                                    </u>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (x)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Información
                                    sobre grados académicos
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (xi)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Lo
                                    relativo a historial académico y laboral;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (xii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Datos
                                    relativos a interés en puestos de trabajo;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (xiii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Habilidades y capacidades;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (xiv)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Pretensiones laborales
                                    (incluyendo sueldo);
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-left:54.0pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l19 level1 lfo3'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (xv)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Currículum vitae (los
                                    &quot;Datos&quot;).
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    También<b>
                                        INVOLVE</b>
                                    te
                                    informa que a través de su sitio web y/o App, con motivo de los servicios que
                                    brinda a sus suscriptores, te solicitará una video presentación y una entrevista
                                    técnica la cual será videograbada, en la cual se te preguntará sobre tu
                                    experiencia laboral, tus habilidades profesionales, descripción como
                                    colaborador, las cuales se desarrollan de la siguiente forma:
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-18.0pt;line-height:107%;mso-list:l3 level1 lfo16'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Video
                                        Presentación:</span>
                                </b>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    consiste en un video elaborado
                                    por el postulante (Interesado) en el cual contestara a su libre arbitrio las preguntas
                                    que formule el Empleador al momento de crear la vacante.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-18.0pt;line-height:107%;mso-list:l3 level1 lfo16'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Entrevista
                                        Técnica:</span>
                                </b>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    por este medio contestaras algunas
                                    preguntas designadas por el reclutador máximo 4, enfocadas directamente a la
                                    experiencia del candidato o conocimientos técnicos que necesita para el
                                    desempeño de sus funciones en la vacante ofertada (las cuales podrás consultar
                                    una vez postulado).
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Lo
                                    anterior con la intención de brindar un mejor servicio a nuestros subscritores
                                    y ellos <span class=GramE>
                                        conozcan</span>
                                    Asimismo, te informamos que, como
                                    parte del desarrollo de la plataforma, y con la mejor tus habilidades y
                                    aptitudes la plataforma y/o sitio web a través de los siguientes métodos te
                                    realizaran pruebas psicométricas las cuales incluyen cuestionamientos únicos y
                                    exclusivamente enfocados a tu perfil laboral, pruebas que se describen a
                                    continuación:
                                </span>
                            </span>
                        </p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:
                    36.0pt;text-align:justify;text-indent:-18.0pt;mso-list:l3 level1 lfo16'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-fareast-language:
                    EN-US'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;
                    mso-fareast-font-family:Calibri;mso-fareast-language:EN-US'>
                                        CLEAVER:</span>
                                </b>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif;
                    mso-fareast-font-family:Calibri;mso-fareast-language:EN-US'>
                    
                                </span>
                            </span>
                        </p>
                        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:11.25pt;margin-left:
                    35.45pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:Calibri;
                    mso-fareast-language:EN-US'>
                                    Esta prueba mide comportamientos y habilidades,
                                    mide cómo actúa la persona en un ambiente normal, cómo cambia su comportamiento
                                    si se encuentra motivada o se le motiva y cómo actúa bajo presión ya sea que se
                                    le presione o se <span class=SpellE>
                                        auto-presione</span>
                                    , además mide las
                                    capacidades de empuje (Dominio), Influencia (Persuasión), Constancia y Apego
                                    (Cumplimiento) que posee la persona evaluada. Permite determinar si la persona
                                    posee las competencias laborales idóneas para un determinado puesto o bien la
                                    personalidad para desempeñarse en una profesión determinada.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-18.0pt;line-height:107%;mso-list:l3 level1 lfo16'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-bidi-font-weight:
                    bold'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        MOSS:
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:Montserrat;mso-fareast-font-family:"Times New Roman";
                    color:black;mso-fareast-language:ES-MX'>
                                    Permite identifica</span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    r</span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:Montserrat;
                    mso-fareast-font-family:"Times New Roman";color:black;mso-fareast-language:
                    ES-MX'>
                                    las cualidades de la personalidad que pueden ser a un determinado
                                    estilo de liderazgo. Se evalúa la capacidad de identificar dificultades y
                                    afrontarlas de manera oportuna y efectiva, </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Suele
                                    usarse sobre todo en mandos intermedios como jefes y Gerentes.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-18.0pt;line-height:107%;mso-list:l3 level1 lfo16'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;mso-bidi-font-weight:
                    bold'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        IPV:
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-bottom:8.0pt;mso-add-space:
                    auto;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span class=GramE>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Este test</span>
                                </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    permite evaluar un conjunto amplio de características de personalidad
                                    consideradas relevantes para el éxito de los profesionales comerciales. La cual
                                    le permite al profesional determinar el grado en el que el candidato o
                                    candidata debe poseer esas características de personalidad en una situación o
                                    puesto concreto.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span class=SpellE>
                                        <b>
                                            <span style='font-family:"Century Gothic",sans-serif'>
                                                Kostick</span>
                                        </b>
                                    </span>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                        </span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Esta prueba
                                    permite conocer la personalidad limitándose a siete factores grado de energía,
                                    adaptación al trabajo, naturaleza social, liderazgo, naturaleza emocional,
                                    subordinación y modo de vida, midiendo el comportamiento en el Área Laboral.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Terman </span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:35.4pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El objetivo
                                    de esta prueba es medir el coeficiente intelectual de las personas que cuentan
                                    con un grado de escolaridad suficiente que puedan comprender problemas
                                    expuestos en forma escrita.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            LIFO </span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es un
                                    método de valoración de existencias o inventarios, para mostrar una imagen más
                                    fiel y realista de la empresa.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span class=SpellE>
                                        <b>
                                            <span style='font-family:"Century Gothic",sans-serif'>
                                                Zavic</span>
                                        </b>
                                    </span>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Prueba
                                    psicométrica que da la posibilidad de indagar en los valores e intereses que
                                    poseen los candidatos, para así tener la certeza de que se trata de personas
                                    honestas y responsables que aportarán beneficios a la organización.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            16 PF</span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Indica la
                                    capacidad de apertura mental o el respeto a los modos de hacer tradicionales,
                                    midiendo también el nivel de confiabilidad de las pospuestas dado.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span class=SpellE>
                                        <b>
                                            <span style='font-family:"Century Gothic",sans-serif'>
                                                Barsit</span>
                                        </b>
                                    </span>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                    
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Tiene el
                                    objetivo de medir la inteligencia a través de la valoración de factores de
                                    inteligencia verbal y razonamiento numérico.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Wonderlic</span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        abastece una estimación precisa de la inteligencia de una persona,
                                        midiendo las habilidades del evaluado con respecto a los requisitos de un
                                        trabajo o tarea especifica.
                                    </span>
                                </span>
                            </li>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Gordon</span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                    
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span class=GramE>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Este
                                        test</span>
                                </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    permite evaluar la
                                    personalidad del individuo mediante 9 rasgos que son significativos en el
                                    funcionamiento diario de la persona, como son: Ascendencia, Responsabilidad,
                                    Estabilidad Emocional, Sociabilidad, Cautela, Originalidad, Relaciones
                                    personales y Vigor.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Raven</span>
                                    </b>
                                </span>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                    
                                    </span>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es <span class=GramE>
                                        un Test</span>
                                    profesional a través de Matrices Progresivas de
                                    Raven, miden la capacidad para dar sentido a un material desorganizado o
                                    confuso, es decir, constructos claramente no verbales que facilitan la
                                    captación de una estructura compleja.
                                </span>
                            </span>
                        </p>
                        <ul style='margin-top:0cm' type=disc>
                            <li class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                         107%;mso-list:l3 level1 lfo16'>
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            Inglés
                                        </span>
                                    </b>
                                </span>
                            </li>
                        </ul>
                        <p class=MsoNormal style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;
                    margin-left:36.0pt;text-align:justify;line-height:107%'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es
                                    importante resaltar que dichas psicométricas solo realizan preguntas enfocadas
                                    a sus habilidades, las pruebas psicométricas antes descritas son realizadas por
                                    un proveedor que es API de Psicometrias.mx.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        ¿Qué
                                        es API?
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es
                                    Interfaz de programación de aplicaciones
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        ¿Cómo
                                        funciona la API de Psicometrias.mx?
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Se
                                    utiliza para la integración de las pruebas psicométricos con INVOLVE.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Una
                                    vez que el candidato complete <span class=SpellE>
                                        el</span>
                                    envió de la video
                                    presentación y video entrevista técnica, tiene la posibilidad de iniciar sus
                                    pruebas psicométricas (siempre y cuando la vacante tenga asociada alguna prueba
                                    psicométrica), este proceso el candidato lo inicia desde la App y/o página web
                                    de INVOLVE.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Una
                                    vez iniciado este proceso INVOLVE enviara un correo electrónico a el candidato
                                    y le dará clic al botón “ir a las pruebas” lo redireccionara a la plataforma de
                                    “Pruebas Psicométricas”, únicamente se encarga de realizar las pruebas
                                    psicométricas, una vez que culmine dichas Pruebas Psicométricas automáticamente
                                    envía una copia de dicho resultando a INVOLVE.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Las
                                    pruebas en todo momento pertenecen a “Pruebas Psicométricas”, podrás encontrar
                                    el aviso de privacidad y los términos y condiciones en las siguientes URL </span>
                            </span>
                            <a href="https://psicometricas.mx/">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        https://psicometricas.mx/</span>
                                </span>
                            </a>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    y</span>
                            </span>
                            <a href="https://psicometricas.mx/aviso-privacidad">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        https://psicometricas.mx/aviso-privacidad</span>
                                </span>
                            </a>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    .
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es
                                    importante resaltar, que dichas pruebas son realizadas por expertos a través de
                                    estas pruebas solo se realizarán preguntas diseñadas para conocer las
                                    habilidades, aptitudes, capacidades de los candidatos, en ningún momento se
                                    solicitara información personal o sensible, el único objetivo de la aplicación
                                    de estas pruebas es reclutar personal que cuente con el perfil específico y/o
                                    características muy particulares, debido a que facilitan la observación de los
                                    puntos clave de cada persona.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-bottom:8.0pt;text-align:justify;line-height:
                    107%'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Con
                                    la aceptación de este aviso de privacidad consientes y aceptas que se
                                    transmitan los datos antes referidos, así como las videograbaciones antes
                                    aludidas que en el <span class=SpellE>
                                        lleado</span>
                                    del perfil y aplicación de
                                    una vacante tú como candidato apliques directamente para los fines específicos.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El Interesado (Candidato) no
                                    deberá añadir datos personales no requeridos o diferentes a aquellos
                                    solicitados por el responsable en los campos designados para ello, ya que
                                    cualquier conducta contraria <b>
                                        <u>
                                            no garantiza la confidencialidad de dicha
                                            información.
                                        </u>
                                    </b>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            I.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        CONSERVACIÓN
                                        DE LOS DATOS.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Los datos personales del Interesado
                                    serán conservados por el INVOLVE para la prestación de los servicios durante el
                                    tiempo que el Interesado, esté tu perfil registrado en el Sitio y aplicación
                                    móvil, a menos que el Interesado solicite la supresión de estos mediante el
                                    procedimiento establecido en el número (VIII) de este Aviso. En todo caso el
                                    usuario acepta el uso de sus datos personales para los fines propios de la
                                    naturaleza de la plataforma la cual es de <span class=SpellE>
                                        indole</span>
                                    laboral y profesional, por lo que el usuario Interesado está obligado a darse
                                    de baja para la eliminación total de sus datos personales, de lo contrario su
                                    información seguirá guardada y resguardada por INVOLVE para los fines
                                    conducentes.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoCommentText style='margin-left:50.2pt;text-align:justify;
                    text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-size:12.0pt;font-family:"Century Gothic",sans-serif;mso-fareast-font-family:
                    "Century Gothic";mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:
                    Ignore'>
                                            II.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-size:12.0pt;font-family:
                    "Century Gothic",sans-serif'>
                                        DATOS PERSONALES SENSIBLES.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoCommentText style='text-align:justify'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoCommentText style='text-align:justify'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-size:12.0pt;font-family:"Century Gothic",sans-serif'>
                                    Se
                                    entiende como Datos personales sensibles: aquellos datos personales que afecten
                                    a la esfera más íntima de su Interesado, o cuya utilización indebida pueda dar
                                    origen a discriminación o conlleve un riesgo grave para éste. En particular, se
                                    consideran sensibles aquellos que puedan revelar aspectos como origen étnico o
                                    racial, las opiniones políticas, las convicciones religiosas o filosóficas, o
                                    la afiliación sindical, y el tratamiento de datos genéticos, datos biométricos
                                    dirigidos a identificar de manera unívoca a una persona física, datos relativos
                                    a la salud o datos relativos a la <span class=GramE>
                                        vida sexual o la
                                        orientación sexual</span>
                                    de una persona física.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:54.0pt;mso-add-space:auto;
                    text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Los datos personales sensibles,
                                    en su caso, deberán ser proporcionados, en su caso, por el Interesado mediante
                                    consentimiento explícito.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    INVOLVE a través del sitio web
                                    y/o la plataforma solicita de forma optativa nos proporciones tu género en sus
                                    dos variantes masculino y femenino, en cual encontraras la opción de omitir
                                    esta respuesta, información <span class=GramE>
                                        que</span>
                                    si decides
                                    proporcionar, <b>
                                        INVOLVE </b>
                                    la solicita<b>
                                    </b>
                                    con el único propósito de
                                    establecer un clara visión, dirección, de comunicar nuestro compromiso con la
                                    promoción de la igualdad de género e inclusión.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El <span class=GramE>
                                        Responsable</span>
                                    no solicitará de forma alguna, y el Interesado no proporcionará en ningún
                                    momento al Responsable, algún otro dato sensible, distinto al antes expuesto, es
                                    decir, aquellos datos personales íntimos o cuya utilización indebida pueda dar
                                    origen a discriminación o conlleve un riesgo grave para el Interesado. El Interesado
                                    se obliga a no proporcionar al <span class=GramE>
                                        Responsable</span>
                                    ninguna
                                    información relativa a su origen racial o étnico, estado de salud presente y/o
                                    futuro, información genética, creencias religiosas, filosóficas y/o morales,
                                    afiliación sindical, opiniones políticas y/o preferencia sexual. En caso de que
                                    el Interesado proporcione datos sensibles a través de documentos subidos al
                                    sitio web y/o aplicación móvil, o por medio de los formularios, acepta y
                                    consiente que los mismos sean tratados conforme a los términos y condiciones
                                    del sitio web y a su Aviso de Privacidad, siendo obligación del Interesado no
                                    proporcionar este tipo de datos en la información que sea subida al sitio web
                                    y/o aplicación móvil.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            III.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        USO
                                        A SUS DATOS.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:18.0pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    <span style='mso-spacerun:yes'>
                                         </span>
                                    Sus Datos serán tratados para las siguientes
                                    finalidades:
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <a name="_Hlk89097273">
                                    <b>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                            a)</span>
                                    </b>
                                </a>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='mso-bookmark:_Hlk89097273'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        <b>
                                            Objetivos Primarios:</b>
                                        Prestación de servicios a través de su sitio web y/o aplicación móvil con el
                                        propósito de proporcionar una plataforma para la promoción de ofertas de laborales
                                        y búsqueda de empleo por terceros, de acuerdo con lo siguiente:
                                    </span>
                                </span>
                            </span>
                        </p>
                        <span style='mso-bookmark:_Hlk89097273'>
                        </span>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (i)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Creación
                                    de tu perfil como candidato.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (ii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Asesoría
                                    con la creación de tu perfil como candidato;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (iii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Envío
                                    de tu currículo a las empresas que buscan tu perfil o se interesan en el mismo;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (iv)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Permitir
                                    que las empresas, a través de una video entrevista conozca más a detalle sobre
                                    tus habilidades y formación laboral.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (v)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Recibir
                                    alertas de ofertas laborales que podrían resultar de tu interés de acuerdo con
                                    tu perfil;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (vi)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Contactarte
                                    para el seguimiento de reportes de ofertas de trabajo que consideres
                                    fraudulentas;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (vii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Informarte sobre cambios o
                                    nuevos productos y servicios;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (viii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Identificar de forma única el
                                    comportamiento de los usuarios dentro de la plataforma;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (ix)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Obtener
                                    reporte psicométrico.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-left:54.0pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l12 level1 lfo6'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (x)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Brindarte
                                    asesoría y soporte técnico.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        b) Objetivos Secundarios:</span>
                                </b>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Mejora del proceso comercial y de mercadotecnia, utilizando la información
                                    para:
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l21 level1 lfo7'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (i)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Gestionar
                                    un servicio eficaz y suministrar una mejor atención para mejorar su experiencia
                                    en el uso de ciertos servicios;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l21 level1 lfo7'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (ii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Informarle
                                    y presentar servicios que puedan resultar relevantes o atractivos;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:54.0pt;mso-add-space:
                    auto;text-align:justify;text-indent:-36.0pt;mso-list:l21 level1 lfo7'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (iii)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Enviarle
                                    por cualquier medio todo tipo de comunicación para participar en pruebas,
                                    promociones, encuestas, ofertas y campañas de publicidad, propias o de
                                    terceros;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-left:54.0pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l21 level1 lfo7'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        (iv)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Evaluar
                                    la calidad del servicio o realizar estudios sobre hábitos de consumo.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            IV.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        LICITUD
                                        DEL TRATAMIENTO.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Los datos personales que el
                                    Interesado proporcione serán tratados para las finalidades descritas en base a
                                    todas o, en su caso, algunas de las siguientes condiciones establecidas en el
                                    artículo 6 RGPD:
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='text-align:justify;text-indent:-18.0pt;
                    mso-list:l17 level1 lfo22'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
                    Symbol'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    el
                                    interesado dio su consentimiento para el tratamiento de sus datos personales
                                    para uno o varios fines específicos;
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='text-align:justify;text-indent:-18.0pt;
                    mso-list:l17 level1 lfo22'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
                    Symbol'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    el
                                    tratamiento es necesario para la ejecución de un contrato en el que el
                                    interesado es parte o para la aplicación a petición de este de medidas
                                    precontractuales; y
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='text-align:justify;text-indent:-18.0pt;
                    mso-list:l17 level1 lfo22'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
                    Symbol'>
                                    <span style='mso-list:Ignore'>
                                        ·<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    el
                                    tratamiento es necesario para la satisfacción de intereses legítimos
                                    perseguidos por el responsable del tratamiento o por un tercero, siempre que
                                    sobre dichos intereses no prevalezcan los intereses o los derechos y libertades
                                    fundamentales del interesado que requieran la protección de datos personales,
                                    en particular cuando el interesado sea un niño.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span lang=ES style='font-family:"Century Gothic",sans-serif;mso-ansi-language:ES'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            V.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        DERECHOS
                                        DEL INTERESADO.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El Interesado podrá acceder y
                                    en su caso, rectificar, suprimir, oponerse y limitar el tratamiento de sus
                                    datos en cualquier momento, así como revocar el consentimiento cuando proceda,
                                    ejercitando sus derechos en la dirección postal o de correo electrónico del
                                    Representante del <span class=GramE>
                                        Responsable</span>
                                    arriba indicada. En todo
                                    caso, deberá acompañar a la solicitud copia de su DNI para acreditar su
                                    identidad.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    INVOLVE informa, no obstante,
                                    que el ejercicio de los derechos de supresión, así como la revocación o la
                                    oposición pueden suponer diversas consecuencias en los tratamientos que INVOLVE
                                    realiza sobre los datos registrados, por lo que, en todo caso, se recomienda
                                    encarecidamente que el Interesado consulte previamente su situación y los
                                    tratamientos en particular que le afectan al solicitar el ejercicio de los
                                    derechos que le asisten.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Una vez que el Representante
                                    del <span class=GramE>
                                        Responsable</span>
                                    reciba el escrito del Interesado
                                    referente al ejercicio de sus derechos, aquél, dentro de los 15 días hábiles
                                    siguientes, tendrá que analizar, atender y enviar al Interesado la respuesta
                                    correspondiente. El medio del envío de la respuesta podrá ser por la forma que
                                    indique el Interesado o utilizando el mismo medio por el cual el Interesado le
                                    hizo llegar el correspondiente escrito al Representante del <span class=GramE>
                                        Responsable</span>
                                    .
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    La autoridad de control a la
                                    que puede acudir en caso de requerir la tutela de sus datos es la Agencia
                                    Española de Protección de Datos, cuya dirección postal es C/Jorge Juan, 6,
                                    28001 - Madrid, y su web </span>
                            </span>
                            <a href="http://www.agpd.es">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif;
                    color:windowtext;text-decoration:none;text-underline:none'>
                                        www.agpd.es</span>
                                </span>
                            </a>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    .
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span lang=ES style='font-family:"Century Gothic",sans-serif;mso-ansi-language:ES'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            VI.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        COMUNICACIÓN
                                        DE DATOS.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Para poder cumplir con las
                                    finalidades de este Aviso de Privacidad si cuentas con un perfil de candidato,
                                    transferiremos todo o parte de tus Datos a terceros cuando apliques a alguna
                                    oferta publicada, o bien cuando el reclutador te busque dentro de la base de
                                    datos de candidatos del <span class=GramE>
                                        Responsable</span>
                                    . Los terceros
                                    receptores de tus datos<b>
                                        <u>
                                            podrán ser personas físicas o jurídicas,
                                            nacionales o extranjeras</u>
                                    </b>
                                    , dedicadas a la industria, el comercio y/o los
                                    servicios, quienes quedarán obligados a mantener la confidencialidad de los
                                    Datos y tratarlos conforme a este Aviso de Privacidad, tratando tus datos
                                    personales únicamente para fines de reclutamiento y selección.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Como se ha indicado más arriba,
                                    el <span class=GramE>
                                        Responsable</span>
                                    se encuentra ubicado en México, país
                                    que no está reconocido por la Comisión Europea como uno de los que cuenta con
                                    un nivel de protección adecuado sobre los datos personales.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b style='mso-bidi-font-weight:
                    normal'>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:
                    "Century Gothic";mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:
                    Ignore'>
                                            VII.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span lang=EN-US style='font-family:"Century Gothic",sans-serif;
                    mso-ansi-language:EN-US'>
                                        MAILING y SMS.</span>
                                </b>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span lang=EN-US style='font-family:"Century Gothic",sans-serif;
                    mso-ansi-language:EN-US'>
                                </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify;text-indent:14.2pt'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        PROCESO
                                        DE VERIFICACIÓN DE USUARIOS, POR MEDIO DEL CANAL SMS.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    <span style='mso-spacerun:yes'>
                                         </span>
                                    INVOLVE te informa que a través de <span class=SpellE>
                                        Twillo</span>
                                    , que es una API interfaz de programación de
                                    aplicaciones, permite él envió de mensajería de texto (SMS), el cual tiene
                                    únicamente como función de confirmación de registro de cuentas, ayudando a
                                    verificar que no sea un robot el que esté realizando el proceso de registro de
                                    candidato, el cual únicamente funge como tercero, en ningún momento tiene
                                    acceso a la información lo cual podrás corroborar en su aviso de privacidad en
                                    el siguiente link </span>
                            </span>
                            <a href="https://www.twilio.com/legal/privacy">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        https://www.twilio.com/legal/privacy</span>
                                </span>
                            </a>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Para suprimir la recepción de Mailyng
                                    publicitario, el Interesado podrá realizarlo seleccionando la opción de suscribir
                                    o un subscribe que se encuentra al final de la comunicación recibida, o bien,
                                    realizando la petición al correo electrónico contacto@involverh.com con el
                                    asunto “de suscribir publicidad”.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:50.2pt;mso-add-space:auto;
                    text-align:justify;text-indent:-36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            VIII.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        GEOLOCALIZACIÓN.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif;background:yellow;mso-highlight:
                    yellow'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Para los efectos del presente
                                    la geolocalización será entendida como el uso de coordenadas de latitud y
                                    longitud de un dispositivo obtenidas a través de GPS. Esta información será requerida
                                    únicamente durante el uso de la Aplicación Móvil y/o Sitio Web en cualquier
                                    dispositivo.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es necesaria únicamente para
                                    validar el lugar físico desde donde la persona se encuentra solicitando su
                                    alta.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    En caso de que el usuario no
                                    esté de acuerdo con que se recabe la geolocalización de su dispositivo, deberá
                                    denegar los permisos para acceder a la misma dentro de la Aplicación Móvil, o
                                    bien, dentro de la configuración de privacidad (en específico ubicación) de su
                                    navegador de Internet.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:50.2pt;text-align:justify;text-indent:
                    -36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            IX.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        <span style='mso-spacerun:yes'>
                                             </span>
                                        ELIMINACIÓN DE CUENTA.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:50.2pt;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Si deseas solicitar la
                                    eliminación de tu cuenta, solo deberás colocarte en el icono de ajustes y darle
                                    clic en la opción de eliminar cuenta, en el que te aparecerá un mensaje para su
                                    confirmación, una vez que le des clic en confirmar, se eliminará todos tus
                                    datos registrados, recibiendo un mensaje de la confirmación
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:50.2pt;text-align:justify;text-indent:
                    -36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            X.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        MEDIOS
                                        REMOTOS.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    En caso de que el Interesado
                                    proporcione sus Datos a través de medios electrónicos, incluyendo el sitio web
                                    (Internet) y/o aplicación móvil del <span class=GramE>
                                        Responsable</span>
                                    ,
                                    entonces el Interesado entiende, acepta y reconoce que:
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpFirst style='margin-left:0cm;mso-add-space:auto;
                    text-align:justify;text-indent:0cm;mso-list:l0 level1 lfo12'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        a)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El
                                    sitio web y/o aplicación móvil del <span class=GramE>
                                        Responsable</span>
                                    puede
                                    incluir enlaces a sitios web de terceros, que de accederse, ocasionará que se
                                    abandone el sitio web y/o aplicación móvil del Responsable, por lo cual el
                                    Responsable no asume ninguna responsabilidad en relación con esos sitios web de
                                    terceros.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:0cm;mso-add-space:auto;
                    text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:0cm;mso-add-space:auto;
                    text-align:justify;text-indent:0cm;mso-list:l0 level1 lfo12'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        b)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El
                                    sitio web y/o aplicación móvil del <span class=GramE>
                                        Responsable</span>
                                    puede
                                    incluir enlaces a sitios que administran redes sociales, en cuyo caso el Interesado
                                    acepta que al proporcionar cualquier tipo de información o Datos en dichos
                                    sitios, ocasionará que la misma pueda ser leída, vista, accedida, retransmitida
                                    y tratada por cualquier persona, y por lo tanto libera de cualquier
                                    responsabilidad al Responsable.
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpMiddle style='margin-left:90.0pt;mso-add-space:
                    auto;text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraphCxSpLast style='margin-left:0cm;mso-add-space:auto;
                    text-align:justify;text-indent:0cm;mso-list:l1 level1 lfo19'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:
                    "Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        C)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Es
                                    posible que los sistemas del <span class=GramE>
                                        Responsable</span>
                                    recopilen
                                    Datos del Interesado tales como tipo de navegador, sistema operativo, páginas
                                    de Internet visitadas, dirección IP, etc., a través &quot;cookies&quot; o
                                    &quot;web involverh.com&quot;, entre otros.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span class=SpellE>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Involve</span>
                                </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    utiliza cookies, web <span class=SpellE>
                                        beacons</span>
                                    y/o Kits de Desarrollo
                                    de Software (“<span class=SpellE>
                                        SDKs</span>
                                    ”) para facilitar la navegación en
                                    sus Plataformas.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Las cookies constituyen una
                                    herramienta empleada por los servidores web para almacenar y recuperar
                                    información que se guarda en el navegador utilizado por los usuarios o
                                    visitantes del sitio web, lo que permite guardar tus preferencias personales
                                    para brindarte una mejor experiencia de navegación.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Las cookies tienen fecha de
                                    caducidad, que puede oscilar desde el tiempo que dura la sesión o visita al
                                    sitio web hasta una fecha específica a partir de la cual dejan de ser
                                    operativas. Las cookies empleadas en las Plataformas se asocian únicamente con
                                    un Usuario anónimo y su equipo informático, no proporcionan referencias que
                                    permitan deducir el nombre y apellidos del Usuario, no pueden leer datos de su
                                    disco duro ni incluir virus en sus textos.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Puedes configurar tu navegador
                                    para aceptar o rechazar automáticamente todas las cookies o para recibir un
                                    aviso en pantalla sobre la recepción de cada cookie y decidir en ese momento su
                                    implantación o no en tu disco duro.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Te confirmamos que “INVOLVE”
                                    recaba datos personales a través de estos medios, es por ello por lo que te
                                    informamos que a través de estas recaban los siguientes datos: (i)Nombre; (<span class=SpellE>
                                        ii</span>
                                    ) Apellido materno y Paterno; (<span class=SpellE>
                                        iii</span>
                                    )
                                    correo electrónico, conforme a lo señalado en la política de cookies insertar <span class=GramE>
                                        link</span>
                                    talent.involverh.es.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoListParagraph style='margin-left:0cm;mso-add-space:auto;text-align:
                    justify;text-indent:0cm;mso-list:l20 level1 lfo20'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif;
                    mso-fareast-font-family:"Century Gothic";mso-bidi-font-family:"Century Gothic"'>
                                    <span style='mso-list:Ignore'>
                                        d)<span style='font:7.0pt "Times New Roman"'>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </span>
                                    </span>
                                </span>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El
                                    sitio web y la aplicación móvil del responsable incluye para desarrollar sus
                                    funcionalidades los servicios de la persona moral extranjera <span class=SpellE>
                                        <b>
                                            Textkernel</b>
                                    </span>
                                    <b>
                                        LLS US</b>
                                    comercializado como <b>
                                        SOVREN,</b>
                                    cabe aclarar que en ningún momento la moral antes referida va a hacer uso de
                                    los datos que el Interesado (Usted) nos proporcione, solo almacenara la
                                    información.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        ¿Cómo funciona AWS e INVOLVE?
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Al guardar o recibir un CV en
                                    la App y/o sitio web INVOLVE se envía el documento codificado hacia el API de AWS,
                                    conocido como servicios en la nube es una red de servidores remotos conectados
                                    a internet para almacenar, administrar y procesar datos, servidores, bases de
                                    datos, redes y software, fungiendo como una computadora de almacenamiento extraída
                                    por nosotros para su despliegue en INVOLVE.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    En ningún momento guarda
                                    información del análisis realizado, cuenta con una certificación de privacidad
                                    y seguridad en la cual garantizan la eliminación de la información recibida y
                                    procesada, información que podrás corroborar en el sitio web donde se encuentra
                                    su Aviso de Privacidad, mismo que podrá visualizar en la siguiente liga </span>
                            </span>
                            <a href="https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_Spanish_Translation.pdf">
                                <span style='mso-bookmark:_Hlk89267426'>
                                    <span style='font-family:"Century Gothic",sans-serif;
                    color:windowtext;text-decoration:none;text-underline:none'>
                                        https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice_Spanish_Translation.pdf</span>
                                </span>
                            </a>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Para que el Interesado pueda
                                    deshabilitar estos programas, deberá de acceder a las &quot;opciones de
                                    internet&quot; ubicadas en la sección de las &quot;herramientas&quot;, o
                                    funciones similares, del navegador que utilice.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:50.2pt;text-align:justify;text-indent:
                    -36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            XI.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        MODIFICACIONES
                                        AL AVISO DE PRIVACIDAD.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        INVOLVE, S.A. de C.V</span>
                                </b>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    .
                                    modificará el presente Aviso de Privacidad las veces que considere necesarias,
                                    a petición de las autoridades competentes y/o a nuestra propia conveniencia
                                    siempre y cuando el <span class=GramE>
                                        Responsable</span>
                                    no modifique su
                                    identidad y se requieran del Interesado más datos de los aquí mencionados, se
                                    modifique el objeto del presente Aviso de Privacidad y/o cambien las
                                    condiciones.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span class=GramE>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        Asimismo</span>
                                </span>
                            </span>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    el Interesado está conforme, en que la forma de notificar cualquier cambio a
                                    este Aviso de Privacidad será a través de la publicación en la página de
                                    Internet https://talent.involverh.es la cual es obligación del Interesado
                                    visitar periódicamente con el fin de verificar la versión más actual del Aviso
                                    de Privacidad. Para los casos en que el consentimiento del Interesado sea
                                    necesario por los cambios realizados al presente Aviso de Privacidad, el <span class=GramE>
                                        Responsable</span>
                                    le hará llegar el aviso correspondiente a
                                    cualquiera de los medios de contacto que el Interesado nos haya brindado; se
                                    entenderá que el Interesado acepta dicho Aviso hasta que no recibamos cualquier
                                    comunicación en sentido contrario.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:50.2pt;text-align:justify;text-indent:
                    -36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            XII.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        ACEPTACIÓN
                                        DE LOS TÉRMINOS.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Esta declaración de Privacidad
                                    está sujeta a los términos y condiciones de todos los sitios web y/o aplicación
                                    móvil del <span class=GramE>
                                        Responsable</span>
                                    antes descritos, lo cual
                                    constituye un acuerdo legal entre el Usuario y del Responsable.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Si el usuario utiliza los
                                    servicios en cualquiera de los sitios y/o aplicación móvil del <span class=GramE>
                                        Responsable</span>
                                    , registrando un perfil de candidato, significa
                                    que <b>
                                        <u>
                                            ha leído, entendido y aceptado los términos antes expuestos</u>
                                    </b>
                                    .
                                    Al momento de registrarse y previo al tratamiento de los datos, se pone a su
                                    disposición este Aviso de Privacidad integral, mismo que usted acepta al dar clic
                                    en el botón “<b>
                                        <u>
                                            Crear Perfil</u>
                                    </b>
                                    ” o a través del mecanismo que se ponga
                                    a su disposición. La oposición a la aceptación del presente Aviso de Privacidad
                                    traerá como consecuencia la imposibilidad para prestar el servicio dentro de la
                                    plataforma para oferentes y demandantes de empleo. En caso de no aceptar el
                                    presente Aviso de Privacidad, no debe proporcionarnos datos personales, ni
                                    suscribirse a los sitios del <span class=GramE>
                                        Responsable</span>
                                    .
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='margin-left:50.2pt;text-align:justify;text-indent:
                    -36.0pt;mso-list:l7 level1 lfo1'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif;mso-fareast-font-family:"Century Gothic";
                    mso-bidi-font-family:"Century Gothic"'>
                                        <span style='mso-list:Ignore'>
                                            XIII.<span style='font:7.0pt "Times New Roman"'>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </span>
                                    </span>
                                </b>
                                <b>
                                    <span style='font-family:"Century Gothic",sans-serif'>
                                        MEDIDAS DE PROTECCIÓN.
                                    </span>
                                </b>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    La seguridad y confidencialidad
                                    de los datos que el Interesado nos proporcionen al registrase en la plataforma
                                    o sitio web, <b>
                                        <u>
                                            estarán protegidos por un servidor seguro bajo el protocolo
                                            Secure Socket <span class=SpellE>
                                                Layer</span>
                                            (SSL), de tal forma que los datos
                                            enviados se transmitirán encriptados para asegurar su resguardo.
                                        </u>
                                    </b>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <b>
                                    <u>
                                        <span style='font-family:"Century Gothic",sans-serif'>
                                        </span>
                                    </u>
                                </b>
                            </span>
                        </p>
                        <p class=MsoCommentText style='text-align:justify'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-size:12.0pt;font-family:"Century Gothic",sans-serif'>
                                    “INVOLVE”
                                    protege tus datos personales usando el estándar de la industria en materia de
                                    encriptación, de esta forma garantizamos que los datos que se envían desde la
                                    página de Internet lleguen seguros a nuestros servidores. Almacenamos y
                                    procesamos tu información manteniendo siempre medidas de seguridad orientadas a
                                    proteger tus datos personales.
                                </span>
                            </span>
                        </p>
                        <p class=MsoCommentText style='text-align:justify'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-size:12.0pt;font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoCommentText style='text-align:justify'>
                            <span style='mso-bookmark:
                    _Hlk89267426'>
                                <span style='font-size:12.0pt;font-family:"Century Gothic",sans-serif'>
                                    En
                                    “INVOLVE” contamos con procedimientos que dictan quién y bajo qué condiciones
                                    se puede tener acceso a los datos, dando siempre la máxima prioridad a la
                                    protección de tu privacidad. Asimismo, asumimos medidas de seguridad físicas
                                    para prevenir el acceso no autorizado a nuestros sistemas e instalaciones.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Para verificar que se encuentra
                                    en un entorno protegido asegúrese de que aparezca una S en la barra de
                                    navegación. Ejemplo: httpS://. Sin embargo, y a pesar de contar cada día con
                                    herramientas más seguras, la protección de los datos enviados a través de
                                    Internet no se puede garantizar al 100%; por lo que una vez recibidos, se hará
                                    todo lo posible por salvaguardar la información.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Durante la prestación de los
                                    servicios, el <span class=GramE>
                                        Responsable</span>
                                    puede hacer uso de una serie
                                    de procesos automatizados de tratamiento de sus datos a fin de brindarle
                                    servicios más personalizados, como realizar recomendaciones de ofertas
                                    laborales o educativas de acuerdo con su perfil, así como recomendación
                                    automática de perfiles laborales.
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    El <span class=GramE>
                                        Responsable</span>
                                    se compromete a contar con las medidas físicas, administrativas y técnicas
                                    (incluyendo técnicas como data <span class=SpellE>
                                        masking</span>
                                    ) de seguridad
                                    suficientes y necesarias para garantizar que sus Datos permanezcan seguros.<i>
                                    </i>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                </span>
                            </span>
                        </p>
                        <p class=MsoNormal>
                            <span style='mso-bookmark:_Hlk89267426'>
                                <span style='font-family:"Century Gothic",sans-serif'>
                                    Versión de este Aviso de
                                    Privacidad fue actualizada por última vez el 11 de otubre de 2022.</span>
                            </span>
                            <span style='font-family:"Century Gothic",sans-serif'>
                            </span>
                        </p>
                        <p class=MsoNormal style='text-align:justify'>
                            <span style='font-family:"Century Gothic",sans-serif'>
                            </span>
                        </p>
                    </div>
                </ng-template>
                <div class="row mt-5 mx-auto" *ngIf="displaysAsModal">
                    <div class="col pr-2 pl-0">
                        <a data-dismiss="modal" *ngIf="showCancelbutton" class="btn btn-outline-primary btn-block" (click)="decline()">{{ 'onboarding.legals.rejectButton' | translate }}</a>
                    </div>
                    <div class="col pl-2 pr-0">
                        <a data-dismiss="modal" class="btn btn-primary btn-block" (click)="accept()">{{ 'onboarding.legals.acceptButton' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>