import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { hasFormControlError, lowercase, maxLengthRequired, minLengthRequired, number, required, uppercase, samePasswords } from '../../../../services/custom-validators/custom-validators.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device/device.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ICandidate } from 'src/app/models/candidate.models';
import { TranslateService } from '@ngx-translate/core';
import { appSettings } from 'src/app/globals/appSettings';
@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss']
})
export class ResetPasswordPageComponent implements OnInit{
  passwordForm: FormGroup;
  currentPath = ''
  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private storageService: StorageService,
    public toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    // TODO: Flujo para restablecer el password desde link en correo electrónico
    this.passwordForm = this.fb.group({
      password: this.fb.control('', [required, 
                                    minLengthRequired(8), 
                                    maxLengthRequired(25),
                                    lowercase(true),
                                    uppercase(true),
                                    number(true)]),
      confirmPassword: this.fb.control('', [required])
    }, 
    {
      validators: samePasswords('password', 'confirmPassword')
    });
    this.passwordForm.markAllAsTouched();
  }

  ngOnInit() {
    this.currentPath = this.router.url.includes('restore-password') || this.router.url.includes('reset-password')?'restore-password':'set-password';    
    if (this.currentPath == 'restore-password') {
      this.route.params.subscribe(params => {
        const token = params['token'];
        this.storageService.set('token',token)
      })
    }
  }


  onCreatePassword() {
    if (this.passwordForm.valid) {
      const params = {
        password: this.passwordControl.value.trim()
      };
      this.apiService.createPassword(params).subscribe({
        next: (createPasswordResponse: HttpResponse<ICandidate>) => {
          const responseToken = createPasswordResponse.headers.get('token');
          if (responseToken){
            this.storageService.set('token', responseToken.replace('Bearer ', ''));
            this.storageService.set('candidate', JSON.stringify(createPasswordResponse.body));
            this.router.navigateByUrl('/onboarding/notifications', { replaceUrl: true });
          }
          else{
            this.toastService.error(this.translateService.instant('errors.generic.default'), this.translateService.instant('errors.resetPasswordPage.noToken'));
          }
        },
        error: (createPasswordError: HttpErrorResponse) => {
          this.toastService.error('Ocurrió un error', createPasswordError.message);
        },
      });
    }
  }

  onResetPassword() {
    if (this.passwordForm.valid) {
      const params = {
        password: this.passwordControl.value.trim()
      };
      this.apiService.resetPassword(params).subscribe({
        next: (createPasswordResponse: HttpResponse<ICandidate>) => {
            this.toastService.success('',this.translateService.instant('resetPassword.setCorrectly'));
            this.storageService.clear()
            this.router.navigateByUrl('/', { replaceUrl: true });
        },
        error: (createPasswordError: HttpErrorResponse) => {
          this.toastService.error(this.translateService.instant('errors.generic.default'), createPasswordError.message);
        },
      });
    }
  }

  hasFormControlError(formControlName: string, errorName?: string, validatesIsTheFirstError = true): boolean | ValidationErrors {
    return hasFormControlError(this.passwordForm, formControlName, errorName, validatesIsTheFirstError);
  }

  get passwordControl(): FormControl{
    return this.passwordForm.get('password') as FormControl;
  }

  get confirmPasswordControl(): FormControl{
    return this.passwordForm.get('confirmPassword') as FormControl;
  }

  getCountryPrivacyLink(): string {
    return appSettings.legals.privacyUrl;
  }

  getCountryTermsLink(): string {
    return appSettings.legals.termsUrl;
  }

  getCountryCookiesLink(): string {
    return appSettings.legals.cookiesUrl;
  }

}


